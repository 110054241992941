import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import Swal from "sweetalert2";
import { PDFViewer } from '@react-pdf/renderer';
import CertificadoPDF from "./CertificadoPDF";
import DiplomaCerrado from "./DiplomaCerrado";
import { AuthContext } from "../../auth/authContext";
import {format, parseISO } from 'date-fns';
import {es} from 'date-fns/locale';

const CertificadosCerrados = () => {
  const { user } = useContext(AuthContext ); // Obtener user del contexto
  const [cursos,setCursos]=useState([]);
  const [id,setId]=useState('0');
  const [verpdf,setVerpdf]=useState(false);
  const [participantes,setParticipantes]=useState([]);
  const [actividad,setActividad]=useState([]);

  useEffect(()=>{
    const cursosData=async()=>{
        const response= await AuthService.getActividades();
        setCursos(response.data.body) 
    }
    cursosData();
  },[])
  
 const submitForm=async(values,actions)=>{
    setId(values.curso)
    //await foliar()
    setVerpdf(true);
    

};
const handleFoliar=async(actividad,participantes)=>{
  if(participantes.length===0){
    Swal.fire('No existen participantes para esta actividad.','','error')
  }else{
    for (const alumno of participantes) {
      let fecha = actividad.fecha_inicio ? cambiarFormatoFecha(actividad.fecha_inicio, 'dd-MM-yyyy') : '';
      if (alumno.num_folio_diploma === null) {
        try {
          const dataParticipante = {
            'tipo_folio': 8,
            'detalle_documento': alumno.nombres + ' ' + alumno.primer_apellido + ' ' + alumno.segundo_apellido + ' ' + 'Run: ' + alumno.run + '-' + alumno.dv + ' ' + actividad.nombre_generico + ' ' + fecha + ', ' + actividad.nombre_empresa,
            'ind_fondorendir': 'N',
            'usuario_crea': user.name,
            'id': alumno.id
          };
          const responseParticipante = await AuthService.postFoliarParticipante(dataParticipante);
          if (responseParticipante.status === 200) {
            console.log(alumno.nombres + ' ' + alumno.primer_apellido + ' ' + alumno.segundo_apellido + ' ' + 'Run: ' + alumno.run + '-' + alumno.dv + ' ' + actividad.nombre_generico + ' ' + fecha);
            
          }
        } catch (error) {
          console.error('Error al foliar:', error);
          setVerpdf(false);
        }
      }
    }
  }
}
  //validaciones
  const formik = useFormik({
    initialValues: {
      curso: "",
    },
    validationSchema: Yup.object({
      curso: Yup.string().required("Es obligatorio"),
    }),
    onSubmit:submitForm,
  });
  
  
  const handleLimpiar=(e,values)=>{
    formik.setValues({
      ...values,
      curso: "",
    });
    setVerpdf(false)
  }
  const handleChangeCurso=async(e,values)=>{
    formik.setFieldValue('curso', e.target.value);
    if(e.target.value!=='0'){
    const responseA= await AuthService.getActividad(e.target.value);
    setActividad(responseA.data.body[0])

    const responseP= await AuthService.getParticipantes(e.target.value);
    setParticipantes(responseP.data.body)
    handleFoliar(responseA.data.body[0],responseP.data.body)
    }
  }
  function cambiarFormatoFecha(fecha,tipo){
    const fechaISO=parseISO(fecha);
    let fechaFormateada='';
    if(tipo==='dd'){
        fechaFormateada=format(fechaISO,'dd');
    }
    if(tipo==='MM'){
        fechaFormateada=capitalize(format(fechaISO,'MMMM',{locale:es}));
    }
    if(tipo==='yyyy'){
        fechaFormateada=format(fechaISO,'yyyy');
    }
    if(tipo==='dd-MM-yyyy'){
        fechaFormateada=format(fechaISO,'dd-MM-yyyy');
    }
    return fechaFormateada;
  }
  const capitalize=(str)=>{
    return str.charAt(0).toUpperCase()+str.slice(1);
  }
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Diploma de Cursos Cerrados
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="curso">
                      <Form.Label>Curso</Form.Label>
                      <Form.Select
                        value={formik.values.curso}
                        onChange={handleChangeCurso}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option value='0'>Seleccione...</option>
                        {cursos.length>0?cursos.map(elemento=>(elemento.estado_actividad!==null?
                        <option key={"cursosAlumno"+elemento.id_actividad} value={elemento.id_actividad}>{elemento.nombre_generico} - {elemento.nombre_empresa}</option>:null
                      )):''}
                      </Form.Select>
                    </Form.Group>
                    {formik.touched.curso && formik.errors.curso ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.curso}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit">
                      Ver Certificado
                    </Button>
                    {' '}
                    <Button variant="success" onClick={handleLimpiar}>
                      Limpiar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card.Body>
        </Card>
        <>{ verpdf ? <Card>
          <Card.Header as="h3" style={myHeader}>
            Impresión de Certificado
          </Card.Header>
          <Card.Body style={myBody}>
            <PDFViewer style={{width:"100%", height:"90vh"}}>
              <DiplomaCerrado datos={{id,user}} />
            </PDFViewer>
          </Card.Body>
        </Card>:null}
        </>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default CertificadosCerrados