import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import Swal from "sweetalert2";
import { AuthContext } from "../../auth/authContext";
import {BsSearch} from "react-icons/bs";
import Modal from 'react-bootstrap/Modal';
import { Table } from "react-bootstrap";

const FinalizarCurso = () => {
  const {user}=useContext(AuthContext);
  const [cursos,setCursos]=useState([]);
  const [busquedaAlumnos,setBusquedaAlumnos]=useState([]);
  const [tipoCurso,setTipoCurso]=useState('P');

  useEffect(()=>{
    const cursosData=async()=>{
        //const response= await AuthService.getCursos();
        //setCursos(response.data.body) 
    }
    cursosData();
  },[])
  function validateRut(rut) {
    const cleanRut = rut.replace(/[.-]/g, ''); // Eliminar puntos y guiones
    const rutNumber = cleanRut.slice(0, -1);
    const verifierDigit = cleanRut.slice(-1).toUpperCase();
  
    if (!/^[0-9kK]+$/g.test(verifierDigit)) {
      return false; // El dígito verificador solo debe ser número o K
    }
  
    const reversedRutArray = [...rutNumber].reverse();
    const verifier = reversedRutArray.reduce((acc, digit, index) => {
      const multiplier = (index % 6) + 2;
      return acc + parseInt(digit, 10) * multiplier;
    }, 0);
  
    const remainder = verifier % 11;
    const calculatedVerifierDigit = remainder === 1 ? 'K' : remainder === 0 ? '0' : (11 - remainder).toString();
  
    return calculatedVerifierDigit === verifierDigit;
  }
  const handleBlurRut=async (e,values)=>{
    if(validateRut(values.run+'-'+values.dv)){
      let timerInterval
      Swal.fire({
        title: 'Buscando alumno',
        html: 'Espere...<b></b>',
        timer: 500,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
          const b = Swal.getHtmlContainer().querySelector('b')
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft()
          }, 100)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
        }
      })
      const response=await AuthService.getAlumnoRun(values.run)

      const responseCursos=await AuthService.getCursoAlumno(response.data.body[0].id)
      //setId(responseCursos.data.body[0].id);
      setCursos(responseCursos.data.body)
      
      formik.setValues({
        ...values,
        nombre: response.data.body[0].nombres,
        primerAp: response.data.body[0].primer_apellido,
        segundoAp: response.data.body[0].segundo_apellido,
        asistenciaFinal: ""
      });
      
    }else{
      Swal.fire('Rut Incorrecto','','error')
      formik.setValues({
        ...values,
        nombre: "",
        primerAp: "",
        segundoAp: "",
        run: "",
        dv: "",
        curso: "",
        asistenciaFinal: "",
        notaFinal:"",
        practica:"",
        empresaPractica:"",
        horasPractica:"",
        folio:"",
        inicio:"",
        fin:"",
        SearchNombre: "",
        SearchPrimerAp: "",
        SearchSegundoAp: "",
        estadoAlumno:""
      });
    }
  }

 const submitForm=async(values,actions)=>{

  const responseCurso=await AuthService.getCursoAlumnoId(values.curso)
  const data={'id':values.curso,
              'id_alumno':responseCurso.data.body[0].id_alumno,
              'id_curso':responseCurso.data.body[0].id_curso,
              'id_ciclo':responseCurso.data.body[0].id_ciclo,
              'ind_practica':values.practica,
              'empresa_practica':values.empresaPractica,
              'horas_practica':values.horasPractica,
              'estado_alumno':values.estadoAlumno,
              'folio':values.folio,
              'fecha_inicio_curso':values.inicio,
              'fecha_termino_curso':values.fin,
              'finalizado':'S',
              'usuario_finaliza':user.name
            }
  const response=await AuthService.postCursoAlumno(data);
  if(response.status===201){
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: response.body,
      showConfirmButton: false,
      timer: 1500
    })
      actions.resetForm({
        values: {
          nombre: "",
          primerAp: "",
          segundoAp: "",
          run: "",
          dv: "",
          curso: "",
          asistenciaFinal: "",
          notaFinal:"",
          practica:"",
          empresaPractica:"",
          horasPractica:"",
          folio:"",
          inicio:"",
          fin:"",
          SearchNombre: "",
          SearchPrimerAp: "",
          SearchSegundoAp: "",
          estadoAlumno:""
        },
      });
      setCursos([]);
  }else{
      Swal.fire('Ha ocurrido un error!',response.body,'error')
  };

};
  //validaciones
  const formik = useFormik({
    initialValues: {
      nombre: "",
      primerAp: "",
      segundoAp: "",
      run: "",
      dv: "",
      curso: "",
      asistenciaFinal: "",
      notaFinal:"",
      practica:"",
      empresaPractica:"",
      horasPractica:"",
      folio:"",
      inicio:"",
      fin:"",
      SearchNombre: "",
      SearchPrimerAp: "",
      SearchSegundoAp: "",
      estadoAlumno:""
    },
    validationSchema: Yup.object({
      nombre: Yup.string()
        .min(3, "Debe tener al menos 3 caracteres.")
        .required("Es obligatorio"),
      primerAp: Yup.string()
        .min(3, "Debe tener al menos 3 caracteres.")
        .required("Es obligatorio"),
      segundoAp: Yup.string().min(3, "Debe tener al menos 3 caracteres."),
      run: Yup.number()
        .max(99999999, "Debe tener al menos 8 caracteres.")
        .required("Es obligatorio"),
      dv: Yup.string().max(1, "Largo 1").required("Falta"),
      curso: Yup.string().required("Es obligatorio"),
      notaFinal:Yup.number().required("Es obligatorio")
        .max(999,"Debe tener 3 caracteres"),
      practica: Yup.string().required("Es obligatorio"),
      estadoAlumno: Yup.string().required("Es obligatorio"),
      folio: Yup.number().required("Es obligatorio"),
      inicio: Yup.date().required("Es obligatorio"),
      fin: Yup.date().required("Es obligatorio"),
      SearchNombre: Yup.string(),
      SearchPrimerAp: Yup.string(),
      SearchSegundoAp: Yup.string()
    }),
    onSubmit: submitForm,
  });
  
  const handleLimpiar=(e,values)=>{
    formik.setValues({
      ...values,
      nombre: "",
      primerAp: "",
      segundoAp: "",
      run: "",
      dv: "",
      curso: "",
      asistenciaFinal: "",
      notaFinal:"",
      practica:"",
      empresaPractica:"",
      horasPractica:"",
      folio:"",
      inicio:"",
      fin:"",
      SearchNombre: "",
      SearchPrimerAp: "",
      SearchSegundoAp: "",
      estadoAlumno:""
    });
    setCursos([]);
  }
  const handleChangeCurso=async(e,values)=>{
    formik.setFieldValue('curso', e.target.value);
    if(e.target.value!=='0'){
      const cursosAlumno=[...cursos];
      const cursoInscrito=cursosAlumno.find(n=> n.id===parseInt(e.target.value));
      setTipoCurso(cursoInscrito.tipo_curso);
      const responseCurso=await AuthService.getCursoAlumnoId(e.target.value)
      
      formik.setFieldValue('notaFinal', responseCurso.data.body[0].nota_final);
      formik.setFieldValue('asistenciaFinal', responseCurso.data.body[0].porcentaje_asistencia);
      formik.setFieldValue('practica', responseCurso.data.body[0].ind_practica===null?"0":responseCurso.data.body[0].ind_practica);
      formik.setFieldValue('empresaPractica', responseCurso.data.body[0].empresa_practica===null?"":responseCurso.data.body[0].empresa_practica);
      formik.setFieldValue('horasPractica', responseCurso.data.body[0].horas_practica===null?"":responseCurso.data.body[0].horas_practica);
      formik.setFieldValue('folio', responseCurso.data.body[0].folio===null?"":responseCurso.data.body[0].folio);
      formik.setFieldValue('inicio', responseCurso.data.body[0].fecha_inicio_curso===null?"":responseCurso.data.body[0].fecha_inicio_curso);
      formik.setFieldValue('fin', responseCurso.data.body[0].fecha_termino_curso===null?"":responseCurso.data.body[0].fecha_termino_curso);
      formik.setFieldValue('estadoAlumno', responseCurso.data.body[0].estado_alumno===null?"0":responseCurso.data.body[0].estado_alumno);
    }else{
      setTipoCurso('P');
      formik.setFieldValue('notaFinal', '');
      formik.setFieldValue('asistenciaFinal', '');
      formik.setFieldValue('practica', '0');
      formik.setFieldValue('empresaPractica', '');
      formik.setFieldValue('horasPractica', '');
      formik.setFieldValue('folio', '');
      formik.setFieldValue('inicio', '');
      formik.setFieldValue('fin', '');
    }
  }
  const handleBlurCurso=async(e,values)=>{
    if(values.curso!=='0' || values.curso!=='undefined'){
      const cursosAlumno=[...cursos];
      const cursoInscrito=cursosAlumno.find(n=> n.id===parseInt(values.curso));
      setTipoCurso(cursoInscrito.tipo_curso);
      const responseCurso=await AuthService.getCursoAlumnoId(values.curso)

      formik.setValues({
        ...values,
        nombre: values.nombre,
        primerAp: values.primerAp,
        segundoAp: values.segundoAp,
        notaFinal:responseCurso.data.body[0].nota_final,
        asistenciaFinal:responseCurso.data.body[0].porcentaje_asistencia,
        practica:responseCurso.data.body[0].ind_practica===null?"0":responseCurso.data.body[0].ind_practica,
        empresaPractica:responseCurso.data.body[0].empresa_practica===null?"":responseCurso.data.body[0].empresa_practica,
        horasPractica:responseCurso.data.body[0].horas_practica===null?"":responseCurso.data.body[0].horas_practica,
        folio:responseCurso.data.body[0].folio===null?"":responseCurso.data.body[0].folio,
        inicio:responseCurso.data.body[0].fecha_inicio_curso===null?"":responseCurso.data.body[0].fecha_inicio_curso,
        fin:responseCurso.data.body[0].fecha_termino_curso===null?"":responseCurso.data.body[0].fecha_termino_curso,
      });
    }
  }

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    formik.setFieldValue('SearchNombre', '');
    formik.setFieldValue('SearchPrimerAp', '');
    formik.setFieldValue('SearchSegundoAp', '');
    setBusquedaAlumnos([])
  }
  const handleSearch=()=>{
    setShow(true);
  }
  const handleBuscar=async(e,values)=>{
    const response= await AuthService.postAlumnosNombres(values.SearchNombre,values.SearchPrimerAp,values.SearchSegundoAp);
    if(response.data.status===200){
      setBusquedaAlumnos(response.data.body)
    }else{
      Swal.fire(response.data.body,'','error')
    }
    
  }
  const handleSeleccionar=(rut,dv)=>{
    formik.setFieldValue('run', rut);
    formik.setFieldValue('dv', dv);
    handleClose()
    
  }
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Finalizar Curso
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="run">
                      <Form.Label>Run de Alumno</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.run}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.run && formik.errors.run ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.run}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col sm={1}>
                    <Form.Group className="mb-3" controlId="dv">
                      <Form.Label>DV</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.dv}
                        onChange={formik.handleChange}
                        onBlur={e=>{handleBlurRut(e,formik.values)}}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.dv && formik.errors.dv ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.dv}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col sm={1}>
                    <Form.Group className="mb-1" controlId="search">
                      <Form.Label>Buscar</Form.Label>
                      <Button variant="warning" onClick={() => handleSearch()}>
                      <BsSearch/>
                    </Button>
                      
                    </Form.Group>
                  
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="nombre">
                      <Form.Label>Nombre</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                    {formik.touched.nombre && formik.errors.nombre ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.nombre}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="primerAp">
                      <Form.Label>Primer Apellido</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.primerAp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                    {formik.touched.primerAp && formik.errors.primerAp ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.primerAp}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="segundoAp">
                      <Form.Label>Segundo Apellido</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.segundoAp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                    {formik.touched.segundoAp && formik.errors.segundoAp ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.segundoAp}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="curso">
                      <Form.Label>Curso</Form.Label>
                      <Form.Select
                        value={formik.values.curso}
                        onChange={handleChangeCurso}
                        onBlur={formik.handleBlur/*e=>{handleBlurCurso(e,formik.values)}*/}
                        className="form-control"
                      >
                        <option value='0'>Seleccione...</option>
                        {cursos.length>0?cursos.map(elemento=>(
                        <option key={"cursosAlumno"+elemento.id} value={elemento.id}>{elemento.nombre_ciclo} {elemento.nombre_curso}</option>
                      )):''}
                      </Form.Select>
                    </Form.Group>
                    {formik.touched.curso && formik.errors.curso ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.curso}
                      </Alert>
                    ) : null}
                  </Col>
                  {tipoCurso==='P' &&(
                  <Col  sm={3}>
                    <Form.Group className="mb-3" controlId="asistenciaFinal">
                      <Form.Label>Porcentaje de Asistencia</Form.Label>
                      <Form.Control
                        type="number"
                        value={formik.values.asistenciaFinal}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                    {formik.touched.asistenciaFinal && formik.errors.asistenciaFinal ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.asistenciaFinal}
                      </Alert>
                    ) : null}
                  </Col>
                  )}
                  <Col  sm={3}>
                    <Form.Group className="mb-3" controlId="notaFinal">
                      <Form.Label>Nota Final</Form.Label>
                      <Form.Control
                        type="number"
                        value={formik.values.notaFinal}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                    {formik.touched.notaFinal && formik.errors.notaFinal ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.notaFinal}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="practica">
                      <Form.Label>Práctica Pre-Ocupacional</Form.Label>
                      <Form.Select
                        value={formik.values.practica}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option value='0'>Seleccione...</option>
                        <option value='S'>Si</option>
                        <option value='N'>No</option>
                        
                      </Form.Select>
                    </Form.Group>
                    {formik.touched.practica && formik.errors.practica ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.practica}
                      </Alert>
                    ) : null}
                  </Col>
                {formik.values.practica==='S' &&(
                  <Col>
                    <Form.Group className="mb-3" controlId="empresaPractica">
                      <Form.Label>Empresa realiza práctica</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.empresaPractica}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.empresaPractica && formik.errors.empresaPractica ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.empresaPractica}
                      </Alert>
                    ) : null}
                  </Col>
                  )}
                {formik.values.practica==='S' &&(
                  <Col>
                    <Form.Group className="mb-3" controlId="horasPractica">
                      <Form.Label>Duración total horas práctica</Form.Label>
                      <Form.Control
                        type="number"
                        value={formik.values.horasPractica}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.horasPractica && formik.errors.horasPractica ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.horasPractica}
                      </Alert>
                    ) : null}
                  </Col>
                )}
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="inicio">
                      <Form.Label>Fecha inicio curso</Form.Label>
                      <Form.Control
                        type="date"
                        value={formik.values.inicio}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.inicio && formik.errors.inicio ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.inicio}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="fin">
                      <Form.Label>Fecha término curso</Form.Label>
                      <Form.Control
                        type="date"
                        value={formik.values.fin}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.fin && formik.errors.fin ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.fin}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="estadoAlumno">
                      <Form.Label>Estado del Alumno</Form.Label>
                      <Form.Select
                        value={formik.values.estadoAlumno}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option value='0'>Seleccione...</option>
                        <option value='1'>Reprobado</option>
                        <option value='2'>Egresado</option>
                        <option value='3'>Retirado</option>
                      </Form.Select>
                    </Form.Group>
                    {formik.touched.estadoAlumno && formik.errors.estadoAlumno ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.estadoAlumno}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col  sm={3}>
                    <Form.Group className="mb-3" controlId="folio">
                      <Form.Label>Folio finalización</Form.Label>
                      <Form.Control
                        type="number"
                        value={formik.values.folio}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.folio && formik.errors.folio ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.folio}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit">
                      Finalizar
                    </Button>
                    {' '}
                    <Button variant="success" onClick={handleLimpiar}>
                      Limpiar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card.Body>
        </Card>
        {/*<Card>
          <Card.Header as="h3" style={myHeader}>
            Alumnos Ingresados
          </Card.Header>
          <Card.Body style={myBody}>
            <Table striped bordered hover size="sm" variant="dark">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Curso</th>
                  <th>Tipo Curso</th>
                  <th>Cuota Mensual</th>
                  <th>Costo Total</th>
                  <th>Duración Meses</th>
                  <th>Total Horas</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {cursos.map(elemento=>(
                <tr key={"cursos"+elemento.id}>
                <td>1</td>
                <td>{elemento.nombre_curso}</td>
                <td>{elemento.tipo_curso=='O'?'Online':'Presencial'}</td>
                <td>{elemento.cuota_mensual}</td>
                <td>{elemento.costo_total}</td>
                <td>{elemento.duracion_meses}</td>
                <td>{elemento.duracion_horas}</td>
                <td><Button variant="warning" onClick={()=>handleEditar(elemento.id)}>Editar</Button></td>
                <td><Button variant="danger" onClick={()=>handleEliminar(elemento.id)}>Eliminar</Button></td>
              </tr>
                        ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>*/}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Buscar Alumno</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col>
            <Form.Group className="mb-3" controlId="SearchNombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.SearchNombre}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="SearchPrimerAp">
              <Form.Label>Primer Apellido</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.SearchPrimerAp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="SearchSegundoAp">
              <Form.Label>Segundo Apellido</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.SearchSegundoAp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
              />
            </Form.Group>
          </Col>
          <Table striped bordered hover size="sm" variant="dark" className="text-center">
              <thead>
                <tr>
                  <th>Run</th>
                  <th>Nombre Completo</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {busquedaAlumnos.length>0?busquedaAlumnos.map(elemento=>(
                <tr key={"busquedaalumno"+elemento.id}>
                <td>{elemento.run}-{elemento.digito_verificador}</td>
                <td>{elemento.primer_apellido} {elemento.segundo_apellido} {elemento.nombres}</td>
                <td><Button variant="warning" onClick={()=>handleSeleccionar(elemento.run,elemento.digito_verificador)}>Seleccionar</Button></td>
              </tr>
               )):null}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={e=>{handleBuscar(e,formik.values)}}>
              Buscar
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default FinalizarCurso;