import React, { useContext,useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import { AuthContext } from "../../auth/authContext";
import Swal from "sweetalert2";
import { Table } from "react-bootstrap";

const MantenedorUsuario= () => {

  const [id,setId]=useState('0');
  const [usuarios,setUsuarios]=useState([]);
  const {user}=useContext(AuthContext );
  const [showPassword, setShowPassword] = useState(false);
  useEffect(()=>{
    const usuariosData=async()=>{
      const response= await AuthService.getUsuarios();
      if(response.status===200){
        setUsuarios(response.data.body) 
      }else{
        Swal.fire('Ha ocurrido un error!',response.data.body,'error')
      };
    }
    usuariosData();
  },[])
  const submitForm=async(values,actions)=>{
    const data={'id':id,
                'nombres':values.nombre,
                'primer_apellido':values.primerAp,
                'segundo_apellido':values.segundoAp,
                'correo_electronico':values.correo,
                'usuario':values.usuario,
                'password':values.clave,
                'estado':'V',
                'activo':1
    }
      
      const response=await AuthService.postUsuario(data);
      if(response.status===201){
          Swal.fire(response.body,'','success')
          actions.setSubmitting(false);
          actions.resetForm({
            values: {
              nombre: "",
              primerAp:"",
              segundoAp:"",
              correo: "",
              usuario: "",
              clave: ""
            },
          });
          handleLimpiar();
          handleActualizaTabla();
      }else{
          Swal.fire('Ha ocurrido un error!',response.body,'error')
      };
      
  };


    //validaciones
    const formik = useFormik({
    initialValues: {
        nombre: "",
        primerAp:"",
        segundoAp:"",
        correo: "",
        usuario: "",
        clave: ""
    },
    validationSchema: Yup.object({
        nombre:  Yup.string().required("Es obligatorio"),
        primerAp:  Yup.string().required("Es obligatorio"),
        segundoAp: Yup.string().min(3, "Debe tener al menos 3 caracteres."),
        correo: Yup.string().min(4, "Debe tener al menos 3 caracteres.").email().required("Es obligatorio"),
        usuario: Yup.string().min(5, "Debe tener al menos 5 caracteres.").required("Es obligatorio"),
        clave: Yup.string().min(8, "Debe tener al menos 8 caracteres.").required("Es obligatorio"),
    }),
    onSubmit: submitForm,
  });
  const handleEditar=async(idEdita,values)=>{
    const response=await AuthService.getUsuarioId(idEdita)
      setId(idEdita);
      formik.setValues({
        ...values,
        nombre: response.data.body[0].nombres===null ? "":response.data.body[0].nombres,
        primerAp: response.data.body[0].primer_apellido===null ? "":response.data.body[0].primer_apellido,
        segundoAp: response.data.body[0].segundo_apellido===null ? "":response.data.body[0].segundo_apellido,
        correo: response.data.body[0].correo_electronico===null ? "":response.data.body[0].correo_electronico,
        usuario: response.data.body[0].usuario===null ? "":response.data.body[0].usuario,
        clave:""
      });
  }
  const handleEliminar=async(idElimina)=>{
    Swal.fire({
      title: '¿Está seguro de eliminar?',
      text: "No se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        const response=await AuthService.putUsuario({'id':idElimina,'usuario':user.name})
        if(response.status===200){
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.body,
            showConfirmButton: false,
            timer: 1500
          })
          handleActualizaTabla();
          Swal.fire(
            'Eliminado!',
            'El registro fue eliminado.',
            'success'
          )
        }else{
          Swal.fire('Ha ocurrido un error!',response.body,'error')
        }
      }
    })
  }
  const handleActualizaTabla=async()=>{
    const response= await AuthService.getUsuarios();
    setUsuarios(response.data.body)
  }
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleLimpiar=(e,values)=>{
    formik.setValues({
      ...values,
      nombre: "",
      primerAp:"",
      segundoAp:"",
      correo: "",
      usuario: "",
      clave: ""
    });
    setId('0');
  }
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Mantenedor de Usuarios
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Nombres</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="nombre">
                      <Form.Control
                        type="text"
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.nombre && formik.errors.nombre ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.nombre}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Primer Apellido</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="primerAp">
                      <Form.Control
                        type="text"
                        value={formik.values.primerAp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.primerAp && formik.errors.primerAp ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.primerAp}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Segundo Apellido</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="segundoAp">
                      <Form.Control
                        type="text"
                        value={formik.values.segundoAp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.segundoAp && formik.errors.segundoAp ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.segundoAp}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Correo Electrónico</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="correo">
                      <Form.Control
                        type="email"
                        value={formik.values.correo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.correo && formik.errors.correo ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.correo}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Usuario</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="usuario">
                      <Form.Control
                        type="text"
                        value={formik.values.usuario}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.usuario && formik.errors.usuario ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.usuario}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Contraseña</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="clave">
                      <Form.Control
                        type={showPassword ? 'text' : 'password'}
                        value={formik.values.clave}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={1}>
                    <label>
                      <input type="checkbox" checked={showPassword} onChange={handleShowPassword} />
                      Mostrar contraseña
                    </label>
                  </Col>
                  <Col sm={3}>
                    {formik.touched.clave && formik.errors.clave ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.clave}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit">
                      Guardar
                    </Button>
                    {' '}
                    <Button variant="success" onClick={handleLimpiar}>
                      Limpiar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Usuarios creados
          </Card.Header>
          <Card.Body style={myBody}>
            <Table striped bordered hover size="sm" variant="dark" responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Primer Apellido</th>
                  <th>Segundo Apellido</th>
                  <th>Nombres</th>
                  <th>Correo Electronico</th>
                  <th>Usuario</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {usuarios?usuarios.map((elemento,index)=>(
                <tr key={"filas"+elemento.id}>
                <td>{index+1}</td>
                <td>{elemento.primer_apellido}</td>
                <td>{elemento.segundo_apellido}</td>
                <td>{elemento.nombres}</td>
                <td>{elemento.correo_electronico}</td>
                <td>{elemento.usuario}</td>
                <td><Button variant="warning" onClick={()=>handleEditar(elemento.id)}>Editar</Button></td>
                <td><Button variant="danger" onClick={()=>handleEliminar(elemento.id)}>Eliminar</Button></td>
              </tr>
              )):<tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default MantenedorUsuario;
