import React, {useContext,  useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import { AuthContext } from "../../auth/authContext";
import Swal from "sweetalert2";
import { Modal, Table } from "react-bootstrap";
import { FaFileExcel } from "react-icons/fa";
import * as XLSX from 'xlsx'


const MantenedorParticipantes = (data) => {
  const {user}=useContext(AuthContext );
  const [id,setId]=useState('0');
  const [idactividad]=useState(data.data);

  //const { auth } = useAuth();
  //console.log(auth.accessToken);
  const [participantes,setParticipantes]=useState([{}]);
    useEffect(()=>{
        const participantesData=async()=>{
            const response= await AuthService.getParticipantes(idactividad);
            setParticipantes(response.data.body) 
        }
        participantesData();
    },[])
  //validaciones
  const submitForm=async (values,actions)=>{

    const data={'id':id,
                'id_actividad':idactividad,
                'run':values.run,
                'dv':values.dv.toUpperCase(),
                'nombres':values.nombre.toUpperCase(),
                'primer_apellido':values.primerAp.toUpperCase(),
                'segundo_apellido':values.segundoAp.toUpperCase(),
                'extranjero':values.extranjero,
                'cargo':values.cargo.toUpperCase(),
                'superintendencia':values.super.toUpperCase(),
                'franquicia':values.franquicia.toUpperCase(),
                'nota':values.nota==='' ? null: values.nota,
                'asistencia':values.asistencia==='' ? null: values.asistencia,
                'estado':'V',
                'usuario_crea':user.name
              }
    const response=await AuthService.postParticipante(data);
    if(response.status===201){
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: response.body,
        showConfirmButton: false,
        timer: 1500
      })
      const response2= await AuthService.getParticipantes(idactividad);
      setParticipantes(response2.data.body)
      //actions.setSubmitting(false);
      actions.resetForm({
        values: {
          nombre: "",
          primerAp: "",
          segundoAp: "",
          run: "",
          dv: "",
          extranjero: "",
          cargo: "",
          super: "",
          franquicia:"",
          nota: "",
          asistencia: "",
          excel:""
        },
      });
    }else{
      Swal.fire('Ha ocurrido un error!',response.body,'error')
    };
  };
  function validateRut(rut) {
    const cleanRut = rut.replace(/[.-]/g, ''); // Eliminar puntos y guiones
    const rutNumber = cleanRut.slice(0, -1);
    const verifierDigit = cleanRut.slice(-1).toUpperCase();
  
    if (!/^[0-9kK]+$/g.test(verifierDigit)) {
      return false; // El dígito verificador solo debe ser número o K
    }
  
    const reversedRutArray = [...rutNumber].reverse();
    const verifier = reversedRutArray.reduce((acc, digit, index) => {
      const multiplier = (index % 6) + 2;
      return acc + parseInt(digit, 10) * multiplier;
    }, 0);
  
    const remainder = verifier % 11;
    const calculatedVerifierDigit = remainder === 1 ? 'K' : remainder === 0 ? '0' : (11 - remainder).toString();
  
    return calculatedVerifierDigit === verifierDigit;
  }
  const handleBlurRut=async (e,values)=>{
    if(values.extranjero==='N'){
      if(!validateRut(values.run+'-'+values.dv)){
        Swal.fire('Rut Incorrecto','','error')
        formik.setValues({
          ...values,
          nombre: "",
          primerAp: "",
          segundoAp: "",
          run: "",
          dv: "",
          extranjero: "",
          cargo: "",
          super: "",
          franquicia:"",
          nota: "",
          asistencia: "",
        });
      }
    }
  }
  const handleLimpiar=(e,values)=>{
    formik.setValues({
      ...values,
      nombre: "",
      primerAp: "",
      segundoAp: "",
      run: "",
      dv: "",
      extranjero: "",
      cargo: "",
      super: "",
      franquicia:"",
      nota: "",
      asistencia: "",
    });
  }
  const formik = useFormik({
    initialValues: {
      nombre: "",
      primerAp: "",
      segundoAp: "",
      run: "",
      dv: "",
      extranjero: "",
      cargo: "",
      super: "",
      franquicia:"",
      nota: "",
      asistencia: "",
      excel:"",
    },
    validationSchema: Yup.object({
      nombre: Yup.string()
        .min(3, "Debe tener al menos 3 caracteres.")
        .required("Es obligatorio"),
      primerAp: Yup.string()
        .min(3, "Debe tener al menos 3 caracteres.")
        .required("Es obligatorio"),
      segundoAp: Yup.string().min(3, "Debe tener al menos 3 caracteres."),
      extranjero: Yup.string().required("Es obligatorio"),
      run: Yup.number()
        .min(8, "Debe tener al menos 8 caracteres.")
        .required("Es obligatorio"),
      dv:Yup.string().max(1, "Debe tener 1 caracter."),
      cargo:  Yup.string(),
      super: Yup.string()
        .min(2, "Debe tener al menos 3 caracteres."),
      franquicia: Yup.string()
        .min(2, "Debe tener al menos 3 caracteres."),
      nota: Yup.number(),
      asistencia:  Yup.number(),
    }),
    onSubmit: submitForm,
    //validate: handleBlurRut
  });
  const handleEditar=async(id,values)=>{
    const response=await AuthService.getParticipante(id)
      setId(id);
      formik.setValues({
        ...values,
        nombre: response.data.body[0].nombres,
        primerAp: response.data.body[0].primer_apellido,
        segundoAp: response.data.body[0].segundo_apellido===null ? '': response.data.body[0].segundo_apellido,
        run: response.data.body[0].run,
        dv: response.data.body[0].dv,
        extranjero: response.data.body[0].extranjero,
        cargo: response.data.body[0].cargo===null ? '': response.data.body[0].cargo,
        super: response.data.body[0].superintendencia===null ? '': response.data.body[0].superintendencia,
        franquicia: response.data.body[0].franquicia===null ? '': response.data.body[0].franquicia,
        nota: response.data.body[0].nota===null ? '': response.data.body[0].nota,
        asistencia: response.data.body[0].asistencia===null ? '': response.data.body[0].asistencia
      });
  }
  const handleEliminar=async(id)=>{
    Swal.fire({
      title: '¿Está seguro de eliminar?',
      text: "No se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        const response=await AuthService.putParticipante({'id':id,'usuario':user.name})
        if(response.status===200){
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.body,
            showConfirmButton: false,
            timer: 1500
          })
          const response2= await AuthService.getParticipantes(idactividad);
          setParticipantes(response2.data.body)
        }else{
          Swal.fire('Ha ocurrido un error!',response.body,'error')
        }
        Swal.fire(
          'Eliminado!',
          'El registro fue eliminado.',
          'success'
        )
      }
    })
  }
const handleExcel=()=>{
  const dataExcel=participantes.map(item=>{
    return{ run: item.run,
            dv: item.dv,
            nombres:item.nombres.toUpperCase(),
            primer_apellido:item.primer_apellido.toUpperCase(),
            segundo_apellido:item.segundo_apellido.toUpperCase(),
            extranjero:item.extranjero.toUpperCase(),
            cargo:item.cargo.toUpperCase(),
            superintendencia:item.superintendencia.toUpperCase(),
            franquicia:item.franquicia.toUpperCase(),
            nota:item.nota,
            asistencia:item.asistencia}
  });
  if (dataExcel.length === 0) {
    dataExcel.unshift({
      run: '',
      dv: '',
      nombres: '',
      primer_apellido: '',
      segundo_apellido: '',
      extranjero: '',
      cargo: '',
      superintendencia: '',
      franquicia: '',
      nota: '',
      asistencia: '',
    });
  }
  var wb =XLSX.utils.book_new(),
  ws= XLSX.utils.json_to_sheet(dataExcel);

  XLSX.utils.book_append_sheet(wb,ws,"Participantes actividad");

  XLSX.writeFile(wb,"Participantes.xlsx");
}
const [excelData,setExcelData]=useState(null);
const [excelName,setExcelName]=useState('');
const [excelColumns,setExcelColumns]=useState('');

const handleFileChange=(event)=>{
  setExcelName(event.target.files[0].name)
  const file=event.target.files[0];
  const reader= new FileReader();
  reader.onload=(e)=>{
      const workbook=XLSX.read(e.target.result,{type:"binary"});
      const worksheet= workbook.Sheets[workbook.SheetNames[0]];
      const jsonData= XLSX.utils.sheet_to_json(worksheet,{header:1,defval:null});
      const jsonExcel=jsonData.splice(1,jsonData.length);
      /*const formattedData=jsonExcel.map((row)=>
        row.map((cell)=>{
          const parsedDate=parse(cell,'dd-MM-yyyy',new Date());
          console.log(parsedDate)
          if(isValid(parsedDate)){
            return format(parsedDate,'yyyy-MM-dd');
          }
          return cell;
        })
      );*/
      const columnCount=jsonExcel.reduce((maxColumns,row)=>{
        return Math.max(maxColumns,row.length);
      },0);
      setExcelColumns(columnCount);
      const updateData=jsonExcel.map(item=>[
        idactividad,
        item[0],
        item[1],
        item[2]===null?'':item[2].toUpperCase(),
        item[3]===null?'':item[3].toUpperCase(),
        item[4]===null?'':item[4].toUpperCase(),
        item[5]===null?'':item[5].toUpperCase(),
        item[6]===null?'':item[6].toUpperCase(),
        item[7]===null?'':item[7].toUpperCase(),
        item[8]===null?'':item[8].toUpperCase(),
        item[9],
        item[10],
        'V',
        user.name
        
  ]);
      setExcelData(updateData);
    }
    reader.readAsBinaryString(file);
}
const handleExcelParticipantes=async(actions)=>{
  //console.log(excelData);
  if(excelColumns===11){
    const response=await AuthService.postParticipanteListado(excelData);
    if(response.status===201){
      Swal.fire(response.body,'','success')
      setExcelData(null);
      const response2= await AuthService.getParticipantes(idactividad);
      setParticipantes(response2.data.body)
      setShow(false);  
    }else{
        Swal.fire('Ha ocurrido un error!',response.body,'error')
    };
    
  }else{
    Swal.fire('Ha ocurrido un error!','Excel seleccionado contiene '+excelColumns+' columnas, se requerieren 11.','error')
  }
}
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Participantes por Actividad
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                <Col sm={2}>
                    <Form.Group className="mb-3" controlId="extranjero">
                      <Form.Label>¿Extranjero?</Form.Label>
                      <Form.Select
                        value={formik.values.extranjero}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option value="0">Seleccione</option>
                        <option value="S">Si</option>
                        <option value="N">No</option>
                      </Form.Select>
                    </Form.Group>
                    {formik.touched.extranjero && formik.errors.extranjero ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.extranjero}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col sm={2}>
                    <Form.Group className="mb-3" controlId="run">
                      <Form.Label>Run</Form.Label>
                      <Form.Control
                        type="number"
                        value={formik.values.run}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.run && formik.errors.run ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.run}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col sm={1}>
                    <Form.Group className="mb-3" controlId="dv">
                    <Form.Label>DV</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.dv}
                        onChange={formik.handleChange}
                        onBlur={e=>{handleBlurRut(e,formik.values)}}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.dv && formik.errors.dv ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.dv}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col sm={2}>
                    <Form.Group className="mb-3" controlId="nombre">
                      <Form.Label>Nombres</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.nombre && formik.errors.nombre ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.nombre}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col sm={2}>
                    <Form.Group className="mb-3" controlId="primerAp">
                      <Form.Label>Primer Apellido</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.primerAp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.primerAp && formik.errors.primerAp ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.primerAp}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col sm={2}>
                    <Form.Group className="mb-3" controlId="segundoAp">
                      <Form.Label>Segundo Apellido</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.segundoAp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.segundoAp && formik.errors.segundoAp ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.segundoAp}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={2}>
                    <Form.Group className="mb-3" controlId="cargo">
                    <Form.Label>Cargo</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.cargo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.cargo && formik.errors.cargo ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.cargo}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col sm={3}>
                    <Form.Group className="mb-3" controlId="super">
                      <Form.Label>Superintendecia</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.super}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.super &&
                    formik.errors.super ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.super}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col sm={2}>
                    <Form.Group className="mb-3" controlId="franquicia">
                      <Form.Label>Franquicia</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.franquicia}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.franquicia &&
                    formik.errors.franquicia ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.franquicia}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col sm={2}>
                    <Form.Group className="mb-3" controlId="nota">
                      <Form.Label>Nota</Form.Label>
                      <Form.Control
                        type="number"
                        value={formik.values.nota}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.nota &&
                    formik.errors.nota ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.nota}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col sm={2}>
                    <Form.Group className="mb-3" controlId="asistencia">
                      <Form.Label>Asistencia</Form.Label>
                      <Form.Control
                        type="number"
                        value={formik.values.asistencia}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.asistencia &&
                    formik.errors.asistencia ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.asistencia}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Button variant="primary" type="submit">
                  Guardar
                </Button>
                {' '}
                <Button variant="success" onClick={handleLimpiar}>
                  Limpiar
                </Button>
                {' '}
                <Button variant="success" onClick={handleExcel}>
                  <FaFileExcel/>Excel
                </Button>
                {' '}
                <Button variant="success" onClick={handleShow}>
                  <FaFileExcel/>Subir Participantes
                </Button>
              </Form>
            </Col>
          </Card.Body>
        </Card>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="excel">
                <Form.Label>Seleccione Archivo</Form.Label>
                <Form.Control
                  type="file"
                  value={formik.values.excel}
                  onChange={handleFileChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {excelName && <p>{excelName}</p>}
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleExcelParticipantes}>
              Cargar Participantes
            </Button>
          </Modal.Footer>
        </Modal>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Participantes ingresados para esta actividad
          </Card.Header>
          <Card.Body style={myBody}>
            <Table striped bordered hover size="sm" variant="dark">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Run</th>
                  <th>Nombre Completo</th>
                  <th>Cargo</th>
                  <th>Super</th>
                  <th>Franquicia</th>
                  <th>Nota</th>
                  <th>Asistencia</th>
                  <th>Situación</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {participantes.map(elemento=>(
                <tr key={"participantes"+elemento.id}>
                <td>{elemento.id}</td>
                <td>{elemento.run}{elemento.extranjero==='S'? null:'-'}{elemento.dv}</td>
                <td>{elemento.nombres} {elemento.primer_apellido} {elemento.segundo_apellido}</td>
                <td>{elemento.cargo}</td>
                <td>{elemento.superintendencia}</td>
                <td>{elemento.franquicia}</td>
                <td>{elemento.nota}</td>
                <td>{elemento.asistencia}</td>
                <td>No realizado</td>
                <td><Button variant="warning" onClick={()=>handleEditar(elemento.id)}>Editar</Button></td>
                <td><Button variant="danger" onClick={()=>handleEliminar(elemento.id)}>Eliminar</Button></td>
              </tr>
                        ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};



export default MantenedorParticipantes;
