import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import Swal from "sweetalert2";
import { AuthContext } from "../../auth/authContext";
import { Table } from "react-bootstrap";

const MantenedorCurso = () => {
  const {user}=useContext(AuthContext);
  const [cursos,setCursos]=useState([{}]);
  const [id,setId]=useState('0');
    useEffect(()=>{
        const cursosData=async()=>{
            const response= await AuthService.getCursos();
            setCursos(response.data.body) 
        }
        cursosData();
    },[])
  const submitForm=async(values,actions)=>{
    const data={'id':id,
                'nombre_curso':values.nombreCurso,
                'observacion':values.observacion,
                'tipo_curso':values.modalidad,
                'cuota_inicial':values.cuotaInicial,
                'numero_cuotas':values.numeroCuotas,
                'cuota_mensual':values.cuotaMensual,
                'costo_total':values.costoTotal,
                'duracion_meses':values.duracionMeses,
                'duracion_horas':values.totalHoras,
                'monto_retraso':values.montoRetraso,
                'estado':'V',
                'usuario_crea':user.name
              }
    const response=await AuthService.postCurso(data);
    if(response.status===201){
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: response.body,
        showConfirmButton: false,
        timer: 1500
      })
        actions.setSubmitting(false);
        actions.resetForm({
          values: {
            nombreCurso: "",
            modalidad: "",
            totalHoras: "",
            duracionMeses: "",
            cuotaInicial: "",
            numeroCuotas: "",
            cuotaMensual: "",
            costoTotal: "",
            montoRetraso: "",
            observacion: "",
          },
        });
        handleLimpiar();
    }else{
        Swal.fire('Ha ocurrido un error!',response.body,'error')
    };

  };

  //validaciones
  const formik = useFormik({
    initialValues: {
      nombreCurso: "",
      modalidad: "",
      totalHoras: "",
      duracionMeses: "",
      cuotaInicial: "",
      numeroCuotas: "",
      cuotaMensual: "",
      costoTotal: "",
      montoRetraso: "",
      observacion: "",
    },
    validationSchema: Yup.object({
      nombreCurso: Yup.string()
        .min(3, "Debe tener al menos 3 caracteres.")
        .required("Es obligatorio"),
      modalidad:  Yup.string().required("Es obligatorio"),
      totalHoras:Yup.number()
        .required("Es obligatorio"),
      duracionMeses: Yup.number()
        .required("Es obligatorio"),
      cuotaInicial:  Yup.number()
        .required("Es obligatorio"),
      numeroCuotas:  Yup.number()
        .required("Es obligatorio"),
      cuotaMensual:  Yup.number()
        .required("Es obligatorio"),
      costoTotal:  Yup.number()
        .required("Es obligatorio"),
      montoRetraso:  Yup.number()
        .required("Es obligatorio"),
      observacion: Yup.string()
      .min(3, "Debe tener al menos 3 caracteres."),
    }),
    onSubmit: submitForm,
  });
  const handleEditar=async(idEdita,values)=>{
    const response=await AuthService.getCursoId(idEdita)
      setId(idEdita);
      formik.setValues({
        ...values,
        nombreCurso: response.data.body[0].nombre_curso,
        modalidad: response.data.body[0].tipo_curso,
        totalHoras: response.data.body[0].duracion_horas,
        duracionMeses: response.data.body[0].duracion_meses,
        cuotaInicial: response.data.body[0].cuota_inicial,
        numeroCuotas: response.data.body[0].numero_cuotas,
        cuotaMensual: response.data.body[0].cuota_mensual,
        costoTotal: response.data.body[0].costo_total,
        montoRetraso: response.data.body[0].monto_retraso,
        observacion: response.data.body[0].observacion
      });
  }
  const handleEliminar=async(idElimina)=>{
    Swal.fire({
      title: '¿Está seguro de eliminar?',
      text: "No se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        const response=await AuthService.putCurso({'id':idElimina,'usuario':user.name})
        if(response.status===200){
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.body,
            showConfirmButton: false,
            timer: 1500
          })
          const response2= await AuthService.getCursos();
          setCursos(response2.data.body)
          handleLimpiar()
        }else{
          Swal.fire('Ha ocurrido un error!',response.body,'error')
        }
        Swal.fire(
          'Eliminado!',
          'El registro fue eliminado.',
          'success'
        )
      }
    })
  }
  const handleLimpiar=async(e,values)=>{
    const response2= await AuthService.getCursos();
    setCursos(response2.data.body)
    formik.setValues({
      ...values,
      nombreCurso: "",
      modalidad: 0,
      totalHoras: "",
      duracionMeses: "",
      cuotaInicial: "",
      numeroCuotas: "",
      cuotaMensual: "",
      costoTotal: "",
      montoRetraso: "",
      observacion: "",
    });
    setId('0');
    
  }
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Mantenedor de Cursos
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="nombreCurso">
                      <Form.Label>Nombre Curso</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.nombreCurso}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.nombreCurso && formik.errors.nombreCurso ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.nombreCurso}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="modalidad">
                      <Form.Label>Modalidad</Form.Label>
                      <Form.Select
                        value={formik.values.modalidad}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option value="0">Seleccione</option>
                        <option value="P">Presencial</option>
                        <option value="O">Online</option>
                      </Form.Select>
                    </Form.Group>
                    {formik.touched.modalidad && formik.errors.modalidad ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.modalidad}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="totalHoras">
                      <Form.Label>Total de Horas</Form.Label>
                      <Form.Control
                        type="number"
                        value={formik.values.totalHoras}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.totalHoras && formik.errors.totalHoras ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.totalHoras}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="duracionMeses">
                      <Form.Label>Duración Meses</Form.Label>
                      <Form.Control
                        type="number"
                        value={formik.values.duracionMeses}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.duracionMeses && formik.errors.duracionMeses ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.duracionMeses}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="cuotaInicial">
                      <Form.Label>Cuota Inicial</Form.Label>
                      <Form.Control
                        type="number"
                        value={formik.values.cuotaInicial}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.cuotaInicial &&
                    formik.errors.cuotaInicial ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.cuotaInicial}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="numeroCuotas">
                      <Form.Label>Número de Cuotas</Form.Label>
                      <Form.Control
                        type="number"
                        value={formik.values.numeroCuotas}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.numeroCuotas &&
                    formik.errors.numeroCuotas ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.numeroCuotas}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="cuotaMensual">
                      <Form.Label>Cuota Mensual</Form.Label>
                      <Form.Control
                        type="number"
                        value={formik.values.cuotaMensual}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.cuotaMensual &&
                    formik.errors.cuotaMensual ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.cuotaMensual}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="costoTotal">
                      <Form.Label>Costo Total</Form.Label>
                      <Form.Control
                        type="number"
                        value={formik.values.costoTotal}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.costoTotal &&
                    formik.errors.costoTotal ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.costoTotal}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="montoRetraso">
                      <Form.Label>Monto Retraso</Form.Label>
                      <Form.Control
                        type="number"
                        value={formik.values.montoRetraso}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.montoRetraso &&
                    formik.errors.montoRetraso ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.montoRetraso}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="observacion">
                      <Form.Label>Observación</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Agregar observación..."
                        value={formik.values.observacion}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Form.Group>
                    {formik.touched.observacion && formik.errors.observacion ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.observacion}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit">
                      Guardar
                    </Button>
                    {' '}
                    <Button variant="success" onClick={handleLimpiar}>
                      Limpiar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Cursos Creados
          </Card.Header>
          <Card.Body style={myBody}>
            <Table striped bordered hover size="sm" variant="dark" responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Curso</th>
                  <th>Tipo Curso</th>
                  <th>Cuota Mensual</th>
                  <th>Costo Total</th>
                  <th>Duración Meses</th>
                  <th>Total Horas</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {cursos.map(elemento=>(
                <tr key={"cursos"+elemento.id}>
                <td>1</td>
                <td>{elemento.nombre_curso}</td>
                <td>{elemento.tipo_curso==='O'?'Online':'Presencial'}</td>
                <td>{elemento.cuota_mensual}</td>
                <td>{elemento.costo_total}</td>
                <td>{elemento.duracion_meses}</td>
                <td>{elemento.duracion_horas}</td>
                <td><Button variant="warning" onClick={()=>handleEditar(elemento.id)}>Editar</Button></td>
                <td><Button variant="danger" onClick={()=>handleEliminar(elemento.id)}>Eliminar</Button></td>
              </tr>
                        ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default MantenedorCurso;
