import React, { useEffect, useState } from "react";
import QRCode from "qrcode";
import { Image, StyleSheet } from "@react-pdf/renderer";

function QRCodeImage(props) {
    //console.log(props.datos.url)
  const [qrImage, setQrImage] = useState(null);

  useEffect(() => {
    const generateQRCode = async () => {
      try {
        const imageUrl = await QRCode.toDataURL(props.datos.url);
        setQrImage(imageUrl);
      } catch (error) {
        console.error("Error generating QR code:", error);
      }
    };

    generateQRCode();
  }, [props.datos.url]);

  return qrImage ? <Image src={qrImage}  style={styles.qrCode} /> : null;
}

export default QRCodeImage;

const styles = StyleSheet.create({
    qrCode: {
      width: 200,
      height: 200,
    },
  });