import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import Swal from "sweetalert2";
import { AuthContext } from "../../auth/authContext";
import { Table } from "react-bootstrap";

const MantenedorAsignatura = () => {
  const {user}=useContext(AuthContext);
  const [cursos,setCursos]=useState([{}]);
  const [asignaturas,setAsignaturas]=useState([]);
  const [id,setId]=useState('0');
  const [numeroActividad,setNumeroActividad]=useState('0');
  const [disabledActividades, setDisabledActividades]=useState(false);
    useEffect(()=>{
        const cursosData=async()=>{
            const response= await AuthService.getCursos();
            setCursos(response.data.body) 
        }
        cursosData();
    },[])
  const submitForm=async(values,actions)=>{
    const data={'id':id,
                'id_curso':values.curso,
                'nombre':values.nombre,
                'numero_actividades':values.tipo_asignatura==='2'?'0':values.actividades,
                'porcentaje_actividad_1':parseFloat(values.actividad_porcentaje_1)||0,
                'porcentaje_actividad_2':parseFloat(values.actividad_porcentaje_2)||0,
                'porcentaje_actividad_3':parseFloat(values.actividad_porcentaje_3)||0,
                'porcentaje_actividad_4':parseFloat(values.actividad_porcentaje_4)||0,
                'porcentaje_actividad_5':parseFloat(values.actividad_porcentaje_5)||0,
                'porcentaje_evaluacion':values.evaluacion_porcentaje,
                'tipo_asignatura':values.tipo_asignatura,
                'estado':'V',
                'usuario_crea':user.name
              }
    const response=await AuthService.postAsignatura(data);
    if(response.status===201){
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: response.body,
        showConfirmButton: false,
        timer: 1500
      })
        actions.setSubmitting(false);
        actions.resetForm({
          values: {
            curso:values.curso,
            nombre: "",
            tipo_asignatura: "",
            actividades: "",
            actividad_porcentaje_1: "",
            actividad_porcentaje_2: "",
            actividad_porcentaje_3: "",
            actividad_porcentaje_4: "",
            actividad_porcentaje_5: "",
            evaluacion_porcentaje: "",
          },
        });
        setId('0')
        handleLimpiar()
    }else{
        Swal.fire('Ha ocurrido un error!',response.body,'error')
    };

  };
  //validaciones
  const formik = useFormik({
    initialValues: {
      curso: "",
      nombre: "",
      tipo_asignatura: "",
      actividades: "",
      actividad_porcentaje_1: "",
      actividad_porcentaje_2: "",
      actividad_porcentaje_3: "",
      actividad_porcentaje_4: "",
      actividad_porcentaje_5: "",
      evaluacion_porcentaje: "",
    },
    validationSchema: Yup.object({
      curso: Yup.string()
        .required("Es obligatorio"),
      nombre:  Yup.string()
              .min(3, "Debe tener al menos 3 caracteres.")
              .required("Es obligatorio"),
      tipo_asignatura:Yup.string().required("Es obligatorio"),
      actividades: Yup.number().when('disabledActividades', {
        is: false,
        then: Yup.number().required("Es obligatorio"),
      }),
      actividad_porcentaje_1:  Yup.number(),
      actividad_porcentaje_2:  Yup.number(),
      actividad_porcentaje_3:  Yup.number(),
      actividad_porcentaje_4:  Yup.number(),
      actividad_porcentaje_5:  Yup.number(),
      evaluacion_porcentaje:  Yup.number()
        .required("Es obligatorio"),
    }),
    onSubmit: submitForm,
  });
  const handleEditar=async(idEdita,values)=>{
    const response=await AuthService.getAsignaturaId(idEdita)
      setId(idEdita);
      formik.setFieldValue('curso', response.data.body[0].id_curso);
      formik.setFieldValue('nombre', response.data.body[0].nombre);
      formik.setFieldValue('tipo_asignatura', response.data.body[0].tipo_asignatura);
      formik.setFieldValue('actividades', response.data.body[0].numero_actividades);
      formik.setFieldValue('actividad_porcentaje_1', response.data.body[0].porcentaje_actividad_1);
      formik.setFieldValue('actividad_porcentaje_2', response.data.body[0].porcentaje_actividad_2);
      formik.setFieldValue('actividad_porcentaje_3', response.data.body[0].porcentaje_actividad_3);
      formik.setFieldValue('actividad_porcentaje_4', response.data.body[0].porcentaje_actividad_4);
      formik.setFieldValue('actividad_porcentaje_5', response.data.body[0].porcentaje_actividad_5);
      formik.setFieldValue('evaluacion_porcentaje', response.data.body[0].porcentaje_evaluacion);
      setNumeroActividad(response.data.body[0].numero_actividades)
  }
  const handleEliminar=async(idElimina)=>{
    Swal.fire({
      title: '¿Está seguro de eliminar?',
      text: "No se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        const response=await AuthService.putAsignatura({'id':idElimina,'usuario':user.name})
        if(response.status===200){
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.body,
            showConfirmButton: false,
            timer: 1500
          })
          handleLimpiar()
          Swal.fire(
            'Eliminado!',
            'El registro fue eliminado.',
            'success'
          )
        }else{
          Swal.fire('Ha ocurrido un error!',response.body,'error')
        }
      }
    })
  }
  const handleLimpiar=(e,values)=>{
    formik.setValues({
      ...values,
      curso: 0,
      nombre: "",
      tipo_asignatura: 0,
      actividades: 0,
      actividad_porcentaje_1: "",
      actividad_porcentaje_2: "",
      actividad_porcentaje_3: "",
      actividad_porcentaje_4: "",
      actividad_porcentaje_5: "",
      evaluacion_porcentaje: "",
    });
    setId('0');
    setAsignaturas([])
    setNumeroActividad(0)
    setDisabledActividades(false)
  }
  const handleBlurCurso=async(e,values)=>{
    if(values.curso!=='0' || values.curso!=='undefined'){
      const response2= await AuthService.getAsignaturasCurso(values.curso);
      
      if(response2.data.status===200){
        setAsignaturas(response2.data.body)
      }else{
        setAsignaturas([])
      }
    }
  }
  const handleChangeCurso=async(e,values)=>{
    formik.setFieldValue('curso', e.target.value);
    if(e.target.value!=='0'){
      const response2= await AuthService.getAsignaturasCurso(e.target.value);
      
      if(response2.data.status===200){
        setAsignaturas(response2.data.body)
      }else{
        setAsignaturas([])
      }
    }else{
      setAsignaturas([])
    }
  }
  const handleChangeActividades=async(e,values)=>{
    formik.setFieldValue('actividades', e.target.value);
    setNumeroActividad(e.target.value)
  }
  const handleChangeTipoAsignatura=async(e,values)=>{
    formik.setFieldValue('tipo_asignatura', e.target.value);
    if(e.target.value==='2'){
      setDisabledActividades(true)
    }else{
      setDisabledActividades(false)
    }
  }
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Mantenedor de Asignaturas
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Seleccione Curso</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="curso">
                      <Form.Select
                        value={formik.values.curso}
                        onChange={handleChangeCurso}
                        onBlur={formik.handleBlur/*e=>{handleBlurCurso(e,formik.values)}*/}
                        className="form-control"
                      >
                        <option value="0">Seleccione</option>
                        {cursos.map(elemento=>(
                        <option key={"cursos"+elemento.id} value={elemento.id}>{elemento.nombre_curso}</option>
                      ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                      {formik.touched.curso && formik.errors.curso ? (
                        <Alert variant="danger">
                          Hubo un error: {formik.errors.curso}
                        </Alert>
                      ) : null}
                  </Col>
                  
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Nombre Asignatura</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="nombre">
                      <Form.Control
                        type="text"
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.nombre && formik.errors.nombre ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.nombre}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Tipo de Asignatura</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="tipo_asignatura">
                    <Form.Select
                        value={formik.values.tipo_asignatura}
                        onChange={handleChangeTipoAsignatura}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option value="0">Seleccione</option>
                        <option value="1">Transversal</option>
                        <option value="2">Técnica</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.tipo_asignatura && formik.errors.tipo_asignatura ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.tipo_asignatura}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                
                <Row>
                  <Col sm={3}>
                    <Form.Label>Número de Actividades</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="actividades">
                      <Form.Select
                        value={formik.values.actividades}
                        onChange={handleChangeActividades}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled={disabledActividades}
                      >
                        <option value="0">Ninguna</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.actividades && formik.errors.actividades ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.actividades}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
              {numeroActividad>0 &&(
                <Row>
                  <Col sm={3}>
                    <Form.Label>Porcentaje por actividad 1</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="actividad_porcentaje_1">
                      <Form.Control
                        type="number"
                        value={formik.values.actividad_porcentaje_1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.actividad_porcentaje_1 && formik.errors.actividad_porcentaje_1 ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.actividad_porcentaje_1}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                )}
                {numeroActividad>1 &&(
                <Row>
                  <Col sm={3}>
                    <Form.Label>Porcentaje por actividad 2</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="actividad_porcentaje_2">
                      <Form.Control
                        type="number"
                        value={formik.values.actividad_porcentaje_2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.actividad_porcentaje_2 && formik.errors.actividad_porcentaje_2 ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.actividad_porcentaje_2}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                )}
                {numeroActividad>2 &&(
                <Row>
                  <Col sm={3}>
                    <Form.Label>Porcentaje por actividad 3</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="actividad_porcentaje_3">
                      <Form.Control
                        type="number"
                        value={formik.values.actividad_porcentaje_3}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.actividad_porcentaje_3 && formik.errors.actividad_porcentaje_3 ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.actividad_porcentaje_3}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                )}
                {numeroActividad>3 &&(
                <Row>
                  <Col sm={3}>
                    <Form.Label>Porcentaje por actividad 4</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="actividad_porcentaje_4">
                      <Form.Control
                        type="number"
                        value={formik.values.actividad_porcentaje_4}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.actividad_porcentaje_4 && formik.errors.actividad_porcentaje_4 ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.actividad_porcentaje_4}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                )}
                {numeroActividad>4 &&(
                <Row>
                  <Col sm={3}>
                    <Form.Label>Porcentaje por actividad 5</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="actividad_porcentaje_5">
                      <Form.Control
                        type="number"
                        value={formik.values.actividad_porcentaje_5}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.actividad_porcentaje_5 && formik.errors.actividad_porcentaje_5 ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.actividad_porcentaje_5}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                )}
                <Row>
                  <Col sm={3}>
                    <Form.Label>Porcentaje por Evaluación</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="evaluacion_porcentaje">
                      <Form.Control
                        type="number"
                        value={formik.values.actividades==='0'?'100':formik.values.evaluacion_porcentaje}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.evaluacion_porcentaje && formik.errors.evaluacion_porcentaje ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.evaluacion_porcentaje}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit">
                      Guardar
                    </Button>
                    {' '}
                    <Button variant="success" onClick={handleLimpiar}>
                      Limpiar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Asignaturas Creadas
          </Card.Header>
          <Card.Body style={myBody}>
            <Table striped bordered hover size="sm" variant="dark" responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Asignatura</th>
                  <th>Número de Actividades</th>
                  <th>% Actividad 1</th>
                  <th>% Actividad 2</th>
                  <th>% Actividad 3</th>
                  <th>% Actividad 4</th>
                  <th>% Actividad 5</th>
                  <th>% Evaluación</th>
                  <th>Tipo Asignatura</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {asignaturas.length>0?asignaturas.map((elemento,index)=>(
                <tr key={"asignaturas"+elemento.id}>
                <td>{index+1}</td>
                <td>{elemento.nombre}</td>
                <td>{elemento.numero_actividades}</td>
                <td>{elemento.porcentaje_actividad_1}</td>
                <td>{elemento.porcentaje_actividad_2}</td>
                <td>{elemento.porcentaje_actividad_3}</td>
                <td>{elemento.porcentaje_actividad_4}</td>
                <td>{elemento.porcentaje_actividad_5}</td>
                <td>{elemento.porcentaje_evaluacion}</td>
                <td>{elemento.tipo_asignatura===1?'Transversal':'Técnica'}</td>
                <td><Button variant="warning" onClick={()=>handleEditar(elemento.id)}>Editar</Button></td>
                <td><Button variant="danger" onClick={()=>handleEliminar(elemento.id)}>Eliminar</Button></td>
              </tr>)):
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              }
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default MantenedorAsignatura;
