import React, { useContext,useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import { AuthContext } from "../../auth/authContext";
import Swal from "sweetalert2";


const Programacion= (data) => {
  const [id]=useState(data.data);
  const {user}=useContext(AuthContext );
  const [idimputable,setIdimputable]=useState('');
  const [horarios,setHorarios]=useState([]);

  useEffect(()=>{
    const horariosData=async()=>{
      const response= await AuthService.getHorarios();
      setHorarios(response.data.body)
    }
    horariosData();
    const programacionData=async(values)=>{
        const response= await AuthService.getActividad(id);
        setIdimputable(response.data.body[0].id_imputable)
        formik.setValues({
          ...values,
          inicio: response.data.body[0].fecha_inicio===null ? '': response.data.body[0].fecha_inicio.substring(0,10),
          termino: response.data.body[0].fecha_termino===null ? '': response.data.body[0].fecha_termino.substring(0,10),
          horas: response.data.body[0].horas===null ? '': response.data.body[0].horas,
          horario:response.data.body[0].id_horario===null ? '': response.data.body[0].id_horario
        })
    }
    programacionData();
  },[])
    const submitForm=async(values,actions)=>{
      const data={'id':id,
                  'id_imputable':idimputable,
                  'fecha_inicio':values.inicio,
                  'fecha_termino':values.termino==='' ? null : values.termino,
                  'horas':values.horas==='' ? null : values.horas,
                  'id_horario':values.horario,
                  'usuario_modifica':user.name,
                  'estado':'V'
      }
       
        const response=await AuthService.postActividad(data);
        if(response.status===201){
            Swal.fire(response.body,'','success')
        }else{
            Swal.fire('Ha ocurrido un error!',response.body,'error')
        };
        
    };
    //validaciones
    const formik = useFormik({
    initialValues: {
        inicio: "",
        termino:"",
        horas: "",
    },
    validationSchema: Yup.object({
        inicio:  Yup.date().required("Es obligatorio"),
        termino:  Yup.date(),
        horas: Yup.number(),
    }),
    onSubmit: submitForm,
  });

  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Programación de Actividad
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="inicio">
                      <Form.Label>Fecha Inicio:</Form.Label>
                      <Form.Control
                        type="date"
                        value={formik.values.inicio}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.inicio && formik.errors.inicio ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.inicio}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="termino">
                      <Form.Label>Fecha Término:</Form.Label>
                      <Form.Control
                        type="date"
                        value={formik.values.termino}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.termino && formik.errors.termino ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.termino}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="horas">
                      <Form.Label>Duración Horas:</Form.Label>
                      <Form.Control
                        type="number"
                        value={formik.values.horas}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.horas && formik.errors.horas ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.horas}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="horario">
                      <Form.Label>Horario:</Form.Label>
                      <Form.Select
                        value={formik.values.horario}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option value="0">Seleccione</option>
                        {horarios.length>0?horarios.map(elemento=>(
                        <option key={"horarios"+elemento.id} value={elemento.id}>{elemento.hora_inicio_manana.substring(0,5)}-{elemento.hora_termino_manana.substring(0,5)} a {elemento.hora_inicio_tarde.substring(0,5)}-{elemento.hora_termino_tarde.substring(0,5)}</option>
                      )):''}
                      </Form.Select>
                    </Form.Group>
                    {formik.touched.horario && formik.errors.horario ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.horario}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit">
                      Guardar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default Programacion;
