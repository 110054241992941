import React, { useRef, useState, useEffect, useContext } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
//import Logo from "../../assets/cfc-unap/PNG/3000px/COLOR CORPORATIVO/CFC-UNAP-imagotipo-vertical(3000px).png";
import Logo from "../../assets/bienvenida.png";
import { useNavigate } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
//import {useAuth} from "../../context/AuthProvider"
import AuthService from "../../api/ApiRest";
import { types } from "../../types/types";
import { AuthContext } from "../../auth/authContext";


function Login() {
  const {dispatch} = useContext(AuthContext);
  //const {setAuth}= useAuth();

  const navigate = useNavigate();
  
  const userRef =useRef();
  const errRef = useRef();
  const [user,setUser]=useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg]=useState('');

  useEffect(()=>{
    userRef.current.focus();
  },[])
  useEffect(()=>{
    setErrMsg('');
  },[user,pwd])
  const handleSubmit = async (e)=>{
    e.preventDefault();
    try {
        const response=await AuthService.login(user,pwd);
        const accessToken=response.data.body;
        try {
          const tokenPayload = accessToken.split('.')[1];
          const decodedPayload = base64UrlDecode(tokenPayload);
          const payloadObj = JSON.parse(decodedPayload);

          const responseUser =await AuthService.getUsuarioId(payloadObj.id);
          const action={type:types.login,
                          payload:{ id:responseUser.data.body[0].id,
                                    name:user,
                                    nombres:responseUser.data.body[0].nombres,
                                    primer_apellido: responseUser.data.body[0].primer_apellido,
                                    segundo_apellido: responseUser.data.body[0].segundo_apellido,
                                    correo_electronico: responseUser.data.body[0].correo_electronico,
                                    token:accessToken}
                          }
            dispatch(action);

        } catch (error) {
          console.error('Error decoding the JWT:', error);
        }

        const lastPath=localStorage.getItem('lastPath')||'/';
            //setAuth({user,pwd,accessToken});
            //setUser('');
            //setPwd('');
            navigate(lastPath,{replace:true});
    } catch (error) {
        if(!error?.response){
            setErrMsg('Servidor no responde');
        }else if(error.response?.status===500){
            setErrMsg('Datos erroneos');
        }else if(error.response?.status===400){
            setErrMsg('Usuario y contraseña desconocidos');
        }else if(error.response?.status===401){
            setErrMsg('No autorizado');
        }else{
            setErrMsg('Ha fallado');
        }
        errRef.current.focus();
    }
    setUser('');
    setPwd('');
  }
  const base64UrlDecode = (base64Url) => {
    const padding = '='.repeat((4 - base64Url.length % 4) % 4);
    const base64 = (base64Url + padding).replace(/-/g, '+').replace(/_/g, '/');
    return atob(base64);
  };
  return (
    <div style={fondo}>
      <Container style={contenedor}>
        <Card style={tarjeta}>
          <Card.Img variant="top" src={Logo} style={logoEstilo}/>
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="usuario">
                    <FloatingLabel
                      controlId="usuario"
                      label="Usuario"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        ref={userRef}
                        className="form-control"
                        onChange={(e)=>setUser(e.target.value)}
                        value={user}
                        placeholder="Usuario"
                        required
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="password">
                    <FloatingLabel
                      controlId="password"
                      label="Contraseña"
                      className="mb-3"
                    >
                      <Form.Control
                        type="password"
                        className="form-control"
                        onChange={(e)=>setPwd(e.target.value)}
                        value={pwd}
                        placeholder="Contraseña"
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {errMsg && (
                  <Alert variant="danger">
                    Hubo un error:<p id={errRef}>{errMsg}</p>
                  </Alert>
                )}
              </Row>
              <Row>
                <div className="d-flex justify-content-between mb-4">
                  <Form.Check 
                    name="flexCheck"
                    value=""
                    id="flexCheckDefault"
                    label="Recordar"
                  />
                </div>
              </Row>
              <Row>
                <Col className="d-grid gap-2">
                  <Button variant="primary" size="lg" type="submit">
                    Acceder
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}
const contenedor = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
};
const cardStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};
const tarjeta = {
  border: "1px solid rgba(0,0,0,1)",
  width: "25rem",
};
const fondo = {
  background:
    "linear-gradient(to right, rgba(0, 0, 0, 1), rgba(94, 99, 102, 1))",
    margin: 0,
    padding: 0,
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};
const logoEstilo = {
  display: 'block',
  margin: '0 auto',
  maxWidth: '550px',
  maxHeight: '550px',
};
export default Login;