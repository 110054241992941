import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';
import { format, isValid, parse} from 'date-fns';
import { Table } from "react-bootstrap";


const MantenedorSence= () => {
  const [sence,setSence]=useState([{}]);
  useEffect(()=>{
      const senceData=async()=>{
          const response= await AuthService.getSence();
          setSence(response.data.body) 
      }
      senceData();
  },[])

  const [excelData,setExcelData]=useState(null);
  const [excelName,setExcelName]=useState('');

  const handleFileChange=(event)=>{
    formik.setFieldValue('excel',  event.target.value);
    if(event.target.files[0].type==='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
      setExcelName(event.target.files[0].name)
      const file=event.target.files[0];
      const reader= new FileReader();
      reader.onload=(e)=>{
          const workbook=XLSX.read(e.target.result,{type:"binary"});
          const worksheet= workbook.Sheets[workbook.SheetNames[0]];
          const jsonData= XLSX.utils.sheet_to_json(worksheet,{header:1,defval:null});
          const jsonExcel=jsonData.splice(1,jsonData.length);
          const formattedData=jsonExcel.map((row)=>
            row.map((cell)=>{
              const parsedDate=parse(cell,'dd-MM-yyyy',new Date());
              if(isValid(parsedDate)){
                return format(parsedDate,'yyyy-MM-dd');
              }
              return cell;
            })
          );
          setExcelData(formattedData);
          
        }
      reader.readAsBinaryString(file);
      
    }
  }
  const submitForm=async(actions)=>{

      const response=await AuthService.postSence(excelData);
      if(response.status===201){
          Swal.fire(response.body,'','success')
          actions.resetForm({
            values: {
              excel: "",
            },
          });
          
      }else{
          Swal.fire('Ha ocurrido un error!',response.body,'error')
      };
  };
  const validateExcelFile = (value) => {
    if (!value) {
      // Si el campo está vacío o es undefined, se considera un error
      return false;
    }

    if (typeof value !== '') {
      const allowedExtensions = ['xls', 'xlsx']; // Extensiones permitidas
      const fileName = value.split('\\').pop(); 
      const fileExtension = fileName.split('.').pop(); // Obtener la extensión del archivo

      if (allowedExtensions.includes(fileExtension.toLowerCase())) {
        return true; // El archivo tiene una extensión permitida
      }
    }
  
    return false; // El archivo tiene una extensión no permitida o el valor no es válido
  };
    //validaciones
    const formik = useFormik({
    initialValues: {
        excel: '',
    }, 
    validationSchema: Yup.object({
      excel: Yup.mixed()
      .test('fileType', 'Solo se permiten archivos Excel (xls, xlsx).', validateExcelFile)
      .required('Este campo es requerido')
    }),
    onSubmit: submitForm,
  });

  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Mantenedor de Códigos Sence
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="excel">
                      <Form.Label>Adjunte Archivo Excel:</Form.Label>
                      <Form.Control
                        type="file"
                        value={formik.values.excel}
                        onChange={handleFileChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.excel && formik.errors.excel ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.excel}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit">
                      Guardar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Cursos Sence cargados
          </Card.Header>
          <Card.Body style={myBody}>
            <Table striped bordered hover size="sm" variant="dark" 
                    className="text-center" 
                    responsive>
              <thead>
                <tr>
                	<th>C&oacute;digo Curso</th>
                  <th>N&deg; Solicitud Curso</th>
                  <th>Nombre Curso</th>
                  <th>Modalidad</th>
                  <th>Modo</th>
                  <th>Nivel</th>
                  <th>Tipo</th>
                  <th>N&deg; Horas</th>
                  <th>Valor Franquicia</th>
                  <th>Valor Efectivo Participante</th>
                  <th>Valor Imputable Participante</th>
                  <th>N&deg; Resoluci&oacute;n Autorizaci&oacute;n</th>
                  <th>Estado</th>
                  <th>N&deg; Deposito</th>
                  <th>Fecha Ingreso</th>
                  <th>Fecha Evaluaci&oacute;n</th>
                  <th>Fecha Resoluci&oacute;n</th>
                  <th>Fecha Vigencia</th>
                  <th>Fecha Pago</th>
                </tr>
              </thead>
              <tbody>
              {sence.map(elemento=>(
              <tr key={"actividad"+elemento.id}>
                <td>{elemento.codigo_curso}</td>
                <td>{elemento.solicitud_curso}</td>
                <td>{elemento.nombre_curso}</td>
                <td>{elemento.modalidad}</td>
                <td>{elemento.grupo}</td>
                <td>{elemento.tipo}</td>
                <td>{elemento.nivel}</td>
                <td>{elemento.horas}</td>
                <td>{elemento.valor_franquicia}</td>
                <td>{elemento.valor_efectivo}</td>
                <td>{elemento.valor_imputable}</td>
                <td>{elemento.resolucion}</td>
                <td>{elemento.estado}</td>
                <td>{elemento.numero_deposito}</td>
                <td>{elemento.fecha_ingreso}</td>
                <td>{elemento.fecha_evaluacion}</td>
                <td>{elemento.fecha_resolucion}</td>
                <td>{elemento.fecha_vigencia}</td>
                <td>{elemento.fecha_pago}</td>
              </tr>
                        ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default MantenedorSence;
