import React, { useContext,useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import { AuthContext } from "../../auth/authContext";
import Swal from "sweetalert2";
import { Table } from "react-bootstrap";
import {BsSearch} from "react-icons/bs";
import Modal from 'react-bootstrap/Modal';

const MantenedorPermisos= () => {
  const [id,setId]=useState('0');
  const [permisos,setPermisos]=useState([]);
  const [busquedaUsuarios,setBusquedaUsuarios]=useState([]);

  const {user}=useContext(AuthContext );

  const submitForm=async(values,actions)=>{
    handleActualizaTabla();     
  };
  //validaciones
  const formik = useFormik({
    initialValues: {
      nombre: "",
      primerAp:"",
      segundoAp:"",
      correo: "",
      usuario: "",
      SearchNombre: "",
      SearchPrimerAp: "",
      SearchSegundoAp: ""
    },
    validationSchema: Yup.object({
      nombre:  Yup.string().required("Es obligatorio"),
      primerAp:  Yup.string().required("Es obligatorio"),
      segundoAp: Yup.string().min(3, "Debe tener al menos 3 caracteres."),
      correo: Yup.string().min(4, "Debe tener al menos 3 caracteres.").email().required("Es obligatorio"),
      usuario: Yup.string().min(5, "Debe tener al menos 5 caracteres.").required("Es obligatorio"),
        SearchNombre: Yup.string(),
        SearchPrimerAp: Yup.string(),
        SearchSegundoAp: Yup.string(),
    }),
    onSubmit: submitForm,
  });


  const handleActualizaTabla=async()=>{
    const response= await AuthService.getPermisosUsuario(id);
    if(response.data.status===200){
      setPermisos(response.data.body)
    }else{
      Swal.fire(response.data.body,'','error')
    }
    
  }
  const handleLimpiar=(e,values)=>{
    formik.setValues({
      ...values,
      nombre: "",
      primerAp:"",
      segundoAp:"",
      correo: "",
      usuario: "",
      SearchNombre: "",
      SearchPrimerAp: "",
      SearchSegundoAp: ""
      }
    );
    setId('0');
    setPermisos([])
  }

    const handleSwitchGuardar =async (id_permiso) => {
      const data={'id_usuario':id,
                'id_permiso':id_permiso,
                'estado':'V',
                'usuario_crea':user.name
      }
      
      const response=await AuthService.postPermisosUsuario(data);
      if(response.status===201){
          Swal.fire(response.body,'','success')
          handleActualizaTabla();
      }else{
          Swal.fire('Ha ocurrido un error!',response.body,'error')
      };

    };
    const handleSwitchEliminar = async (idElimina) => {
      
      const response=await AuthService.putPermisosUsuario({'id':idElimina,'usuario':user.name})
      if(response.status===200){
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: response.body,
          showConfirmButton: false,
          timer: 1500
        })
        handleActualizaTabla();
        Swal.fire(
          'Eliminado!',
          'El registro fue eliminado.',
          'success'
        )
      }else{
        Swal.fire('Ha ocurrido un error!',response.body,'error')
      }
    };
    const [show, setShow] = useState(false);

    const handleClose = () => {
      setShow(false);
      formik.setFieldValue('SearchNombre', '');
      formik.setFieldValue('SearchPrimerAp', '');
      formik.setFieldValue('SearchSegundoAp', '');
      setBusquedaUsuarios([])
    }
    const handleSearch=()=>{
      setShow(true);
    }
    const handleBuscar=async(e,values)=>{
      const response= await AuthService.postUsuariosNombres(values.SearchNombre,values.SearchPrimerAp,values.SearchSegundoAp);
      if(response.data.status===200){
        setBusquedaUsuarios(response.data.body)
      }else{
        Swal.fire(response.data.body,'','error')
      }
      
    }
    const handleSeleccionar=async(id)=>{
      const response= await AuthService.getUsuarioId(id);
      if(response.data.status===200){
        setId(response.data.body[0].id)
        formik.setFieldValue('usuario', response.data.body[0].usuario);
        formik.setFieldValue('nombre', response.data.body[0].nombres);
        formik.setFieldValue('primerAp', response.data.body[0].primer_apellido);
        formik.setFieldValue('segundoAp', response.data.body[0].segundo_apellido);
        formik.setFieldValue('correo', response.data.body[0].correo_electronico);
      }else{
        Swal.fire(response.data.body,'','error')
      }
      handleClose()
    }
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Mantenedor de Permisos
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Usuario</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="usuario">
                      <Form.Control
                        type="text"
                        value={formik.values.usuario}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={1}>
                    <Form.Group className="mb-1" controlId="search">
                      <Button variant="warning" onClick={() => handleSearch()}>
                      <BsSearch/>
                    </Button>
                      
                    </Form.Group>
                  
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Nombres</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="nombre">
                      <Form.Control
                        type="text"
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Primer Apellido</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="primerAp">
                      <Form.Control
                        type="text"
                        value={formik.values.primerAp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Segundo Apellido</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="segundoAp">
                      <Form.Control
                        type="text"
                        value={formik.values.segundoAp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Correo Electrónico</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="correo">
                      <Form.Control
                        type="email"
                        value={formik.values.correo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit">
                      Ver
                    </Button>
                    {' '}
                    <Button variant="success" onClick={handleLimpiar}>
                      Limpiar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Permisos creados
          </Card.Header>
          <Card.Body style={myBody}>
            <Table striped bordered hover size="sm" variant="dark" responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Detalle de permiso</th>
                  <th>Pantalla</th>
                  <th>Activar</th>
                </tr>
              </thead>
              <tbody>
              {permisos?permisos.map((elemento,index)=>(
                <tr key={"permisos"+elemento.id}>
                <td>{index+1}</td>
                <td>{elemento.nombre}</td>
                <td>{elemento.tabla}</td>
                <td><Form.Check
                          key={elemento.id}
                          type="switch"
                          id={elemento.id}
                          checked={elemento.id_permiso_asignado!==null?true:false}
                          onChange={elemento.id_permiso_asignado!==null?() => handleSwitchEliminar(elemento.id_permiso_asignado):() => handleSwitchGuardar(elemento.id)}
                        />
                </td>
              </tr>
              )):<tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Buscar Usuario</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col>
            <Form.Group className="mb-3" controlId="SearchNombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.SearchNombre}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="SearchPrimerAp">
              <Form.Label>Primer Apellido</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.SearchPrimerAp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="SearchSegundoAp">
              <Form.Label>Segundo Apellido</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.SearchSegundoAp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
              />
            </Form.Group>
          </Col>
          <Table striped bordered hover size="sm" variant="dark" className="text-center">
              <thead>
                <tr>
                  <th>Nombre Completo</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {busquedaUsuarios.length>0?busquedaUsuarios.map(elemento=>(
                <tr key={"busquedausuario"+elemento.id}>
                <td>{elemento.primer_apellido} {elemento.segundo_apellido} {elemento.nombres}</td>
                <td><Button variant="warning" onClick={()=>handleSeleccionar(elemento.id)}>Seleccionar</Button></td>
              </tr>
               )):null}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={e=>{handleBuscar(e,formik.values)}}>
              Buscar
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default MantenedorPermisos;
