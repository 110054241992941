import axios from "./Axios"

const login = (username, password) => {
  return axios
    .post("auth/login", {'usuario':username,'password':password})
    .then((response) => {
      if (response.data.body) {
        localStorage.setItem("Token", response.data.body);
      }
      return response;
    });
};

const token=localStorage.getItem("Token");

const logout = () => {
  localStorage.removeItem("Token");
};

const postAlumno = (data) => {
  return axios
    .post("alumnos", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const putAlumno = (data) => {
  return axios
    .put("alumnos", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getAlumnoRun = (id) => {
  return axios
    .get(`alumnos/run/${id}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const postAlumnosNombres = (nombres,primer_apellido,segundo_apellido) => {
  return axios
    .post(`alumnos/nombres?nombres=${nombres}&primer_apellido=${primer_apellido}&segundo_apellido=${segundo_apellido}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const getTiposFolios = () => {
  return axios
    .get("folios")
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const postFolio = (data) => {
  return axios
    .post("foliador", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getProcesos = () => {
  return axios
    .get("procesos")
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const getProcesoId = (id) => {
  return axios
    .get(`procesos/${id}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const postProceso = (data) => {
  return axios
    .post("procesos", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const putProceso = (data) => {
  return axios
    .put("procesos", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const postCurso = (data) => {
  return axios
    .post("cursos", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const putCurso = (data) => {
  return axios
    .put("cursos", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getCursos = () => {
  return axios
    .get("cursos")
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const getCursoId = (id) => {
  return axios
    .get(`cursos/${id}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const getCursoAlumno = (id) => {
  return axios
    .get(`cursos/alumno/${id}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const postCursoAlumno = (data) => {
  return axios
    .post("cursosalumnos", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getCursosAlumnos = () => {
  return axios
    .get("cursosalumnos")
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const getCursoAlumnoId = (id) => {
  return axios
    .get(`cursosalumnos/${id}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const postFoliarAlumno = (data) => {
  return axios
    .post("cursosalumnos/foliar", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const postEmpresa = (data) => {
  return axios
    .post("empresas", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getEmpresas = () => {
  return axios
    .get("empresas")
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const getEmpresaId = (id) => {
  return axios
    .get(`empresas/${id}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const putEmpresa = (data) => {
  return axios
    .put("empresas", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const postImputable = (data) => {
  return axios
    .post("imputables", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getImputables = () => {
  return axios
    .get("imputables")
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const getImputable = (id) => {
  return axios
    .get(`imputables/${id}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const getImputableEmpresa = (id) => {
  return axios
    .get(`imputables/empresa/${id}`)
    .then((response) => {
        return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const putImputable = (data) => {
  return axios
    .put("imputables", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const postActividad = (data) => {
  return axios
    .post("actividades", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const postFinalizaActividad = (data) => {
  return axios
    .post("actividades/finaliza", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getActividades = () => {
  return axios
    .get("actividades")
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const getActividad = (id) => {
  return axios
    .get(`actividades/${id}`)
    .then((response) => {
        return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const putActividad = (data) => {
  return axios
    .put("actividades", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getActividadPDF = (id) => {
  return axios
    .get(`actividades/${id}`, {
      responseType: 'blob',
    })
    .then((response) => {
        return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const postArea = (data) => {
  return axios
    .post("areas", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getAreas = () => {
  return axios
    .get("areas")
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const getArea = (id) => {
  return axios
    .get(`areas/${id}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const putArea = (data) => {
  return axios
    .put("areas", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const postInstructor = (data) => {
  return axios
    .post("instructores", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getInstructores = () => {
  return axios
    .get("instructores")
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const getInstructor = (id) => {
  return axios
    .get(`instructores/${id}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const putInstructor = (data) => {
  return axios
    .put("instructores", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getParticipantes = (id) => {
  return axios
    .get(`participantes/actividad/${id}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const postParticipante = (data) => {
  return axios
    .post("participantes", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const postParticipanteListado = (data) => {
  return axios
    .post("participantes/listado", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getParticipante = (id) => {
  return axios
    .get(`participantes/${id}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const postFoliarParticipante = (data) => {
  return axios
    .post("participantes/foliar", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const putParticipante = (data) => {
  return axios
    .put("participantes", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const postCiclo = (data) => {
  return axios
    .post("ciclos", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getCiclos = () => {
  return axios
    .get("ciclos")
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const putCiclo = (data) => {
  return axios
    .put("ciclos", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getCicloId = (id) => {
  return axios
    .get(`ciclos/${id}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const postSence = (data) => {
  return axios
    .post("sence", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getSence = () => {
  return axios
    .get("sence")
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const getSenceId = (id) => {
  return axios
    .get(`sence/${id}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const postActividadTipo = (data) => {
  return axios
    .post("actividad", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getActividadTipo = () => {
  return axios
    .get("actividad")
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const getActividadTipoId = (id) => {
  return axios
    .get(`actividad/${id}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const putActividadTipo = (data) => {
  return axios
    .put("actividad", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const postAsignatura = (data) => {
  return axios
    .post("asignaturas", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getAsignaturasCurso = (id) => {
  return axios
    .get(`asignaturas/curso/${id}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const getAsignaturaId = (id) => {
  return axios
    .get(`asignaturas/${id}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const putAsignatura = (data) => {
  return axios
    .put("asignaturas", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getNotasAlumnoCurso = (idalumno,idCurso) => {
  return axios
    .get(`notasalumnos/alumno/${idalumno}/curso/${idCurso}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const postNotasAlumnoCurso = (data) => {
  return axios
    .post("notasalumnos", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const postFirma = (data) => {
  return axios
    .post("firmas", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const postFirmaHabilitar = (data) => {
  return axios
    .post("firmas/habilitar", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getFirmas = () => {
  return axios
    .get("firmas")
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const putFirma = (data) => {
  return axios
    .put("firmas", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getFirmaId = (id) => {
  return axios
    .get(`firmas/${id}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const postHorario = (data) => {
  return axios
    .post("horarios", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getHorarios = () => {
  return axios
    .get("horarios")
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const putHorario = (data) => {
  return axios
    .put("horarios", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getHorarioId = (id) => {
  return axios
    .get(`horarios/${id}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const postUsuario = (data) => {
  return axios
    .post("usuarios", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const putUsuario = (data) => {
  return axios
    .put("usuarios", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getUsuarios = () => {
  return axios
    .get('usuarios', {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error.response;
    })
};
const getUsuarioId = (id) => {
  return axios
    .get(`usuarios/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const postUsuariosNombres = (nombres,primer_apellido,segundo_apellido) => {
  return axios
    .post(`usuarios/nombres?nombres=${nombres}&primer_apellido=${primer_apellido}&segundo_apellido=${segundo_apellido}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const getNacionalidades = () => {
  return axios
    .get("nacionalidades")
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const getFoliosDetalle = () => {
  return axios
    .get("foliosdetalle")
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const getFoliosDetalleId = (id) => {
  return axios
    .get(`foliosdetalle/${id}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const getFoliosDetalleTipoFolio = (id) => {
  return axios
    .get(`foliosdetalle/tipofolio/${id}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const getFoliosDetalleUsuario = (id) => {
  return axios
    .get(`foliosdetalle/usuario/${id}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const postFolioDetalle = (data) => {
  return axios
    .post("foliosdetalle", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const putFolioDetalle = (data) => {
  return axios
    .put("foliosdetalle", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getPermisosUsuario = (id) => {
  return axios
    .get(`permisosusuarios/${id}`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const postPermisosUsuario = (data) => {
  return axios
    .post("permisosusuarios", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const putPermisosUsuario = (data) => {
  return axios
    .put("permisosusuarios", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
const getPerfiles = () => {
  return axios
    .get(`perfiles`)
    .then((response) => {
      return response;
    })  
    .catch((error)=>{
      return error;
    })
};
const postPerfiles = (data) => {
  return axios
    .post("perfiles", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return error.response.data;
    })
};
export default {
  login,
  logout,
  postAlumno,
  putAlumno,
  getAlumnoRun,
  postAlumnosNombres,
  getTiposFolios,
  postFolio,
  getProcesos,
  getProcesoId,
  postProceso,
  putProceso,
  postCurso,
  putCurso,
  getCursos,
  getCursoId,
  getCursoAlumno,
  getCursosAlumnos,
  getCursoAlumnoId,
  postCursoAlumno,
  postFoliarAlumno,
  postEmpresa,
  getEmpresas,
  getEmpresaId,
  putEmpresa,
  postImputable,
  getImputable,
  getImputables,
  getImputableEmpresa,
  putImputable,
  postActividad,
  postFinalizaActividad,
  getActividades,
  getActividad,
  putActividad,
  getActividadPDF,  
  postArea,
  getAreas,
  getArea,
  putArea,
  postInstructor,
  getInstructores,
  getInstructor,
  putInstructor,
  getParticipantes,
  getParticipante,
  postParticipante,
  postParticipanteListado,
  postFoliarParticipante,
  putParticipante,
  postCiclo,
  getCiclos,
  putCiclo,
  getCicloId,
  postSence,
  getSence,
  getSenceId,
  postActividadTipo,
  getActividadTipo,
  getActividadTipoId,
  putActividadTipo,
  postAsignatura,
  getAsignaturasCurso,
  getAsignaturaId,
  putAsignatura,
  getNotasAlumnoCurso,
  postNotasAlumnoCurso,
  postFirma,
  postFirmaHabilitar,
  getFirmas,
  putFirma,
  getFirmaId,
  postHorario,
  getHorarios,
  putHorario,
  getHorarioId,
  postUsuario,
  putUsuario,
  getUsuarios,
  getUsuarioId,
  postUsuariosNombres,
  getNacionalidades,
  getFoliosDetalle,
  getFoliosDetalleId,
  getFoliosDetalleTipoFolio,
  getFoliosDetalleUsuario,
  postFolioDetalle,
  putFolioDetalle,
  getPermisosUsuario,
  postPermisosUsuario,
  putPermisosUsuario,
  getPerfiles,
  postPerfiles,
};