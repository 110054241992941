import React, { useEffect, useState} from 'react'
import AuthService from "../../api/ApiRest";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { useParams } from "react-router-dom";
import Logo_cfc from "../../assets/logo_cfc.jpg"
import Logo_unap from "../../assets/unap_new.jpg"
import { Image } from 'react-bootstrap';
import {format, parseISO } from 'date-fns';
import {es} from 'date-fns/locale';

const DiplomaPDF = () => {
  const [id]=useState(useParams().id);

  useEffect(()=>{
    const participantesData=async()=>{
      const response= await AuthService.getParticipante(id);
      let actividad=response.data.body[0].id_actividad;
      const responseA= await AuthService.getActividad(actividad);

      formik.setFieldValue('nombre', response.data.body[0].nombres+' '+response.data.body[0].primer_apellido+' '+response.data.body[0].segundo_apellido);
      formik.setFieldValue('run', response.data.body[0].dv?response.data.body[0].run+'-'+response.data.body[0].dv:response.data.body[0].run);
      formik.setFieldValue('fecha', responseA.data.body[0].fecha_inicio?cambiarFormatoFecha(responseA.data.body[0].fecha_inicio.substring(0,10),'dd-MM-yyyy'):'');
      formik.setFieldValue('curso', responseA.data.body[0].nombre_generico);
      
  }
  participantesData();

},[])
  //validaciones
  const submitForm=async (values,actions)=>{}
  //validaciones
  const formik = useFormik({
    initialValues: {
      nombre: "",
      run: "",
      fecha: "",
      curso: "",
    },
    validationSchema: Yup.object({
      curso: Yup.string().required("Es obligatorio"),
    }),
    onSubmit:submitForm,
  });
  const capitalize=(str)=>{
    return str.charAt(0).toUpperCase()+str.slice(1);
  }
  function cambiarFormatoFecha(fecha,tipo){
    const fechaISO=parseISO(fecha);
    let fechaFormateada='';
    if(tipo==='dd'){
        fechaFormateada=format(fechaISO,'dd');
    }
    if(tipo==='MM'){
        fechaFormateada=capitalize(format(fechaISO,'MMMM',{locale:es}));
    }
    if(tipo==='yyyy'){
        fechaFormateada=format(fechaISO,'yyyy');
    }
    if(tipo==='dd-MM-yyyy'){
        fechaFormateada=format(fechaISO,'dd-MM-yyyy');
    }
    return fechaFormateada;
}
      return (
        <>
        <Container style={contenedor}>
          <Card style={tarjeta}>
            <Card.Header as="h3" style={myHeader}>
              Validador de Certificados
            </Card.Header>
            <Card.Img variant="top" src={Logo_cfc} style={logoEstiloTop}/>
            <Card.Body style={myBody}>
              <Col sm={12}>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col sm={4}><Form.Label>Nombre Alumno</Form.Label></Col>
                  <Col sm={8}>
                    <Form.Group controlId="nombre">
                      <Form.Control
                        type="text"
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}><Form.Label>Run</Form.Label></Col>
                  <Col sm={8}>
                    <Form.Group controlId="run">
                      <Form.Control
                        type="text"
                        value={formik.values.run}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}><Form.Label>Fecha Curso</Form.Label></Col>
                  <Col sm={8}>
                    <Form.Group controlId="fecha">
                      <Form.Control
                        type="text"
                        value={formik.values.fecha}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}><Form.Label>Nombre Curso</Form.Label></Col>
                  <Col sm={8}>
                  <Form.Group controlId="curso">
                      <Form.Control
                        type="text"
                        value={formik.values.curso}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>  
              </Col>
              
                  
                
                  
                    
            </Card.Body>
            <Card.Img variant="botton" src={Logo_unap} style={logoEstiloBotton}/>
          </Card>
        </Container>
      </>
      );
}
const contenedor = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const tarjeta = {
  width: "800px",
  borderTopLeftRadius: '10px', 
  borderTopRightRadius: '10px',
  borderBottomLeftRadius: '10px', 
  borderBottomRightRadius: '10px',
  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
};
const myHeader = {
  marginTop:-8,
  color: "white",
  background: "linear-gradient(135deg, rgba(255, 105, 0, 1) 0%, #88420D 100%)",
  padding: "10px",
  fontFamily: "Sans-serif",
  fontSize: 35,
  textAlign: "center",
  borderBottomLeftRadius: '10px', 
  borderBottomRightRadius: '10px'
};
const myBody = {
  marginTop: 20,
  color: "#1A233F",
  padding: "20px",
  fontFamily: "Sans-Serif",
  fontWeight: 'bold',
  fontSize: 25,
  borderTopLeftRadius: '10px', 
  borderTopRightRadius: '10px',
  borderBottomLeftRadius: '10px', 
  borderBottomRightRadius: '10px',
};
const logoEstiloTop = {
  display: 'block',
  margin: '0 auto',
  maxWidth: '550px',
  maxHeight: '550px',
  width: 220, 
  height: 140,
};
const logoEstiloBotton = {
  display: 'block',
  margin: '0 auto',
  maxWidth: '550px',
  maxHeight: '550px',
  width: 220, 
  height: 210,
};
export default DiplomaPDF
