import React, { useContext,useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import { AuthContext } from "../../auth/authContext";
import Swal from "sweetalert2";
import { Table } from "react-bootstrap";
import {format, parseISO } from 'date-fns';

const MantenedorCiclo= () => {
 
  const {user}=useContext(AuthContext );
  const [ciclos,setCiclos]=useState([]);
  const [id,setId]=useState('0');
  useEffect(()=>{
    const ciclosData=async()=>{
        const response= await AuthService.getCiclos();
        setCiclos(response.data.body) 
    }
    ciclosData();
  },[])
    const submitForm=async(values,actions)=>{
      const data={'id':id,
                  'nombre':values.ciclo,
                  'inicio':values.inicio,
                  'termino':values.termino,
                  'usuario_crea':user.name,
                  'estado':'V'
      }
       
        const response=await AuthService.postCiclo(data);
        if(response.status===201){
            Swal.fire(response.body,'','success')
            actions.setSubmitting(false);
            actions.resetForm({
              values: {
                ciclo: "",
                inico: "",
                termino: "",
              },
            });
            handleLimpiar();
        }else{
            Swal.fire('Ha ocurrido un error!',response.body,'error')
        };
        
    };
    //validaciones
    const formik = useFormik({
    initialValues: {
        ciclo: "",
        inicio: "",
        termino: "",
    },
    validationSchema: Yup.object({
        ciclo:  Yup.string().min(3, "Debe tener al menos 3 caracteres.")
                            .max(100, "Debe tener maximo 100 caracteres.")
                            .required("Es obligatorio"),
        inicio:  Yup.date().required("Es obligatorio"),
        termino:  Yup.date().required("Es obligatorio"),
    }),
    onSubmit: submitForm,
  });
  const handleEditar=async(idEdita,values)=>{
    const response=await AuthService.getCicloId(idEdita)
      setId(idEdita);
      formik.setValues({
        ...values,
        ciclo: response.data.body[0].nombre,
        inicio: response.data.body[0].inicio.substring(0,10),
        termino: response.data.body[0].termino.substring(0,10)
      });
  }
  const handleEliminar=async(idElimina)=>{
    Swal.fire({
      title: '¿Está seguro de eliminar?',
      text: "No se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        const response=await AuthService.putCurso({'id':idElimina,'usuario':user.name})
        if(response.status===200){
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.body,
            showConfirmButton: false,
            timer: 1500
          })
          
          handleLimpiar()
        }else{
          Swal.fire('Ha ocurrido un error!',response.body,'error')
        }
        Swal.fire(
          'Eliminado!',
          'El registro fue eliminado.',
          'success'
        )
      }
    })
  }
  const handleLimpiar=async(e,values)=>{
    formik.setValues({
      ...values,
      ciclo: "",
      inicio: "",
      termino: ""
    });
    setId('0');
    const response2= await AuthService.getCiclos();
    setCiclos(response2.data.body)
  }
  function cambiarFormatoFecha(fecha){
    const fechaISO=parseISO(fecha);
    const fechaFormateada=format(fechaISO,'dd-MM-yyyy');
    return fechaFormateada;
  }
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Mantenedor de Ciclos
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="ciclo">
                      <Form.Label>Nombre del Ciclo:</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.ciclo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.ciclo && formik.errors.ciclo ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.ciclo}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="inicio">
                      <Form.Label>Fecha Inicio:</Form.Label>
                      <Form.Control
                        type="date"
                        value={formik.values.inicio}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.inicio && formik.errors.inicio ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.inicio}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="termino">
                      <Form.Label>Fecha Término:</Form.Label>
                      <Form.Control
                        type="date"
                        value={formik.values.termino}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.termino && formik.errors.termino ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.termino}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit">
                      Guardar
                    </Button>
                    {' '}
                    <Button variant="success" onClick={handleLimpiar}>
                      Limpiar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Ciclos Creados
          </Card.Header>
          <Card.Body style={myBody}>
            <Table striped bordered hover size="sm" variant="dark" responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Ciclo</th>
                  <th>Fecha Inicio</th>
                  <th>Fecha Término</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {ciclos.map(elemento=>(
                <tr key={"cursos"+elemento.id}>
                <td>1</td>
                <td>{elemento.nombre}</td>
                <td>{elemento.inicio?cambiarFormatoFecha(elemento.inicio.substring(0,10)):''}</td>
                <td>{elemento.termino?cambiarFormatoFecha(elemento.termino.substring(0,10)):''}</td>
                <td><Button variant="warning" onClick={()=>handleEditar(elemento.id)}>Editar</Button></td>
                <td><Button variant="danger" onClick={()=>handleEliminar(elemento.id)}>Eliminar</Button></td>
              </tr>
                        ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default MantenedorCiclo;
