import React, { useEffect, useState } from 'react'
import { Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer'
import AuthService from "../../api/ApiRest";
import Fondo from "../../assets/ctm_3.jpg"


const CertificadoNotasPDF = ({datos}) => {
    const [curso,setCurso]=useState([]);
    const [notas,setNotas]=useState([]);
    const [habilitado,setHabilitado]=useState(false);
    const [director,setDirector]=useState([]);
    const [directorS,setDirectorS]=useState([]);
    const [encargado,setEncargado]=useState([]);
    useEffect(()=>{
        
        const cursoData=async()=>{
            const response= await AuthService.getCursoAlumnoId(datos.alumno);
            setCurso(response.data.body[0])
        }
        cursoData();
        const notasData=async()=>{
            const response2= await AuthService.getNotasAlumnoCurso(datos.idAlumno,datos.curso);
            setNotas(response2.data.body)
        }
        notasData();
        const firmaData=async()=>{
            const response= await AuthService.getFirmas();
            for (let index = 0; index < response.data.body.length; index++) {
                if(response.data.body[index].habilitado==='S'){
                    setHabilitado(true)
                }else{
                    setHabilitado(false)
                }
                if(response.data.body[index].cargo==='D'){
                    setDirector(response.data.body[index])
                }
                if(response.data.body[index].cargo==='S'){
                    setDirectorS(response.data.body[index])
                }
                if(response.data.body[index].cargo==='E'){
                    setEncargado(response.data.body[index])
                }
            }
        }
        firmaData();
    },[])
    //const qrContent=JSON.stringify(curso.folio);
      /*<QRCode
        size={256}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value='holamundo'
        viewBox={`0 0 256 256`}
        /> */


    return (
        <Document>
            <Page size="LETTER" style={styles.page}>
                <View style={styles.header} fixed>
                    <Image
                        src={Fondo}
                        style={styles.fondo}
                    />
                </View>
                <View  style={styles.titulo}>
                    <Text>Certificado de Notas</Text>
                </View>
                <View  style={styles.parrafo}>
                    <Text>Nombre : {curso.nombre_alumno} {curso.primer_apellido} {curso.segundo_apellido}</Text>
                    <Text>Rut        : {curso.run}{curso.extranjero==='N' ?'-'+curso.dv:null}</Text>
                    <Text>Curso    : {curso.nombre_curso}</Text>
                </View>
                <View  style={styles.subtitulo}>
                    <Text>Notas del participante</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderAsignatura}>
                            <Text style={styles.tableCellHeader}>Asignaturas</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Act.N°1</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Act.N°2</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Act.N°3</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Act.N°4</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Act.N°5</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Evaluación</Text>
                        </View>
                        <View style={styles.tableColHeaderFin}>
                            <Text style={styles.tableCellHeader}>Nota Final</Text>
                        </View>
                    </View>
                
                    {notas.length>0?notas.map((asignatura, index) => (

                    <View key={index} style={styles.tableRow}>
                        <View style={styles.tableColAsignatura}>
                            <Text style={styles.tableCellAsignatura}>{asignatura.nombre}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{asignatura.nota_actividad_1?asignatura.nota_actividad_1+'%':''}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{asignatura.nota_actividad_2?asignatura.nota_actividad_2+'%':''}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{asignatura.nota_actividad_3?asignatura.nota_actividad_3+'%':''}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{asignatura.nota_actividad_4?asignatura.nota_actividad_4+'%':''}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{asignatura.nota_actividad_5?asignatura.nota_actividad_5+'%':''}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{asignatura.nota_evaluacion?asignatura.nota_evaluacion+'%':''}</Text>
                        </View>
                        <View style={styles.tableColFin}>
                            <Text style={styles.tableCell}>{asignatura.nota_final?asignatura.nota_final+'%':''}</Text>
                        </View>
                    </View>
                
                    )):
                    <View style={styles.tableRow}>
                        <View style={styles.tableColAsignatura}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                    </View>
                    }
                </View>

                <View style={styles.firmasNombre}>
                    <Text style={styles.textoIzquierda}>{encargado.nombre} {encargado.primer_apellido} {encargado.segundo_apellido}</Text>
                    <Text style={styles.textoDerecha}>{directorS.habilitado==='S'?directorS.nombre+' '+directorS.primer_apellido+' '+directorS.segundo_apellido:director.nombre+' '+director.primer_apellido+' '+director.segundo_apellido}</Text>
                </View>
                <View style={styles.firmasCargo}>
                    <Text style={styles.textoIzquierda}>{encargado.habilitado==='S'?'Director(S)':encargado.cargo==='D'?'Director':'Encargado de Proyecto'}</Text>
                    <Text style={styles.textoDerecha}>{directorS.habilitado==='S'?'Director(S)':director.cargo==='D'?'Director':'Encargado de Proyecto'}</Text>
                </View>
                <View style={styles.piePagina} fixed>
                    <Text>Fecha de Emisión: {curso.fecha_actual}</Text>
                </View>
                {/*<View>
                    <QRCode
        size={256}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value='holamundo'
        viewBox={`0 0 256 256`}
        />
                </View>*/}
                    
                
                
            </Page>
        </Document>
    )
}
// Create styles
const styles = StyleSheet.create({
    page: {
        paddingTop: 125,
        paddingBottom: 70,
        paddingHorizontal: 35,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    subtitulo:{
        paddingTop:25,
        fontFamily: 'Helvetica-Bold',
        fontSize: 20,
        textAlign: 'center',
        color:'rgb(245,145,11)'
    },
    fondo:{
        width:"600px",
        height:"900px",
    },
    titulo:{
        marginBottom: 20,
        fontFamily: 'Helvetica-Bold',
        fontSize: 24,
        textAlign: 'center',
        color:'rgb(245,145,11)'
    },
    negrita:{
        fontWeight: 'bold'
    },
    parrafo:{
        fontFamily: 'Helvetica',
        fontSize: 12,
        textAlign: 'left'
    },
    curso:{
        margin: 20,
        fontFamily: 'Helvetica-Bold',
        fontSize: 22,
        textAlign: 'center',
        color:'rgb(245,145,11)'
    },
    firmasNombre:{
        marginTop:50,
        fontFamily: 'Helvetica',
        fontSize: 14,
        textAlign: 'center',
        flexDirection:'row',
        justifyContent:'space-between',
    },
    firmasCargo:{
        fontFamily: 'Helvetica',
        fontSize: 14,
        textAlign: 'center',
        flexDirection:'row',
        justifyContent:'space-between',
    },
    textoIzquierda:{
        textAlign:'left',
    },
    textoDerecha:{
        textAlign:'right',
    },
    header: {
        position: 'absolute',
        marginBottom: 10,
      },
    piePagina: {
        position: 'absolute',
        fontSize: 12,
        bottom: 5,
        left: 0,
        right: 0,
        margin:35,
        textAlign: 'left',
        color: 'grey',
      },
    table: {
        display: 'table',
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        marginTop:20,
      },
    tableRow: {
        flexDirection: 'row'
      },
    tableColHeader: {
        width: '8%',
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderLeftWidth: 1
      },
    tableColHeaderFin: {
        width: '8%',
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth:1
      },
    tableColHeaderAsignatura: {
        width: '50%',
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderLeftWidth: 1
      },
    tableCol: {
        width: '8%',
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderLeftWidth: 1
      },
    tableColFin: {
        width: '8%',
        borderStyle: 'solid',
        borderLeftWidth: 1,
        borderBottomWidth: 1,
        borderRightWidth:1
      },
    tableColAsignatura: {
        width: '50%',
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderLeftWidth: 1
      },
    tableCellHeader: {
        margin: 5,
        fontSize: 10,
        fontWeight: 'bold'
      },
    tableCell: {
        margin: 5,
        fontSize: 10,
        textAlign: 'center'
      },
    tableCellAsignatura: {
        margin: 5,
        fontSize: 10
      }
  });

export default CertificadoNotasPDF
