import React, {useContext,  useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import { AuthContext } from "../../auth/authContext";
import Swal from "sweetalert2";

const FinalizarActividad = (data) => {
  const {user}=useContext(AuthContext);
  const [id,setId]=useState(data.data);
  const [idimputable,setIdimputable]=useState('');
  const [disabledFecha, setDisabledFecha]=useState(false);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [ordenCompraPDF,setOrdenCompraPDF]=useState('');
  const [informeFinalPDF,setInformeFinalPDF]=useState('');
  const [ordenCompraName,setOrdenCompraName]=useState('');
  const [informeFinalName,setInformeFinalName]=useState('');

  const FILE_SIZE = 2048 * 1024;
  const SUPPORTED_FORMATS = [
    "application/pdf"
  ]; 
  
  useEffect(()=>{
      const actividadData=async(values)=>{
          const response= await AuthService.getActividad(id);
          setIdimputable(response.data.body[0].id_imputable)

          setOrdenCompraPDF(response.data.body[0].ruta_archivo_orden_compra)
          setInformeFinalPDF(response.data.body[0].ruta_archivo_informe_final)
          setOrdenCompraName(response.data.body[0].nombre_archivo_orden_compra)
          setInformeFinalName(response.data.body[0].nombre_archivo_informe_final)
          const archivoFileOrdenCompra = new File([response.data.body[0].orden_compra_pdf], response.data.body[0].nombre_archivo_orden_compra, {
            type: response.data.body[0].tipo_archivo_orden_compra, // Proporciona el tipo de archivo adecuado
          });
          const archivoFileInformeFinal = new File([response.data.body[0].informe_final_pdf], response.data.body[0].nombre_archivo_informe_final, {
            type: response.data.body[0].tipo_archivo_informe_final, // Proporciona el tipo de archivo adecuado
          });
          formik.setValues({
            ...values,
            termino: response.data.body[0].fecha_termino===null ? "": response.data.body[0].fecha_termino.substring(0,10),
            orden_compra:response.data.body[0].orden_compra===null ? "":response.data.body[0].orden_compra,
            correo:response.data.body[0].correo_notifica_finaliza===null ? "":response.data.body[0].correo_notifica_finaliza,
            orden_compra_pdf: archivoFileOrdenCompra,
            informe_pdf: archivoFileInformeFinal,
          })
          formik.setFieldValue('orden_compra_pdf', archivoFileOrdenCompra);
          formik.setFieldValue('informe_pdf', archivoFileInformeFinal);

          response.data.body[0].fecha_termino===null ? setDisabledFecha(false):setDisabledFecha(true);
      }
      actividadData();
  },[])
  
  //validaciones
  const submitForm=async (values,actions)=>{
    const formData=new FormData();
    formData.append('id',id)
    formData.append('id_imputable',idimputable)
    formData.append('fecha_termino',values.termino)
    formData.append('orden_compra',values.orden_compra)
    formData.append('correo_notifica_finaliza',values.correo)
    formData.append('orden_compra_pdf',values.orden_compra_pdf)
    formData.append('informe_final_pdf',values.informe_pdf)
    formData.append('usuario_finaliza',user.name)
    formData.append('estado_actividad','F')
    formData.append('estado','V')
    
    const response=await AuthService.postFinalizaActividad(formData);
    if(response.status===201){
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: response.body,
        showConfirmButton: false,
        timer: 1500
      })
    }else{
      Swal.fire('Ha ocurrido un error!',response.body,'error')
    };
  };
  const formik = useFormik({
    initialValues: {
      termino:"",
      orden_compra: "",
      correo:"",
      orden_compra_pdf: "",
      informe_pdf: "",
    },
    validationSchema: Yup.object({
      termino:  Yup.date().required("Es obligatorio"),
      orden_compra: Yup.number(),
      correo: Yup.string()
      .email()
      .min(3, "Debe tener al menos 3 caracteres.")
      .required("Es obligatorio"),
      orden_compra_pdf: Yup.mixed()
      .test(
       "FILE_SIZE",
       "Muy Grande!, debe tener menos de 2MB",
       (value) => value && value.size <= FILE_SIZE
       )
     .test(
       "FILE_TYPE",
       "Formato incorrecto!, debe ser PDF.",
       (value) => value && SUPPORTED_FORMATS.includes(value.type)
       ),
      informe_pdf: Yup.mixed()
      .test(
       "FILE_SIZE",
       "Muy Grande!, debe tener menos de 2MB",
       (value) => value && value.size <= FILE_SIZE
       )
     .test(
       "FILE_TYPE",
       "Formato incorrecto!, debe ser PDF.",
       (value) => value && SUPPORTED_FORMATS.includes(value.type)
       ),
    }),
    onSubmit: submitForm,
    //validate: handleBlurRut
  });
  // Función para manejar la descarga del archivo Blob
  const handleDownload = () => {
    // Creamos un elemento <a> temporal para descargar el archivo
    const downloadLink = document.createElement('a');
    downloadLink.href = fileUrl;
    downloadLink.download = 'informe_final.pdf'; // Nombre del archivo para descargar
    downloadLink.click();
  };
  const downloadPDF = () => {
    const blob = new Blob([file], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'informe_final.pdf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Finalizar de actividad de Capacitación
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col sm={3}><Form.Label>Fecha Término</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="termino">
                      <Form.Control
                        type="date"
                        value={formik.values.termino}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled={disabledFecha}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.termino && formik.errors.termino ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.termino}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Orden de Compra/Notificación Sence</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="orden_compra">
                      <Form.Control
                        type="number"
                        value={formik.values.orden_compra}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.orden_compra &&
                    formik.errors.orden_compra ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.orden_compra}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Orden de Compra/Archivo PDF</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="orden_compra_pdf">
                      <input
                        type="file"
                        onChange={(event) => {formik.setFieldValue("orden_compra_pdf",event.target.files[0]);}}
                      />
                      {ordenCompraName && <><p>{ordenCompraName}</p> <Button variant="primary" onClick={() => ordenCompraPDF? window.open(ordenCompraPDF):null}>
                        Ver
                    </Button></>}
                     
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.orden_compra_pdf &&
                    formik.errors.orden_compra_pdf ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.orden_compra_pdf}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Informe Final/Archivo PDF</Form.Label></Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="informe_pdf">
                      <input
                        type="file"
                        onChange={(event) => {formik.setFieldValue("informe_pdf",event.target.files[0]);}}
                      />
                      {file && <button type="button" onClick={downloadPDF}>Descargar PDF</button>}
                      {informeFinalName && <><p>{informeFinalName}</p> <Button variant="primary" onClick={() => informeFinalPDF? window.open(informeFinalPDF):null}>
                        Ver
                    </Button></>}
                     
                    </Form.Group>
                     </Col>
                  
                  <Col sm={4}>
                    {formik.touched.informe_pdf &&
                    formik.errors.informe_pdf ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.informe_pdf}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Correo Electr&oacute;nico a quien notifique</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="correo">
                      <Form.Control
                        type="email"
                        value={formik.values.correo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.correo &&
                    formik.errors.correo ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.correo}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Button variant="primary" type="submit">
                  Guardar
                </Button>
              </Form>
            </Col>
          </Card.Body>
          <Card.Footer style={myFooter}>
            
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};
const myFooter = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "left",
};

export default FinalizarActividad;
