import React, { useEffect, useState } from 'react'
import { Page, Text, View, Document, StyleSheet, Image, Font} from '@react-pdf/renderer'
import AuthService from "../../api/ApiRest";
import Fondo from "../../assets/ctm_5.png"
import Logo_cfc from "../../assets/logo_cfc.jpg"
import Logo_unap from "../../assets/unap_new.jpg"
import MiFuente from '../../assets/fonts/Arimo/static/Arimo-Regular.ttf'; // Asegúrate de la ruta correcta
import MiFuenteB from '../../assets/fonts/Arimo/static/Arimo-Bold.ttf'; // Asegúrate de la ruta correcta
import firma_director from "../../assets/firma_director.png"
import firma_psicologo from "../../assets/firma_agustin_araya.png"
import logo_experiencia from "../../assets/logo_experiencia.png"

Font.register({
    family: 'Arimo',
    fonts: [
        { src: MiFuente, fontWeight: 'normal' },
        { src: MiFuenteB, fontWeight: 'bold' },
      ]
  });

const CertificadoNotasPDF2 = ({datos}) => {
    const [curso,setCurso]=useState([]);
    const [notas,setNotas]=useState([]);
    const [habilitado,setHabilitado]=useState(false);
    const [director,setDirector]=useState([]);
    const [directorS,setDirectorS]=useState([]);
    const [encargado,setEncargado]=useState([]);
    useEffect(()=>{
        
        const cursoData=async()=>{
            const response= await AuthService.getCursoAlumnoId(datos.alumno);
            setCurso(response.data.body[0])
        }
        cursoData();
        const notasData=async()=>{
            const response2= await AuthService.getNotasAlumnoCurso(datos.idAlumno,datos.curso);
            setNotas(response2.data.body)
        }
        notasData();
        const firmaData=async()=>{
            const response= await AuthService.getFirmas();
            for (let index = 0; index < response.data.body.length; index++) {
                if(response.data.body[index].habilitado==='S'){
                    setHabilitado(true)
                }else{
                    setHabilitado(false)
                }
                if(response.data.body[index].cargo==='D'){
                    setDirector(response.data.body[index])
                }
                if(response.data.body[index].cargo==='S'){
                    setDirectorS(response.data.body[index])
                }
                if(response.data.body[index].cargo==='E'){
                    setEncargado(response.data.body[index])
                }
            }
        }
        firmaData();
    },[])
    //const qrContent=JSON.stringify(curso.folio);
      /*<QRCode
        size={256}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value='holamundo'
        viewBox={`0 0 256 256`}
        /> */


    return (
        <Document>
            <Page size="LETTER" style={styles.page}>
                <View style={styles.header} fixed>
                    <Image
                        src={Fondo}
                        style={styles.fondo}
                    />
                </View>
                <View style={styles.encabezadoLogo} fixed>
                    {/* Logo izquierdo */}
                    <Image src={Logo_cfc} style={{ width: 110, height: 70 }} />

                    {/* Texto centrado
                    <Text style={styles.titulo}>C E R T I F I C A D O</Text> */}

                    {/* Logo derecho 
                    <Image src={Logo_unap} style={{ width: 120, height: 110 }} />*/}
                </View>
                <View style={styles.piePagina} fixed>
                    <Text>CFC Nº CN{curso.folio}/2024</Text>
                </View>
                <View style={styles.encabezado} fixed>
                    <Text>EX CENTRO TECNOLÓGICO MINERO</Text>
                </View>
                <View  style={styles.parrafo}>
                    <Text>EL CENTRO DE FORMACIÓN Y CAPACITACIÓN UNAP SPA. DEJA CONSTANCIA QUE:</Text>
                    <Text style={styles.nombre}>DON(A) {curso.nombre_alumno} {curso.primer_apellido} {curso.segundo_apellido}</Text>
                    <Text style={styles.nombre}>RUT {curso.run?curso.run.toLocaleString('es-ES'):null}{curso.extranjero==='N' ?'-'+curso.dv:null}</Text>
                </View>
                <View  style={styles.parrafo2}>
                    <Text>
                        PARTICIPÓ EN EL PROGRAMA DE FORMACIÓN <Text style={styles.nombreCurso}>{curso.nombre_curso}</Text> CICLO {curso.nombre_ciclo}, SEDE {curso.sede}.
                    </Text>
                </View>
                <View style={styles.table}>
                    {/*<View style={styles.tableRow}>
                        <View style={styles.tableColHeaderAsignatura}>
                            <Text style={styles.tableCellHeader}>Asignaturas</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Act.N°1</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Act.N°2</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Act.N°3</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Act.N°4</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Act.N°5</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Evaluación</Text>
                        </View>
                        <View style={styles.tableColHeaderFin}>
                            <Text style={styles.tableCellHeader}>Nota Final</Text>
                        </View>
                    </View> */}
                
                    {notas.length>0?notas.map((asignatura, index) => (

                    <View key={index} style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{index+1}</Text>
                        </View>
                        <View style={styles.tableColAsignatura}>
                            <Text style={styles.tableCellAsignatura}>{asignatura.nombre.toUpperCase()}</Text>
                        </View>
                       {/*<View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{asignatura.nota_actividad_1?asignatura.nota_actividad_1+'%':''}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{asignatura.nota_actividad_2?asignatura.nota_actividad_2+'%':''}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{asignatura.nota_actividad_3?asignatura.nota_actividad_3+'%':''}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{asignatura.nota_actividad_4?asignatura.nota_actividad_4+'%':''}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{asignatura.nota_actividad_5?asignatura.nota_actividad_5+'%':''}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{asignatura.nota_evaluacion?asignatura.nota_evaluacion+'%':''}</Text>
                        </View> */} 
                        <View style={styles.tableColFin}>
                            <Text style={styles.tableCell}>{asignatura.nota_final?asignatura.nota_final+'%':''}</Text>
                        </View>
                    </View>
                
                    )):
                    <View style={styles.tableRow}>
                        <View style={styles.tableColAsignatura}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                    </View>
                    }
                </View>
                <View><Text style={styles.promedioFinal}>Promedio De Notas: {curso.nota_final}%</Text></View>
                <View style={styles.parrafo2}><Text style={styles.nombreCurso}>EL PORCENTAJE MÍNIMO DE APROBACIÓN ES 70% (SETENTA POR CIENTO).</Text></View>
                <View  style={styles.firmas}>
                    <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 1 }}>
                        <Image src={firma_psicologo} style={{ width: 100, height: 100 }} />
                        <Text style={styles.nombreFirma}>AGUSTÍN ARAYA URRUTIA</Text>
                        <Text style={styles.cargoFirma}>JEFE DE PROYECTOS</Text>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 1 }}>
                        <Image src={firma_director} style={{ width: 100, height: 100 }} />
                        <Text style={styles.nombreFirma}>{directorS.habilitado==='S'?directorS.nombre?.toUpperCase()+' '+directorS.primer_apellido?.toUpperCase()+' '+directorS.segundo_apellido?.toUpperCase():director.nombre?.toUpperCase()+' '+director.primer_apellido?.toUpperCase()+' '+director.segundo_apellido?.toUpperCase()}</Text>
                        <Text style={styles.cargoFirma}>DIRECTOR</Text>
                    </View>
                </View>
                <View style={{ flex: 0.5, flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 1, marginTop:20 }}>
                    <Image src={logo_experiencia} style={{ width: 90, height: 90 }} />
                </View>

                <Text
                    style={styles.footer}
                    render={({ pageNumber, totalPages }) =>
                    `HOJA ${pageNumber} DE ${totalPages}`
                    }
                />
                
                
            </Page>
        </Document>
    )
}
// Create styles
const styles = StyleSheet.create({
    page: {
        paddingTop: 121,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    subtitulo:{
        paddingTop:25,
        fontFamily: 'Helvetica-Bold',
        fontSize: 20,
        textAlign: 'center',
        color:'rgb(245,145,11)'
    },
    fondo:{
        width:"620px",
        height:"795px",
    },
    titulo:{
        marginBottom: 20,
        fontFamily: 'Helvetica-Bold',
        fontSize: 24,
        textAlign: 'center',
        color:'rgb(245,145,11)'
    },
    nombre:{
        fontFamily: 'Arimo',
        fontWeight: 'bold',
        marginTop: 10,
    },
    nombreCurso:{
        fontFamily: 'Arimo',
        fontWeight: 'bold',
        marginLeft: 10,
    },
    negrita:{
        fontWeight: 'bold'
    },
    parrafo:{
        marginTop: 30,
        fontFamily: 'Arimo',
        fontSize: 12,
        textAlign: 'center'
    },
    parrafo2:{
        marginTop: 30,
        fontFamily: 'Arimo',
        fontSize: 12,
        textAlign: 'left',
        alignItems: 'center', // centra verticalmente
        flexDirection:'row',
    },
    curso:{
        margin: 20,
        fontFamily: 'Helvetica-Bold',
        fontSize: 22,
        textAlign: 'center',
        color:'rgb(245,145,11)'
    },
    firmas:{
        marginTop:20,
        flexDirection: 'row', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
    },
    promedioFinal:{
        paddingTop:20,
        fontFamily: 'Arimo',
        fontWeight: 'bold',
        fontSize: 12,
        textAlign:'right',
        marginLeft: 10,
    },
    nombreFirma:{
        fontFamily: 'Helvetica-Bold',
        //fontWeight: 'bold',
        fontSize: 13,
    },
    cargoFirma:{
        fontFamily: 'Helvetica',
        //fontWeight: 'bold',
        fontSize: 13,
    },
    firmasNombre:{
        marginTop:50,
        fontFamily: 'Helvetica',
        fontSize: 14,
        textAlign: 'center',
        flexDirection:'row',
        justifyContent:'space-between',
    },
    firmasCargo:{
        fontFamily: 'Helvetica',
        fontSize: 14,
        textAlign: 'center',
        flexDirection:'row',
        justifyContent:'space-between',
    },
    textoIzquierda:{
        textAlign:'left',
    },
    textoDerecha:{
        textAlign:'right',
    },
    header: {
        position: 'absolute',
        marginBottom: 20,
      },
      piePagina: {
        position: 'absolute',
        fontSize: 8,
        //bottom: 30,
        paddingHorizontal: 35,
        paddingTop:75,
        left: 0,
        right: 0,
        margin:20,
        textAlign: 'right',
        color: 'grey',
      },
      encabezado: {
        position: 'absolute',
        fontSize: 8,
        //bottom: 30,
        paddingHorizontal: 20,
        paddingTop:75,
        left: 0,
        right: 0,
        margin:20,
        textAlign: 'left',
        color: '#bf4e14',
      },
      encabezadoLogo: {
        position: 'absolute',
        paddingHorizontal: 20,
        paddingTop:-5,
        alignItems: 'left', 
        margin:20,
      },
    table: {
        display: 'table',
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        marginTop:20,
      },
    tableRow: {
        flexDirection: 'row'
      },
    tableColHeader: {
        width: '8%',
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderLeftWidth: 1
      },
    tableColHeaderFin: {
        width: '8%',
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth:1
      },
    tableColHeaderAsignatura: {
        width: '50%',
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderLeftWidth: 1
      },
    tableCol: {
        width: '8%',
        borderStyle: 'solid',
        borderBottomWidth: 1,
        /*borderLeftWidth: 1,*/
      },
    tableColFin: {
        width: '8%',
        borderStyle: 'solid',
        borderLeftWidth: 1,
        borderBottomWidth: 1,
        borderRightWidth:1
      },
    tableColAsignatura: {
        width: '90%',
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderLeftWidth: 1
      },
    tableCellHeader: {
        margin: 5,
        fontSize: 10,
        fontWeight: 'bold'
      },
    tableCell: {
        margin: 5,
        fontSize: 12,
        textAlign: 'center'
      },
    tableCellAsignatura: {
        margin: 5,
        fontSize: 12
      },
    footer: {
        position: 'absolute',
        bottom: 50,
        right: 30, // Colocarlo en el lado derecho
        fontSize: 12,
      },  
  });

export default CertificadoNotasPDF2
