import React, { useEffect, useState} from 'react'
import { Page, Text, View, Document, StyleSheet, Image, Font} from '@react-pdf/renderer'
import AuthService from "../../api/ApiRest";
import Fondo from "../../assets/diploma_cerrado.jpg"
import Logo_cfc from "../../assets/logo_cfc.jpg"
import Logo_unap from "../../assets/unap_new.jpg"
import firma_director from "../../assets/firma_director.png"
import firma_psicologo from "../../assets/firma_psicologo2.png"
import firma_blanco from "../../assets/firma_blanco.png"
import OpenSans from "../../assets/fonts/OpenSans-Bold.ttf"
import Montserrat from "../../assets/fonts/Montserrat-Regular.ttf"
import MontserratM from "../../assets/fonts/Montserrat-Medium.ttf"
import Cooper from "../../assets/fonts/COOPBL.TTF"
import Aileron from "../../assets/fonts/Aileron-Bold.ttf"
import {format, parseISO } from 'date-fns';
import {es} from 'date-fns/locale';
import QRCodeImage from './Qr';



const DiplomaCerrado = ({datos}) => {
    Font.register({
        family: 'OpenSans',
        src: OpenSans ,
      });
    Font.register({
        family: 'Montserrat',
        src: Montserrat ,
      });
    Font.register({
    family: 'MontserratM',
    src: MontserratM ,
    });
    Font.register({
        family: 'Cooper',
        src: Cooper ,
      });
    Font.register({
    family: 'Aileron',
    src: Aileron ,
    });
    const [participantes,setParticipantes]=useState([]);
    const [actividad,setActividad]=useState([]);
    const [director,setDirector]=useState([]);
    const [directorS,setDirectorS]=useState([]);
    const [encargado,setEncargado]=useState([]);
    useEffect(()=>{
        
        const participantesData=async()=>{
            const response= await AuthService.getParticipantes(datos.id);
            setParticipantes(response.data.body)
        }
        participantesData();
        const actividadData=async()=>{
            const response= await AuthService.getActividad(datos.id);
            setActividad(response.data.body[0])
        }
        actividadData();
        
        const firmaData=async()=>{
            const response= await AuthService.getFirmas();
            for (let index = 0; index < response.data.body.length; index++) {
                if(response.data.body[index].cargo==='D'){
                    setDirector(response.data.body[index])
                }
                if(response.data.body[index].cargo==='S'){
                    setDirectorS(response.data.body[index])
                }
                if(response.data.body[index].cargo==='E'){
                    setEncargado(response.data.body[index])
                }
            }
        }
        firmaData();
    },[])
    //const qrContent=JSON.stringify(curso.folio);
      /*<QRCode
        size={256}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value='holamundo'
        viewBox={`0 0 256 256`}
        /> */
        
    const capitalize=(str)=>{
        return str.charAt(0).toUpperCase()+str.slice(1);
    }
    function cambiarFormatoFecha(fecha,tipo){
        const fechaISO=parseISO(fecha);
        let fechaFormateada='';
        if(tipo==='dd'){
            fechaFormateada=format(fechaISO,'dd');
        }
        if(tipo==='MM'){
            fechaFormateada=capitalize(format(fechaISO,'MMMM',{locale:es}));
        }
        if(tipo==='yyyy'){
            fechaFormateada=format(fechaISO,'yyyy');
        }
        if(tipo==='dd-MM-yyyy'){
            fechaFormateada=format(fechaISO,'dd-MM-yyyy');
        }
        return fechaFormateada;
    }
    const [qrImage, setQrImage] = useState(null);

    const handleImageGenerated = (imageDataUrl) => {
        setQrImage(imageDataUrl);
    };
    const obtenerMesYAnioActual = () => {
        const fechaActual = new Date();
        const mes = fechaActual.getMonth(); // Número del mes (0 - 11)
        const anio = fechaActual.getFullYear(); // Año de cuatro dígitos
        
        // Array de nombres de meses
        const nombresMeses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
        const nombreMes = nombresMeses[mes]; // Obtener el nombre del mes
        
        return `${nombreMes} de ${anio}`; // Retornar el mes y el año formateados
    };
        
    return (
        <>
        
        <Document>
        {participantes.length>0?participantes.map(alumno=>(
            <Page  key={"participanteActividad"+alumno.id} size="LETTER" orientation="landscape" style={styles.page}>
                <View style={styles.header} fixed>
                    <Image
                        src={Fondo}
                        style={styles.fondo}
                    />
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 0 }}>
                    {/* Logo izquierdo */}
                    <Image src={Logo_cfc} style={{ width: 120, height: 80 }} />

                    {/* Texto centrado */}
                    <Text style={styles.titulo}>C E R T I F I C A D O</Text>

                    {/* Logo derecho */}
                    <Image src={Logo_unap} style={{ width: 120, height: 110 }} />
                </View>
                
                <View  style={styles.subTitulo}>
                    <Text>El Centro de Formación y Capacitación UNAP SPA (ex Centro Tecnológico Minero), confiere el presente certificado a :</Text>
                </View>
                <View  style={styles.alumno}>
                    <Text>{alumno.nombres} {alumno.primer_apellido} {alumno.segundo_apellido}</Text>
                </View>
                <View  style={styles.parrafo}>
                    <Text>Por haber participado y aprobado el : <Text style={styles.nombreCurso}>{actividad.nombre_generico}</Text>, con un total de {actividad.horas} horas cronológicas, durante los días {actividad.fecha_inicio?cambiarFormatoFecha(actividad.fecha_inicio.substring(0,10),'dd'):''} al {actividad.fecha_termino?cambiarFormatoFecha(actividad.fecha_termino.substring(0,10),'dd'):''} de {actividad.fecha_termino?cambiarFormatoFecha(actividad.fecha_termino.substring(0,10),'MM'):''} del {actividad.fecha_termino?cambiarFormatoFecha(actividad.fecha_termino.substring(0,10),'yyyy'):''}.</Text>
                </View>
                <View  style={styles.fecha}>
                    <Text>Iquique, {obtenerMesYAnioActual()}.</Text>
                </View>
                
                <View  style={styles.firmas}>
                    {/* Primera fila */}
                    <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 1 }}>
                        <Image src={firma_psicologo} style={{ width: 140, height: 100 }} />
                        <Text style={styles.nombreFirma}>{actividad.instructor_titular?.toUpperCase()}</Text>
                        <Text style={styles.cargoFirma}>Psicólogo</Text>
                        <Text style={styles.cargoFirma}>Magíster en Recursos Humanos</Text>
                    </View>
                    <View style={{ flex: 0.5, flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 1 }}>
                        <QRCodeImage datos={{url:`https://intranet.cfcunap.cl/diploma/${alumno.id}`}} />
                    </View>
                    <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 1 }}>
                        <Image src={firma_director} style={{ width: 100, height: 100 }} />
                        <Text style={styles.nombreFirma}>{directorS.habilitado==='S'?directorS.nombre?.toUpperCase()+' '+directorS.primer_apellido?.toUpperCase()+' '+directorS.segundo_apellido?.toUpperCase():director.nombre?.toUpperCase()+' '+director.primer_apellido?.toUpperCase()+' '+director.segundo_apellido?.toUpperCase()}</Text>
                        <Text style={styles.cargoFirma}>Ingeniero Comercial</Text>
                        <Text style={styles.cargoFirma}>{directorS.habilitado==='S'?'Director(S)':director.cargo==='D'?'Presidente del Directorio':'Encargado de Proyecto'}</Text>
                    </View>
                </View>
                {/*<View  style={styles.firmas} fixed>
                    <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 1 }}>
                        <QRCodeImage datos={{url:`https://intranet.cfcunap.cl/diploma/${alumno.id}`}} />
                    </View>
        </View>*/}
                <View style={styles.piePagina} fixed>
                    <Text style={styles.folio}>Folio Nº {alumno.num_folio_diploma?alumno.num_folio_diploma:''}</Text>
                </View>
            </Page>
        )):null}
        </Document>
    </>
    )
}
// Create styles
const styles = StyleSheet.create({
    page: {
        paddingBottom: 20,
        paddingHorizontal: 85,
    },
    fondo:{
        width:"950px",
        height:"650px",
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    titulo:{
        paddingTop: 50,
        paddingBottom: 40,
        fontFamily: 'OpenSans',
        fontSize: 36,
        textAlign: 'center',
        color:'#253460',
    },
    subTitulo:{
        fontFamily: 'Montserrat',
        fontSize: 16,
        letterSpacing: 2,
        textAlign: 'justify',
    },
    alumno:{
        paddingTop: 20,
        paddingBottom: 15,
        fontFamily: 'Cooper',
        fontWeight: 'bold',
        fontSize: 26,
        textAlign: 'center'
    },
    parrafo:{
        fontFamily: 'MontserratM',
        fontSize: 16,
    },
    nombreCurso:{
        fontFamily: 'Helvetica-Bold',
        fontSize: 20,
    },
    fecha:{
        paddingTop: 25,
        fontFamily: 'Montserrat',
        fontSize: 16,
        textAlign: 'justify'
    },
    firmas:{
        flexDirection: 'row', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
    },
    nombreFirma:{
        fontFamily: 'Aileron',
        fontSize: 15,
    },
    cargoFirma:{
        fontFamily: 'Montserrat',
        fontSize: 14,
    },
    header: {
        position: 'absolute',
        marginBottom: 20,
      },
    folio: {
        marginTop:35,
        fontSize: 12,
        color: 'grey',
      },
      piePagina: {
        flexDirection: 'row', 
        justifyContent: 'space-between',
        alignItems: 'center', 
        padding: 0
      },
  });

export default DiplomaCerrado
