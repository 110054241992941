import React, { useEffect, useState} from 'react'
import { Page, Text, View, Document, StyleSheet, Image, Font} from '@react-pdf/renderer'
import AuthService from "../../api/ApiRest";
//import QRCode from "react-qr-code";
//import QRCode from 'qrcode.react';
import Fondo from "../../assets/ctm_4.png"
import {format, parseISO } from 'date-fns';
import {es} from 'date-fns/locale';
import MiFuente from '../../assets/fonts/Garet-Book.ttf'; // Asegúrate de la ruta correcta
import MiFuenteB from '../../assets/fonts/Garet-Heavy.ttf'; // Asegúrate de la ruta correcta
import OSFuenteB from '../../assets/fonts/OpenSans-Bold.ttf'; // Asegúrate de la ruta correcta
import firma_director from "../../assets/firma_director.png"
import firma_psicologo from "../../assets/firma_agustin_araya.png"
import QRCodeImage from '../certificados/Qr';

import { renderToStaticMarkup } from 'react-dom/server';
import QrCodigo from './QrCodigo'

Font.register({
    family: 'GaretBook',
    fonts: [
        { src: MiFuente, fontWeight: 'normal' },
        { src: MiFuenteB, fontWeight: 'bold' },
      ]
  });
Font.register({
    family: 'OpenSans',
    fonts: [
      { src: MiFuente, fontWeight: 'normal' },
      { src: OSFuenteB, fontWeight: 'bold' },
    ]
  });
const EgresoPDF = ({datos}) => {
    const [curso,setCurso]=useState([]);
    const [habilitado,setHabilitado]=useState(false);
    const [director,setDirector]=useState([]);
    const [directorS,setDirectorS]=useState([]);
    const [encargado,setEncargado]=useState([]);


    useEffect(()=>{

        const cursoData=async()=>{
            const response= await AuthService.getCursoAlumnoId(datos);
            setCurso(response.data.body[0])
        }
        cursoData();
        const firmaData=async()=>{
            const response= await AuthService.getFirmas();
            for (let index = 0; index < response.data.body.length; index++) {
                if(response.data.body[index].habilitado==='S'){
                    setHabilitado(true)
                }else{
                    setHabilitado(false)
                }
                if(response.data.body[index].cargo==='D'){
                    setDirector(response.data.body[index])
                }
                if(response.data.body[index].cargo==='S'){
                    setDirectorS(response.data.body[index])
                }
                if(response.data.body[index].cargo==='E'){
                    setEncargado(response.data.body[index])
                }
            }
        }
        firmaData();
    },[])
    //const qrContent=JSON.stringify(curso.folio);
      /*<QRCode
        size={256}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value='holamundo'
        viewBox={`0 0 256 256`}
        /> */
        
    const capitalize=(str)=>{
        return str.charAt(0).toUpperCase()+str.slice(1);
    }
    function cambiarFormatoFecha(fecha,tipo){
        const fechaISO=parseISO(fecha);
        let fechaFormateada='';
        if(tipo==='dd'){
            fechaFormateada=format(fechaISO,'dd');
        }
        if(tipo==='MM'){
            //fechaFormateada=capitalize(format(fechaISO,'MMMM',{locale:es}));
            fechaFormateada=format(fechaISO,'MMMM',{locale:es});
        }
        if(tipo==='yyyy'){
            fechaFormateada=format(fechaISO,'yyyy');
        }
        if(tipo==='dd-MM-yyyy'){
            fechaFormateada=format(fechaISO,'dd-MM-yyyy');
        }
        return fechaFormateada;
        }
        const [qrImage, setQrImage] = useState(null);

        const handleImageGenerated = (imageDataUrl) => {
          setQrImage(imageDataUrl);
        };
        const obtenerMesYAnioActual = () => {
            const fechaActual = new Date();
            const dia = fechaActual.getDate(); // Número del día (1 - 31)
            const mes = fechaActual.getMonth(); // Número del mes (0 - 11)
            const anio = fechaActual.getFullYear(); // Año de cuatro dígitos
            
            // Array de nombres de meses
            //const nombresMeses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
            const nombresMeses = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'];
            
            const nombreMes = nombresMeses[mes]; // Obtener el nombre del mes
            
            return `${dia} DE ${nombreMes} DEL ${anio}`; // Retornar el mes y el año formateados
        };
    return (
        <Document>
            <Page size="LETTER" style={styles.page}>
                <View style={styles.header} fixed>
                    <Image
                        src={Fondo}
                        style={styles.fondo}
                    />
                </View>
                <View style={styles.piePagina} fixed>
                    <Text>CTM Nº CN{curso.folio}/2024</Text>
                </View>
                <View  style={styles.titulo}>
                    <Text>CONSTANCIA</Text>
                </View>
                <View  style={styles.parrafo}>
                    <Text>EL CENTRO TECNOLÓGICO MINERO S.A., DA CONSTANCIA QUE:</Text>
                </View>
                <View style={styles.alumno}>
                   <Text>DON(ÑA) </Text><Text style={styles.nombre}>{curso.nombre_alumno} {curso.primer_apellido} {curso.segundo_apellido} RUT {curso.run?curso.run.toLocaleString('es-ES'):null}{curso.extranjero==='N' ?'-'+curso.dv:null}</Text>
                </View>
                <View  style={styles.parrafo}>
                    <Text>FUE PARTICIPANTE REGULAR DURANTE EL CICLO {curso.nombre_ciclo} EN LA SEDE DE {curso.sede}, ESPECIALIDAD:</Text>
                </View>
                <View style={styles.curso}>
                   <Text style={styles.nombre}>{curso.nombre_curso}</Text>
                </View>
                <View  style={styles.parrafo}>
                    <Text>DURANTE EL PERÌODO COMPRENDIDO ENTRE LOS MESES DE {curso.fecha_inicio_curso?cambiarFormatoFecha(curso.fecha_inicio_curso.substring(0,10),'MM').toLocaleUpperCase():''} HASTA {curso.fecha_termino_curso?cambiarFormatoFecha(curso.fecha_termino_curso.substring(0,10),'MM').toLocaleUpperCase():''} DEL {curso.fecha_termino_curso?cambiarFormatoFecha(curso.fecha_termino_curso.substring(0,10),'yyyy'):''}, CON UNA DURACIÓN DE {curso.horas_practica} HORAS CRONOLÓGICAS, CON UNA CALIFICACIÓN DE {curso.nota_final}%, OBTENIENDO LA CALIDAD DE ALUMNO EGRESADO.</Text>
                </View>
                <View  style={styles.parrafo}>
                    <Text>SE EXTIENDE LA PRESENTE CONSTANCIA A PETICIÓN DEL INTERESADO, PARA LOS FINES QUE ESTIME CONVENIENTES.</Text>
                </View>
                <View  style={styles.firmas}>
                    <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 1 }}>
                        <Image src={firma_psicologo} style={{ width: 100, height: 100 }} />
                        <Text style={styles.nombreFirma}>AGUSTIN ARAYA URRUTIA</Text>
                        <Text style={styles.cargoFirma}>JEFE DE PROYECTOS</Text>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 1 }}>
                        <Image src={firma_director} style={{ width: 100, height: 100 }} />
                        <Text style={styles.nombreFirma}>{directorS.habilitado==='S'?directorS.nombre?.toUpperCase()+' '+directorS.primer_apellido?.toUpperCase()+' '+directorS.segundo_apellido?.toUpperCase():director.nombre?.toUpperCase()+' '+director.primer_apellido?.toUpperCase()+' '+director.segundo_apellido?.toUpperCase()}</Text>
                        <Text style={styles.cargoFirma}>DIRECTOR</Text>
                    </View>
                </View>
                <View  style={styles.fecha}>
                    <Text>IQUIQUE, {obtenerMesYAnioActual()}.</Text>
                </View>
            </Page>
        </Document>
    )
}
// Create styles
const styles = StyleSheet.create({
    page: {
        paddingTop: 150,
        paddingBottom: 65,
        paddingHorizontal: 45,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    titulo:{
        fontFamily: 'Helvetica-Bold',
        fontSize: 34,
        textAlign: 'center'
    },
    fondo:{
        width:"620px",
        height:"795px",
    },
    alumno:{
        marginTop:5,
        fontSize: 16.5,
        fontFamily: 'GaretBook',
        justifyContent: 'center', // centra horizontalmente
        alignItems: 'center', // centra verticalmente
        flexDirection:'row',
    },
    nombre:{
        fontFamily: 'Helvetica-Bold',
        //fontWeight: 'bold',
    },
    run:{
        fontSize: 18,
        textAlign: 'center',
    },
    parrafo:{
        marginTop:15,
        fontFamily: 'GaretBook',
        fontSize: 16.5,
        textAlign: 'justify'
        //textAlign: 'left'
    },
    curso:{
        margin: 20,
        fontFamily: 'Helvetica-Bold',
        //fontWeight: 'bold',
        fontSize: 16.5,
        textAlign: 'center',
    },
    firmas:{
        flexDirection: 'row', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
    },
    firmasNombre:{
        marginTop:25,
        fontFamily: 'Helvetica-Bold',
        fontSize: 14,
        textAlign: 'center',
        flexDirection:'row',
        justifyContent:'space-between',
    },
    firmasCargo:{
        fontFamily: 'Helvetica-Bold',
        fontSize: 14,
        textAlign: 'center',
        flexDirection:'row',
        justifyContent:'space-between',
    },
    textoIzquierda:{
        textAlign:'left',
    },
    textoDerecha:{
        textAlign:'right',
    },
    header: {
        position: 'absolute',
        marginBottom: 20,
      },
    piePagina: {
        position: 'absolute',
        fontSize: 12,
        //bottom: 30,
        left: 0,
        right: 0,
        margin:20,
        textAlign: 'right',
        color: 'grey',
      },
    nombreFirma:{
        fontFamily: 'Helvetica-Bold',
        //fontWeight: 'bold',
        fontSize: 13,
    },
    cargoFirma:{
        fontFamily: 'Helvetica-Bold',
        //fontWeight: 'bold',
        fontSize: 13,
    },
    fecha:{
        paddingTop: 25,
        fontFamily: 'GaretBook',
        fontSize: 10,
        textAlign: 'justify'
    },
  });

export default EgresoPDF
