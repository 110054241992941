import React, { useContext,useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import { AuthContext } from "../../auth/authContext";
import Swal from "sweetalert2";
import { Table } from "react-bootstrap";
import {format, parseISO } from 'date-fns';
import { useNavigate } from "react-router-dom";


const VerAlumnos= () => {
  const navigate = useNavigate ();
  const [id,setId]=useState('0');
  const [cursosAlumnos,setCursosAlumnos]=useState([{}]);
  const {user}=useContext(AuthContext );

  useEffect(()=>{
    const alumnosCursosData=async()=>{
      const response= await AuthService.getCursosAlumnos();
      setCursosAlumnos(response.data.body) 
    }
    alumnosCursosData();
  },[])
  const handleIngresar=async(id)=>{
    const parametros = {
        id: id
      };
    navigate("/ialumno",{state:parametros});
  }
  const [search, setSearch] = useState('');

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const filteredData = cursosAlumnos.filter((item) => {
    return (
      (item.run && (item.run+'-'+item.digito_verificador).toString().includes(search)) ||
      (item.nombres && (item.nombres+' '+item.primer_apellido+' '+item.segundo_apellido).toLowerCase().includes(search.toLowerCase()))||
      (item.nombre_curso && item.nombre_curso.toLowerCase().includes(search.toLowerCase()))||
      (item.nombre && item.nombre.toLowerCase().includes(search.toLowerCase()))
      // Agregar más condiciones para más columnas si es necesario
    );
  });
  const handleEliminar=async(id)=>{
    Swal.fire({
      title: '¿Está seguro de Eliminar?',
      text: "Recuerde que si elimina un alumno se eliminaran sus cursos y sus notas. No se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        const response=await AuthService.putAlumno({'id':id,'usuario':user.name})
        if(response.status===200){
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.body,
            showConfirmButton: false,
            timer: 1500
          })
          handleLimpiar()
        }else{
          Swal.fire('Ha ocurrido un error!',response.body,'error')
        }
        Swal.fire(
          'Eliminado!',
          'El registro fue eliminado.',
          'success'
        )
      }
    })
  }
  const handleLimpiar=async(e,values)=>{
    const response= await AuthService.getCursosAlumnos();
    setCursosAlumnos(response.data.body)
  }
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Participantes y sus Cursos
          </Card.Header>
          <Card.Body style={myBody}>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Buscar..."
                value={search}
                onChange={handleSearchChange}
              />
            </Form.Group>
            <Table striped bordered hover size="sm" variant="dark" responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Rut</th>
                  <th>Nombre Participante</th>
                  <th>Curso</th>
                  <th>Ciclo</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {filteredData?filteredData.map((elemento,index)=>(
                <tr key={"cursosalumnos"+elemento.id+elemento.id_alumno+elemento.id_curso+elemento.id_ciclo}>
                <td>{index+1}</td>
                <td>{elemento.run}-{elemento.digito_verificador}</td>
                <td>{elemento.nombres} {elemento.primer_apellido} {elemento.segundo_apellido}</td>
                <td>{elemento.nombre_curso}</td>
                <td>{elemento.nombre}</td>
                <td><Button variant="danger" onClick={()=>handleEliminar(elemento.id_alumno)}>Eliminar</Button></td>
                <td><Button variant="success" onClick={()=>handleIngresar(elemento.id)}>Ir</Button></td>
              </tr>
              )):<tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default VerAlumnos;