import React from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { Navbar } from '../ui/Navbar';
import MantenedorAlumno from '../alumnos/MantenedorAlumno'
import Asistencias from '../asistencia/Asistencias'
import MantenedorCurso from '../cursos/MantenedorCurso'
import Foliador from '../foliador/Foliador'
import LoginScreen from '../login/Login'
import Registro from '../login/Registro'
import Notas from '../notas/Notas'
import MantenedorProceso from '../procesos/MantenedorProceso'
import Procesos from '../procesos/Procesos'
import Certificados from '../certificados/Certificados'
import MantenedorActividad from '../actividades/MantenedorActividad'
import Actividades from '../actividades/Actividades'
import Actividad from '../actividades/Actividad'
import MantenedorEmpresa from '../empresas/MantenedorEmpresa'
import MantenedorImputable from '../imputables/MantenedorImputable'
import MantenedorInstructor from '../instructores/MantenedorInstructor';
import MantenedorArea from '../areas/MantenedorArea';
import FinalizarCurso from '../cursos/FinalizarCurso';
import MantenedorCiclo from '../ciclos/MantenedorCiclo';
import MantenedorSence from '../sence/MantenedorSence';
import ActividadesFinalizadas from '../actividades/ActividadesFinalizadas';
import MantenedorActividadTipo from '../actividad/MantenedorActividadTipo';
import ActividadFinalizada from '../actividades/ActividadFinalizada';
import MantenedorAsignatura from '../asignaturas/MantenedorAsignatura';
import MantenedorFirma from '../firmas/MantenedorFirma';
import CertificadoNotas from '../certificados/CertificadoNotas';
import MantenedorHorario from '../horarios/MantenedorHorario';
import MantenedorUsuario from '../usuarios/MantenedorUsuario';
import Dashboard from '../dashboard/Dashboard';
import VerAlumnos from '../alumnos/VerAlumnos';
import InformacionAlumno from '../alumnos/InformacionAlumno';
import MantenedorPermisos from '../permisos/MantenedorPermisos';
import MantenedorPerfiles from '../perfiles/MantenedorPerfiles';
import CertificadosCerrados from '../certificados/CertificadosCerrados';
import ConstanciaEgreso from '../constancias/ConstanciaEgreso';
import ConstanciaNotas from '../constancias/ConstanciaNotas';
import CertificadosEgresoExamen from '../constancias/CertificadoEgresoExamen';
import CertificadosEgresoPractica from '../constancias/CertificadoEgresoPractica';
import CertificadoNotas2 from '../certificados/CertificadoNotas2';




export const DashboardRoutes = () => {
  return (
    <>
      <Navbar/>
      <Routes>
          <Route path="/malumno" element={<MantenedorAlumno />}/>
          <Route path="/ialumno" element={<InformacionAlumno />}/>
          <Route path="/mcurso" element={<MantenedorCurso/>}/>
          <Route path="/finaliza" element={<FinalizarCurso/>}/>
          <Route path="/certificados" element={<Certificados/>}/>
          <Route path="/certificadoscerrados" element={<CertificadosCerrados/>}/>
          <Route path="/cnotas" element={<CertificadoNotas/>}/>
          <Route path="/cnotas2" element={<CertificadoNotas2/>}/>
          <Route path="/asistencia" element={<Asistencias/>}/>
          <Route path="/notas" element={<Notas/>}/>
          <Route path="/login" element={<LoginScreen/>}/>
          <Route path="/registro" element={<Registro/>}/>
          <Route path="/foliador" element={<Foliador/>}/>
          <Route path="/mproceso" element={<MantenedorProceso/>}/>
          <Route path="/procesos" element={<Procesos/>}/>
          <Route path="/mactividad" element={<MantenedorActividad/>}/>
          <Route path="/actividades" element={<Actividades/>}/>
          <Route path="/actividad" element={<Actividad/>}/>
          <Route path="/actividadesfin" element={<ActividadesFinalizadas/>}/>
          <Route path="/actividadfin/:idactividad" element={<ActividadFinalizada/>}/>
          <Route path="/mempresa" element={<MantenedorEmpresa/>}/>
          <Route path="/mimputable" element={<MantenedorImputable/>}/>
          <Route path="/minstructor" element={<MantenedorInstructor/>}/>
          <Route path="/marea" element={<MantenedorArea/>}/>
          <Route path="/mciclo" element={<MantenedorCiclo/>}/>
          <Route path="/msence" element={<MantenedorSence/>}/>
          <Route path="/mactividadtipo" element={<MantenedorActividadTipo/>}/>
          <Route path="/masignatura" element={<MantenedorAsignatura/>}/>
          <Route path="/mfirma" element={<MantenedorFirma/>}/>
          <Route path="/mhorario" element={<MantenedorHorario/>}/>
          <Route path="/musuario" element={<MantenedorUsuario/>}/>
          <Route path="/mpermiso" element={<MantenedorPermisos/>}/>
          <Route path="/mperfil" element={<MantenedorPerfiles/>}/>
          <Route path="/cursosalumnos" element={<VerAlumnos/>}/>
          <Route path="/cons.egreso" element={<ConstanciaEgreso/>}/>
          <Route path="/cons.nota" element={<ConstanciaNotas/>}/>
          <Route path="/cert.egreso.ex" element={<CertificadosEgresoExamen/>}/>
          <Route path="/cert.egreso.pra" element={<CertificadosEgresoPractica/>}/>
          <Route path="/" element={<Dashboard/>}/>
      </Routes>
    </>
  )
}
