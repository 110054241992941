import React, { useContext,useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import { AuthContext } from "../../auth/authContext";
import Swal from "sweetalert2";
import { Table } from "react-bootstrap";


const MantenedorFirma= () => {
  const [id,setId]=useState('0');
  const [firmas,setFirmas]=useState([]);
  const {user}=useContext(AuthContext );
  useEffect(()=>{
    const firmaData=async()=>{
      const response= await AuthService.getFirmas();
      setFirmas(response.data.body) 
    }
    firmaData();
  },[])
  const submitForm=async(values,actions)=>{
      const data={'id':id,
                  'nombre':values.nombre,
                  'primer_apellido':values.primerAp,
                  'segundo_apellido':values.segundoAp,
                  'cargo':values.cargo,
                  'usuario_crea':user.name,
                  'estado':'V'
      }
       
        const response=await AuthService.postFirma(data);
        if(response.status===201){
            Swal.fire(response.body,'','success')
            actions.setSubmitting(false);
            actions.resetForm({
              values: {
                nombre: "",
                primerAp: "",
                segundoAp: "",
                cargo: "",
              },
            });
            handleActualizaTabla();
        }else{
            Swal.fire('Ha ocurrido un error!',response.body,'error')
        };
        
  };
  //validaciones
  const formik = useFormik({
    initialValues: {
        nombre: "",
        primerAp: "",
        segundoAp: "",
        cargo: "",
    },
    validationSchema: Yup.object({
        nombre:  Yup.string().min(3, "Debe tener al menos 3 caracteres.").required("Es obligatorio"),
        primerAp:  Yup.string().min(3, "Debe tener al menos 3 caracteres.").required("Es obligatorio"),
        segundoAp:  Yup.string().min(3, "Debe tener al menos 3 caracteres.").required("Es obligatorio"),
        cargo:  Yup.string().required("Es obligatorio"),
    }),
    onSubmit: submitForm,
  });
  const handleEditar=async(idEdita,values)=>{
    const response=await AuthService.getFirmaId(idEdita)
      setId(idEdita);
      formik.setValues({
        ...values,
        nombre: response.data.body[0].nombre,
        primerAp: response.data.body[0].primer_apellido,
        segundoAp: response.data.body[0].segundo_apellido,
        cargo: response.data.body[0].cargo
      });
  }
  const handleEliminar=async(idElimina)=>{
    Swal.fire({
      title: '¿Está seguro de eliminar?',
      text: "No se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        const response=await AuthService.putFirma({'id':idElimina,'usuario':user.name})
        if(response.status===200){
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.body,
            showConfirmButton: false,
            timer: 1500
          })
          handleActualizaTabla();
          Swal.fire(
            'Eliminado!',
            'El registro fue eliminado.',
            'success'
          )
        }else{
          Swal.fire('Ha ocurrido un error!',response.body,'error')
        }
      }
    })
  }
  const handleActualizaTabla=async()=>{
    const response= await AuthService.getFirmas();
    setFirmas(response.data.body)
  }
  const handleHabilitar=async(idHabilita,estado)=>{
    const response= await AuthService.postFirmaHabilitar({'id':idHabilita,'estado':estado});
    handleActualizaTabla();
  }
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Mantenedor de Firmas
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="nombre">
                      <Form.Label>Nombre:</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.nombre && formik.errors.nombre ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.nombre}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="primerAp">
                      <Form.Label>Primer Apellido:</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.primerAp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.primerAp && formik.errors.primerAp ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.primerAp}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="segundoAp">
                      <Form.Label>Segundo Apellido:</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.segundoAp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.segundoAp && formik.errors.segundoAp ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.segundoAp}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="cargo">
                      <Form.Label>Cargo:</Form.Label>
                      <Form.Select
                        value={formik.values.cargo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option value="0">Seleccione</option>
                        <option value="D">Director</option>
                        <option value="S">Director(S)</option>
                        <option value="E">Encargado de Proyecto</option>
                      </Form.Select>
                    </Form.Group>
                    {formik.touched.cargo && formik.errors.cargo ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.cargo}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit">
                      Guardar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Firmas creadas
          </Card.Header>
          <Card.Body style={myBody}>
            <Table striped bordered hover size="sm" variant="dark" responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nombre</th>
                  <th>Primer Apellido</th>
                  <th>Segundo Apellido</th>
                  <th>Cargo</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {firmas?firmas.map(elemento=>(
                <tr key={"firmas"+elemento.id}>
                <td>{elemento.id}</td>
                <td>{elemento.nombre}</td>
                <td>{elemento.primer_apellido}</td>
                <td>{elemento.segundo_apellido}</td>
                <td>{elemento.cargo==='D'?'Director':elemento.cargo==='E'?'Encargado de Proyecto':'Director(S)'}</td>
                <td><Button variant="warning" onClick={()=>handleEditar(elemento.id)}>Editar</Button></td>
                <td>{elemento.cargo==='S'?elemento.habilitado==='S'?<Button variant="success" onClick={()=>handleHabilitar(elemento.id,'N')}>Deshabilitar</Button>:<Button variant="success" onClick={()=>handleHabilitar(elemento.id,'S')}>Habilitar</Button>:''}</td>
                <td><Button variant="danger" onClick={()=>handleEliminar(elemento.id)}>Eliminar</Button></td>
              </tr>
              )):<tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default MantenedorFirma;
