import React, {useContext,  useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import { AuthContext } from "../../auth/authContext";
import Swal from "sweetalert2";

const MantenedorAlumno = () => {
  const {user}=useContext(AuthContext );
  const [id,setId]=useState('0');
  const [cursos,setCursos]=useState([{}]);
  const [ciclos,setCiclos]=useState([]);
  const [nacionalidades,setNacionalidades]=useState([]);
    useEffect(()=>{
      const cursosData=async()=>{
            const response= await AuthService.getCursos();
            setCursos(response.data.body) 
        }
      cursosData();
      const nacionalidadesData=async()=>{
          const response= await AuthService.getNacionalidades();
          setNacionalidades(response.data.body) 
      }
      nacionalidadesData();
      const ciclosData=async()=>{
        const response= await AuthService.getCiclos();
        setCiclos(response.data.body) 
      }
      ciclosData();
    },[])
  //validaciones
  const submitForm=async (values,actions)=>{

    const data={'id':id,
                'run':values.run,
                'digito_verificador':values.dv,
                'ind_extranjero':values.nacionalidad==='CHL'?'N':'S',
                'iso_nac':values.nacionalidad,
                'nombres':values.nombre,
                'primer_apellido':values.primerAp,
                'segundo_apellido':values.segundoAp,
                'fecha_nacimiento':values.fecNac,
                'direccion':values.direccionParticular,
                'ciudad':values.ciudad,
                'telefono':values.numeroTelefono===""?null:values.numeroTelefono,
                'celular':values.numeroCelular,
                'email':values.correo,
                'cod_descubrecurso':values.informacion,
                'estado':'V',
                'cod_curso':values.curso,
                'id_ciclo':values.ciclo,
                'usuario_crea':user.name
              }
    const response=await AuthService.postAlumno(data);
    if(response.status===201){
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: response.body,
        showConfirmButton: false,
        timer: 1500
      })
      //actions.setSubmitting(false);
      actions.resetForm({
        values: {
          nombre: "",
          primerAp: "",
          segundoAp: "",
          run: "",
          dv: "",
          fecNac: "",
          nacionalidad: "",
          direccionParticular: "",
          ciudad:"",
          numeroTelefono: "",
          numeroCelular: "",
          correo: "",
          informacion:"",
          curso: 0,
          ciclo: 0,
        },
      });
      setId('0');
    }else{
      Swal.fire('Ha ocurrido un error!',response.body,'error')
    };
  };
  function validateRut(rut) {
    const cleanRut = rut.replace(/[.-]/g, ''); // Eliminar puntos y guiones
    const rutNumber = cleanRut.slice(0, -1);
    const verifierDigit = cleanRut.slice(-1).toUpperCase();
  
    if (!/^[0-9kK]+$/g.test(verifierDigit)) {
      return false; // El dígito verificador solo debe ser número o K
    }
  
    const reversedRutArray = [...rutNumber].reverse();
    const verifier = reversedRutArray.reduce((acc, digit, index) => {
      const multiplier = (index % 6) + 2;
      return acc + parseInt(digit, 10) * multiplier;
    }, 0);
  
    const remainder = verifier % 11;
    const calculatedVerifierDigit = remainder === 1 ? 'K' : remainder === 0 ? '0' : (11 - remainder).toString();
  
    return calculatedVerifierDigit === verifierDigit;
  }
  const handleBlurRut=async (e,values)=>{
    if(values.nacionalidad==='CHL'){
      if(validateRut(values.run+'-'+values.dv)){
        let timerInterval
        Swal.fire({
          title: 'Buscando alumno',
          html: 'Espere...<b></b>',
          timer: 500,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft()
            }, 100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          }
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
          }
        })
        const response=await AuthService.getAlumnoRun(values.run)
        if(response.data.status===200){
          setId(response.data.body[0].id);
          
          formik.setValues({
            ...values,
            nombre: response.data.body[0].nombres,
            primerAp:response.data.body[0].primer_apellido,
            segundoAp:response.data.body[0].segundo_apellido,
            fecNac: response.data.body[0].fecha_nacimiento.substring(0,10),
            nacionalidad: response.data.body[0].iso_nac,
            direccionParticular: response.data.body[0].direccion,
            ciudad:response.data.body[0].ciudad,
            numeroTelefono: response.data.body[0].telefono===null?'':values.numeroTelefono,
            numeroCelular: response.data.body[0].celular,
            correo: response.data.body[0].email,
            informacion:response.data.body[0].cod_descubrecurso,
            curso: 0,
            ciclo: 0
          });
        }else{
          setId('0');
        }
        
      }else{
        Swal.fire('Rut Incorrecto','','error')
        formik.setValues({
          ...values,
          run:'',
          dv:'',
          nombre: '',
          primerAp:'',
          segundoAp:'',
          fecNac: '',
          nacionalidad: '0',
          direccionParticular: '',
          ciudad: '',
          numeroTelefono: '',
          numeroCelular: '',
          correo: '',
          informacion: '0',
          curso: 0,
          ciclo: 0
        });
      }
    }
  }
  const handleLimpiar=(e,values)=>{
    formik.setValues({
      ...values,
      run:'',
      dv:'',
      nombre: '',
      primerAp:'',
      segundoAp:'',
      fecNac: '',
      nacionalidad: '0',
      direccionParticular: '',
      ciudad: '',
      numeroTelefono: '',
      numeroCelular: '',
      correo: '',
      informacion: '0',
      curso: 0,
      ciclo: 0
    });
    setId('0');
  }
  const formik = useFormik({
    initialValues: {
      nombre: "",
      primerAp: "",
      segundoAp: "",
      run: "",
      dv: "",
      fecNac: "",
      nacionalidad: "",
      direccionParticular: "",
      ciudad:"",
      numeroTelefono: "",
      numeroCelular: "",
      correo: "",
      informacion:"",
      curso: 0,
      ciclo: 0,
    },
    validationSchema: Yup.object({
      nombre: Yup.string()
        .min(3, "Debe tener al menos 3 caracteres.")
        .required("Es obligatorio"),
      primerAp: Yup.string()
        .min(3, "Debe tener al menos 3 caracteres.")
        .required("Es obligatorio"),
      segundoAp: Yup.string().min(3, "Debe tener al menos 3 caracteres."),
      run: Yup.number()
        .min(8, "Debe tener al menos 8 caracteres.")
        .required("Es obligatorio"),
      dv: Yup.string().max(1, "Largo 1").required("Falta DV"),
      fecNac: Yup.date().required("Es obligatorio"),
      nacionalidad:  Yup.string().required("Es obligatorio"),
      direccionParticular: Yup.string()
        .min(3, "Debe tener al menos 3 caracteres.")
        .required("Es obligatorio"),
      ciudad: Yup.string()
        .min(3, "Debe tener al menos 3 caracteres.")
        .required("Es obligatorio"),
      correo: Yup.string()
        .email()
        .min(3, "Debe tener al menos 3 caracteres.")
        .required("Es obligatorio"),
      informacion:  Yup.string().required("Es obligatorio"),
      numeroTelefono: Yup.number()
        .max(999999, "Debe tener al menos 6 caracteres."),
      numeroCelular: Yup.number()
        .max(999999999, "Debe tener al menos 9 caracteres."),
      curso:  Yup.string().required("Es obligatorio"),
      ciclo:  Yup.string().required("Es obligatorio"),
    }),
    onSubmit: submitForm,
    //validate: handleBlurRut
  });

  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Registro de Alumnos
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col sm={3}><Form.Label>Nacionalidad</Form.Label></Col>
                    <Col sm={5}>
                    <Form.Group className="mb-3" controlId="nacionalidad">
                      <Form.Select
                        value={formik.values.nacionalidad}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option value="0">Seleccione</option>
                        {nacionalidades.map(elemento=>(
                        <option key={"nacionalidades"+elemento.iso_nac} value={elemento.iso_nac}>{elemento.gentilicio_nac}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    </Col>
                    <Col sm={4}>
                    {formik.touched.nacionalidad &&
                    formik.errors.nacionalidad ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.nacionalidad}
                      </Alert>
                    ) : null}
                    </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Run - Identificador</Form.Label></Col>
                  <Col sm={4}>
                    <Form.Group className="mb-3" controlId="run">
                      <Form.Control
                        type="number"
                        value={formik.values.run}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  {formik.values.nacionalidad==='CHL' &&(
                    <Col sm={1}>
                      <Form.Group className="mb-3" controlId="dv">
                        <Form.Control
                          type="text"
                          value={formik.values.dv}
                          onChange={formik.handleChange}
                          onBlur={e=>{handleBlurRut(e,formik.values)}}
                          className="form-control"
                        />
                      </Form.Group>
                    </Col>
                  )}
                  {formik.values.nacionalidad==='CHL' &&(
                    <Col sm={4}>
                      {formik.touched.run && formik.errors.run ? (
                        <Alert className="h-50 pt-1" variant="danger">
                          Hubo un error: {formik.errors.run}
                        </Alert>
                      ) : null}
                      {formik.touched.dv && formik.errors.dv ? (
                        <Alert className="h-50 pt-1" variant="danger">
                          Hubo un error: {formik.errors.dv}
                        </Alert>
                      ) : null}
                    </Col>
                   )}
                </Row>

                <Row>
                  <Col sm={3}>
                    <Form.Label>Nombres</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="nombre">
                      <Form.Control
                        type="text"
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.nombre && formik.errors.nombre ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.nombre}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Primer Apellido</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="primerAp">
                      <Form.Control
                        type="text"
                        value={formik.values.primerAp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.primerAp && formik.errors.primerAp ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.primerAp}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Segundo Apellido</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="segundoAp">
                      <Form.Control
                        type="text"
                        value={formik.values.segundoAp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.segundoAp && formik.errors.segundoAp ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.segundoAp}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Fecha Nacimiento</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="fecNac">
                      <Form.Control
                        type="date"
                        value={formik.values.fecNac}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.fecNac && formik.errors.fecNac ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.fecNac}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Dirección</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="direccionParticular">
                      <Form.Control
                        type="text"
                        value={formik.values.direccionParticular}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.direccionParticular &&
                    formik.errors.direccionParticular ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.direccionParticular}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Ciudad</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group
                      className="mb-3"
                      controlId="ciudad"
                    >
                      <Form.Control
                        type="text"
                        value={formik.values.ciudad}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    
                  </Col>
                  <Col sm={4}>
                    {formik.touched.ciudad &&
                    formik.errors.ciudad ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.ciudad}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Teléfono(s)</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="numeroTelefono">
                      <Form.Control
                        type="number"
                        value={formik.values.numeroTelefono}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.numeroTelefono &&
                    formik.errors.numeroTelefono ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.numeroTelefono}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Celular(es)</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="numeroCelular">
                      <Form.Control
                        type="number"
                        value={formik.values.numeroCelular}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.numeroCelular &&
                    formik.errors.numeroCelular ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.numeroCelular}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Correo Electrónico</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="correo">
                      <Form.Control
                        type="email"
                        value={formik.values.correo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.correo && formik.errors.correo ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.correo}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>¿Cómo supo de CTM?</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="informacion">
                    <Form.Select
                        value={formik.values.informacion}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option value="0">Seleccione</option>
                        <option value="1">Diario</option>
                        <option value="2">Radio</option>
                        <option value="3">Familiares/Amigos</option>
                        <option value="4">Charla Colegio</option>
                        <option value="5">Volantes</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.informacion && formik.errors.informacion ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.informacion}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Curso Ingreso</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="curso">
                      <Form.Select
                        value={formik.values.curso}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option value="0">Seleccione</option>
                        {cursos.length>0?cursos.map(elemento=>(
                        <option key={"cursos"+elemento.id} value={elemento.id}>{elemento.nombre_curso}</option>
                      )):null}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.curso &&
                    formik.errors.curso ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.curso}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Ciclo de Curso</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="ciclo">
                    <Form.Select
                        value={formik.values.ciclo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option value="0">Seleccione</option>
                        {ciclos.map(elemento=>(
                        <option key={"ciclos"+elemento.id} value={elemento.id}>{elemento.nombre}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.ciclo && formik.errors.ciclo ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.ciclo}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Button variant="primary" type="submit">
                  Guardar
                </Button>
                {' '}
                <Button variant="success" onClick={handleLimpiar}>
                  Limpiar
                </Button>
              </Form>
            </Col>
          </Card.Body>
          <Card.Footer style={myFooter}>
            
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};
const myFooter = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "left",
};

export default MantenedorAlumno;
