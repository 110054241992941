import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import LoginScreen from '../login/Login'
import { DashboardRoutes } from './DashboardRoutes'
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import DiplomaPDF from '../certificados/DiplomaPDF';
import ValidaDiplomaCursoAbierto from '../certificados/ValidaDiplomaCursoAbierto';


export const AppRouter = () => {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/login" element={<PublicRoute><LoginScreen/></PublicRoute>}/>
          <Route path="/diploma/:id" element={<DiplomaPDF/>}/>
          <Route path="/diploma2/:id" element={<ValidaDiplomaCursoAbierto/>}/>
          <Route path="/certificado/:id" element={<DiplomaPDF/>}/>
          <Route path="/*" element={<PrivateRoute><DashboardRoutes/></PrivateRoute>}/>
        </Routes>
    </BrowserRouter>
  )
}
