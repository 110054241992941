import React from "react";
import Container from "react-bootstrap/Container";
import Logo from '../../assets/cfc-unap/PNG/3000px/COLOR CORPORATIVO/CFC-UNAP-imagotipo-vertical(3000px).png';


const Dashboard = () => {


  return (
    <>
    <Container style={estiloContenedor}>
        <img src={Logo} style={estiloImagen} alt="cfcunap" />
    </Container>
    </>
  );
};
const estiloContenedor = {
  display: 'flex',
  justifyContent: 'center', // Centrar horizontalmente
  alignItems: 'center',     // Centrar verticalmente
  height: '100vh',          // Ajustar altura al 100% de la ventana
};
const estiloImagen = {
  width:800,
  opacity: 0.2, // Valor entre 0 (transparente) y 1 (opaco)
};

export default Dashboard;
