import React, {useContext,  useEffect, useState } from "react";

import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import { AuthContext } from "../../auth/authContext";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

const AnularActividad = (data) => {
  const navigate = useNavigate ();
  const {user}=useContext(AuthContext);
  const [id]=useState(data.data);

  const handleEliminar=async(id)=>{
    Swal.fire({
      title: '¿Está seguro de Eliminar?',
      text: "Recuerde que si elimina una actividad se eliminaran sus alumnos. No se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        const response=await AuthService.putActividad({'id':id,'usuario':user.name})
        if(response.status===200){
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.body,
            showConfirmButton: false,
            timer: 1500
          })
          Swal.fire(
            'Eliminado!',
            'El registro fue eliminado.',
            'success'
          )
          navigate("/actividades"); 
        }else{
          Swal.fire('Ha ocurrido un error!',response.body,'error')
        }
      }
    })
  }
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Anular actividad de Capacitación
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form>
                <Button variant="danger" onClick={()=>handleEliminar(id)}>
                  Anular
                </Button>
              </Form>
            </Col>
          </Card.Body>
          <Card.Footer style={myFooter}>
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};
const myFooter = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "left",
};

export default AnularActividad;
