import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { PDFViewer } from '@react-pdf/renderer';
import LibroClasesPDF from "./LibroClasesPDF";
import { Alert } from "react-bootstrap";
import AuthService from "../../api/ApiRest";
import Swal from "sweetalert2";
import { AuthContext } from "../../auth/authContext";

const LibroClases = (data) => {
  const [id]=useState(data.data);
  const [idimputable,setIdimputable]=useState('');
  const {user}=useContext(AuthContext);
  const [verpdf,setVerpdf]=useState(false);
  useEffect(()=>{
    const actividadData=async(values)=>{
        const response= await AuthService.getActividad(data.data);
        setIdimputable(response.data.body[0].id_imputable)
        formik.setValues({
          ...values,
          direccion: response.data.body[0].direccion_ejecucion===null ? '': response.data.body[0].direccion_ejecucion,
          otic: response.data.body[0].nombre_otic===null ? '': response.data.body[0].nombre_otic,
          folio: response.data.body[0].folio_libro===null ? '': response.data.body[0].folio_libro,
        })
    }
    actividadData();
  },[])
  const submitForm=async(values,actions)=>{
    const data={'id':id,
                'id_imputable':idimputable,
                'folio_libro':values.folio,
                'nombre_otic':values.otic,
                'direccion_ejecucion':values.direccion,
                'usuario_modifica':user.name,
                'estado':'V'
              }
    const response=await AuthService.postActividad(data);
    if(response.status===201){
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: response.body,
        showConfirmButton: false,
        timer: 1500
      })
      setVerpdf(true)
    }else{
      Swal.fire('Ha ocurrido un error!',response.body,'error')
    };
  };
  //validaciones
  const formik = useFormik({
    initialValues: {
      direccion: "",
      otic: "",
      folio: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: submitForm,
  });
  
  
  const handleLimpiar=(e,values)=>{
    setVerpdf(false)
  }
  return (
    <>
      <Container>  
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Libro de Clases
          </Card.Header>
          <Card.Body style={myBody}>
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="direccion">
                    <Form.Label>Dirección de Ejecución</Form.Label>
                    <Form.Control
                      type="text"
                      value={formik.values.direccion}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="form-control"
                    />
                  </Form.Group>
                  {formik.touched.direccion && formik.errors.direccion ? (
                    <Alert variant="danger">
                      Hubo un error: {formik.errors.direccion}
                    </Alert>
                  ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="otic">
                      <Form.Label>Nombre de la OTIC</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.otic}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.otic && formik.errors.otic ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.otic}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="folio">
                      <Form.Label>Folio de Libro</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.folio}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.folio && formik.errors.folio ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.folio}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit">
                      Ver Libro de Clases
                    </Button>
                    {' '}
                    <Button variant="success" onClick={handleLimpiar}>
                      Limpiar
                    </Button>
                  </Col>
                </Row>
              { verpdf ? <PDFViewer style={{width:"100%", height:"90vh"}}>
                <LibroClasesPDF datos={id} />
              </PDFViewer>:null}
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default LibroClases