import React, { useContext,useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import { AuthContext } from "../../auth/authContext";
import Swal from "sweetalert2";
import { Table } from "react-bootstrap";

const Foliador = () => {
    
  const {user}=useContext(AuthContext );
  const [folio,setFolio]=useState([{}]);
  const [folios,setFolios]=useState([]);
  const [id,setId]=useState('0');
  const [perfil,setPerfil]=useState('');
  const [editado,setEditado]=useState(false);

    useEffect(()=>{
        const foliosData=async()=>{
            const response= await AuthService.getTiposFolios();
            setFolio(response.data.body) 
        }
        foliosData();
        const foliosDetalleData=async()=>{
          const response= await AuthService.getFoliosDetalleUsuario(user.name);
          setFolios(response.data.body) 
        }
        foliosDetalleData();

    },[])

    const submitForm=async(values)=>{
      if(id==='0'){
        const data={'tipo_folio':values.tipoFolio,
                    'detalle_documento':values.glosa,
                    'ind_fondorendir':values.fondoRendir,
                    'usuario_crea':user.name
                  }
        let selectFolio = document.getElementById("tipoFolio");
        let textoSeleccionado = selectFolio.options[selectFolio.selectedIndex].text;

        const response=await AuthService.postFolio(data);
        if(response.status===201){
            Swal.fire(response.body.data.mensaje,textoSeleccionado+' N&deg;: '+response.body.data.folio,'success')
            handleLimpiar();
        }else{
            Swal.fire('Ha ocurrido un error!',response.body,'error')
        };
      }else{
        const data = {
          'id':id,
          'detalle_documento':values.glosa,
          'ind_fondorendir':values.fondoRendir
        }
        const response=await AuthService.postFolioDetalle(data);
        if(response.status===201){
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.body,
            showConfirmButton: false,
            timer: 1500
          })
          handleLimpiar();
        }else{
            Swal.fire('Ha ocurrido un error!',response.body,'error')
        };
      }
    };
    //validaciones
    const formik = useFormik({
    initialValues: {
        tipoFolio: "",
        fondoRendir:"",
        glosa: ""
    },
    validationSchema: Yup.object({
        tipoFolio:  Yup.string().required("Es obligatorio"),
        fondoRendir:  Yup.string().required("Es obligatorio"),
        glosa: Yup.string()
            .min(3, "Debe tener al menos 3 caracteres.")
            .required("Es obligatorio"),
    }),
    onSubmit: submitForm,
  });
  const handleEditar=async(id,values)=>{
    setEditado(true)
    const response=await AuthService.getFoliosDetalleId(id)
      setId(id);
      
      formik.setFieldValue('tipoFolio', response.data.body[0].tipo_folio);
      formik.setFieldValue('fondoRendir', response.data.body[0].ind_fondorendir);
      formik.setFieldValue('glosa', response.data.body[0].detalle_documento);
      /*formik.setValues({
        ...values,
        tipoFolio: response.data.body[0].tipo_folio,
        fondoRendir: response.data.body[0].ind_fondorendir,
        glosa: response.data.body[0].detalle_documento
      });*/
  }
  const handleEliminar=async(id)=>{
    Swal.fire({
      title: '¿Está seguro de Anular?',
      text: "No se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Anular!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        const response=await AuthService.putFolioDetalle({'id':id,'usuario':user.name})
        if(response.status===200){
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.body,
            showConfirmButton: false,
            timer: 1500
          })
          handleLimpiar()
        }else{
          Swal.fire('Ha ocurrido un error!',response.body,'error')
        }
        Swal.fire(
          'Eliminado!',
          'El registro fue eliminado.',
          'success'
        )
      }
    })
  }
  const handleLimpiar=async(e,values)=>{
    const response= await AuthService.getFoliosDetalle();
    setFolios(response.data.body) 
    formik.setValues({
      ...values,
      tipoFolio: "0",
      tipoFolioBuscar:"0",
      fondoRendir: "0",
      glosa: ""
    });
    setId('0');
    setEditado(false)
    setSearch('');
  }
  const [search, setSearch] = useState('');
  const [search2, setSearch2] = useState('');
  const [search2SW, setSearch2SW] = useState(false);
  const [searchSW, setSearchSW] = useState(false);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setSearchSW(true);
    setSearch2SW(false);
    formik.setFieldValue('tipoFolioBuscar', 0);
  };
  const handleSearchChangeTipoFolio=async (e)=>{
    // Obtener el índice seleccionado
    const indiceSeleccionado = e.target.selectedIndex;
    let textoSeleccionado ='';
    
    textoSeleccionado = e.target.options[indiceSeleccionado].text;
    
    // Obtener el texto del elemento seleccionado
    setSearch2(textoSeleccionado);
    setSearchSW(false);
    setSearch2SW(true);
    setSearch('');
  }
  const filteredData = folios.filter((item) => {
    if(search2SW &&search2!=='Seleccione'){
      return (
        (item.tipo_folio && item.tipo_folio.toLowerCase()===search2.toLowerCase())
      );
    }else{
      return (
        (item.folio && item.folio.toString().includes(search)) ||
        (item.tipo_folio && item.tipo_folio.toLowerCase().includes(search.toLowerCase()))||
        (item.anio && item.anio.toString().includes(search))||
        (item.detalle && item.detalle.toLowerCase().includes(search.toLowerCase()))||
        (item.fondo_rendir && item.fondo_rendir.toLowerCase().includes(search.toLowerCase()))||
        (item.estado && item.estado.toLowerCase().includes(search.toLowerCase()))||
        (item.usuario_crea && item.usuario_crea.toLowerCase().includes(search.toLowerCase()))||
        (item.fecha_crea && item.fecha_crea.toString().includes(search))||
        (item.usuario_elimina && item.usuario_elimina.toLowerCase().includes(search.toLowerCase()))||
        (item.fecha_elimina && item.fecha_elimina.toString().includes(search))
        // Agregar más condiciones para más columnas si es necesario
      );

    }
  });
  if (search2 !== '' && filteredData.length === 0) {
    Swal.fire({
      icon: 'info',
      title: 'Sin coincidencias',
      text: 'No se encontraron elementos que coincidan con la búsqueda.',
    });
  }
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Solicitud de Folios SGC
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="tipoFolio">
                      <Form.Label>Tipo de Folio</Form.Label>
                      <Form.Select
                        value={formik.values.tipoFolio}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled={editado}
                      >
                        <option value="">Seleccione</option>
                        {folio.map(elemento=>(
                        <option key={"folio"+elemento.id} value={elemento.id}>{elemento.descripcion_folio}</option>
                      ))}
                      </Form.Select>
                    </Form.Group>
                    {formik.touched.tipoFolio && formik.errors.tipoFolio ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.tipoFolio}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="fondoRendir">
                      <Form.Label>¿Fondo a Rendir?</Form.Label>
                      <Form.Select
                        value={formik.values.fondoRendir}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option value="">Seleccione</option>
                        <option value='N'>No</option>
                        <option value='S'>Si</option>
                      </Form.Select>
                    </Form.Group>
                    {formik.touched.fondoRendir && formik.errors.fondoRendir ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.fondoRendir}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                  
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="glosa">
                      <Form.Label>Glosa</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Agregar Glosa..."
                        value={formik.values.glosa}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Form.Group>
                    {formik.touched.glosa && formik.errors.glosa ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.glosa}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit">
                      Guardar
                    </Button>{' '}
                    <Button variant="success" onClick={handleLimpiar}>
                      Limpiar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Folios generados
          </Card.Header>
          <Card.Body style={myBody}>
            <Form.Group className="mb-3" controlId="tipoFolioBuscar">
              <Form.Label>Búsqueda por Tipo de Folio</Form.Label>
              <Form.Select
                value={formik.values.tipoFolioBuscar}
                onChange={handleSearchChangeTipoFolio}
                onBlur={formik.handleBlur}
                className="form-control"
                disabled={editado}
              >
                <option value="">Seleccione</option>
                {folio.map(elemento=>(
                <option key={"folio"+elemento.id} value={elemento.id}>{elemento.descripcion_folio}</option>
              ))}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Buscar..."
                value={search}
                onChange={handleSearchChange}
              />
            </Form.Group>
            <Table striped bordered hover size="sm" variant="dark">
              <thead>
                <tr>
                  <th>Folio</th>
                  <th>Tipo Folio</th>
                  <th>Año</th>
                  <th>Detalle</th>
                  <th>Fondo a rendir</th>
                  <th>Anulado</th>
                  <th>Usuario Crea</th>
                  <th>Fecha Crea</th>
                  <th>Usuario Anula</th>
                  <th>Fecha Anulación</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {filteredData.map(elemento=>(
                <tr key={"folios"+elemento.id}>
                <td>{elemento.folio}</td>
                <td>{elemento.tipo_folio}</td>
                <td>{elemento.anio}</td>
                <td>{elemento.detalle}</td>
                <td>{elemento.fondo_rendir}</td>
                <td>{elemento.estado==='Anulado'? 'Si':'No'}</td>
                <td>{elemento.usuario_crea}</td>
                <td>{elemento.fecha_crea}</td>
                <td>{elemento.usuario_elimina}</td>
                <td>{elemento.fecha_elimina}</td>
                <td>{elemento.estado==='Anulado'? null:<Button variant="warning" onClick={()=>handleEditar(elemento.id)}>Editar</Button>}</td>
                <td>{elemento.estado==='Anulado'? null:<Button variant="danger" onClick={()=>handleEliminar(elemento.id)}>Anular</Button>}</td>
              </tr>
                        ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default Foliador;
