import React, {useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import Swal from "sweetalert2";
import { Table } from "react-bootstrap";
import { AuthContext } from "../../auth/authContext";

const MantenedorProceso = () => {
  const FILE_SIZE = 2048 * 1024;
  const SUPPORTED_FORMATS = [
    "application/pdf"
  ]; 

    const {user}=useContext(AuthContext );
    const [proceso,setProceso]=useState([{}]);
    const [procesos,setProcesos]=useState([{}]);
    const [id,setId]=useState('0');
    const [editado,setEditado]=useState(false);
    const [disabledArchivo, setDisabledArchivo]=useState(false);

    useEffect(()=>{
        const procesosData=async()=>{
            const response= await AuthService.getProcesos();
            setProceso(response.data.body) 
            setProcesos(response.data.body) 
        }
        procesosData();
    },[])
    const submitForm=async(values)=>{
        const formData=new FormData();
        
          formData.append('id',id)
          formData.append('nombre_proceso',values.nombreProceso)
          formData.append('tipo_proceso',values.tipoProceso)
          formData.append('archivo_binario',values.archivo)
          formData.append('id_padre',values.procesoPadre===''?'':values.procesoPadre)
          formData.append('estado','V')
          formData.append('usuario_crea',user.name)
        
          const response=await AuthService.postProceso(formData);
          if(response.status===201){
              Swal.fire(response.body,'','success')
              handleLimpiar()
          }else{
              Swal.fire('Ha ocurrido un error!',response.body,'error')
          };
  
    };
    //validaciones
    const formik = useFormik({
    initialValues: {
        procesoPadre: "",
        tipoProceso:"",
        nombreProceso: "",
        archivo:"",
    },
    validationSchema: Yup.object({
        procesoPadre:  Yup.string(),
        tipoProceso:  Yup.string().required("Es obligatorio"),
        nombreProceso: Yup.string()
            .min(3, "Debe tener al menos 3 caracteres.")
            .required("Es obligatorio"),
        archivo: Yup.mixed().when('disabledArchivo', {
          is: false,
          then: Yup.mixed().test(
            "FILE_SIZE",
            "Muy Grande!, debe tener menos de 2MB",
            (value) => value && value.size <= FILE_SIZE
            )
          .test(
            "FILE_TYPE",
            "Formato incorrecto!, debe ser PDF.",
            (value) => value && SUPPORTED_FORMATS.includes(value.type)
            ),
        }),
                 
    }),
    onSubmit: submitForm,
  });
  const [search, setSearch] = useState('');

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const filteredData = procesos.filter((item) => {
    return (
      (item.id && item.id.toString().includes(search)) ||
      (item.nombre_proceso && item.nombre_proceso.toLowerCase().includes(search.toLowerCase()))||
      (item.tipo_proceso && item.elemento.tipo_proceso==='D'?'Documento':'Carpeta'.toLowerCase().includes(search.toLowerCase()))||
      (item.id_padre && item.id_padre.toString().includes(search))
      // Agregar más condiciones para más columnas si es necesario
    );
  });
  const handleEditar=async(id,values)=>{
    setEditado(true)
    const response=await AuthService.getProcesoId(id)
      setId(id);
      
      formik.setFieldValue('procesoPadre', response.data.body[0].id_padre===null?'':response.data.body[0].id_padre);
      formik.setFieldValue('tipoProceso', response.data.body[0].tipo_proceso);
      formik.setFieldValue('nombreProceso', response.data.body[0].nombre_proceso);
      formik.setFieldValue('archivo', response.data.body[0].archivo_binario);
  }
  const handleEliminar=async(id)=>{
    Swal.fire({
      title: '¿Está seguro de Eliminar?',
      text: "Recuerde que si elimina un proceso padre los hijos se eliminarán tambien. No se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        const response=await AuthService.putProceso({'id':id,'usuario':user.name})
        if(response.status===200){
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.body,
            showConfirmButton: false,
            timer: 1500
          })
          handleLimpiar()
        }else{
          Swal.fire('Ha ocurrido un error!',response.body,'error')
        }
        Swal.fire(
          'Eliminado!',
          'El registro fue eliminado.',
          'success'
        )
      }
    })
  }
  const handleLimpiar=async(e,values)=>{
    const response= await AuthService.getProcesos();
    setProceso(response.data.body) 
    setProcesos(response.data.body) 
    formik.setValues({
      ...values,
      procesoPadre: "",
      tipoProceso: "",
      nombreProceso: "",
      archivo: ""
    });
    setId('0');
    setEditado(false)
    setDisabledArchivo(false)
  }
  const handleChangeTipoProceso=async(e,values)=>{
    formik.setFieldValue('tipoProceso', e.target.value);
    if(e.target.value==='C'){
      setDisabledArchivo(true)
    }else{
      setDisabledArchivo(false)
    }
  }
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Mantenedor de Procesos
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="procesoPadre">
                      <Form.Label>Seleccione proceso padre:</Form.Label>
                      <Form.Select
                        value={formik.values.procesoPadre}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option value="">Seleccione</option>
                        {proceso.map(elemento=>(
                        <option key={"proceso"+elemento.id} value={elemento.id}>{elemento.nombre_proceso}</option>
                      ))}
                      </Form.Select>
                    </Form.Group>
                    {formik.touched.procesoPadre && formik.errors.procesoPadre ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.procesoPadre}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="tipoProceso">
                      <Form.Label>Tipo de Proceso</Form.Label>
                      <Form.Select
                        value={formik.values.tipoProceso}
                        onChange={handleChangeTipoProceso}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option value="">Seleccione</option>
                        <option value='C'>Carpeta</option>
                        <option value='D'>Documento</option>
                      </Form.Select>
                    </Form.Group>
                    {formik.touched.tipoProceso && formik.errors.tipoProceso ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.tipoProceso}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="nombreProceso">
                      <Form.Label>Nombre del nuevo proceso:</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.nombreProceso}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.nombreProceso && formik.errors.nombreProceso ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.nombreProceso}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="archivo">
                      <Form.Label>Archivo:</Form.Label>
                      <input
                        type="file"
                        onChange={(event) => {formik.setFieldValue("archivo",event.target.files[0]);}}
                        disabled={disabledArchivo}
                      />
                    </Form.Group>
                    {formik.touched.archivo && formik.errors.archivo ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.archivo}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit">
                      Guardar
                    </Button>{' '}
                    <Button variant="success" onClick={handleLimpiar}>
                      Limpiar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Procesos generados
          </Card.Header>
          <Card.Body style={myBody}>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Buscar..."
                value={search}
                onChange={handleSearchChange}
              />
            </Form.Group>
            <Table striped bordered hover size="sm" variant="dark">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Nombre Proceso</th>
                  <th>Tipo Proceso</th>
                  <th>Id Padre</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {filteredData.map(elemento=>(
                <tr key={"procesos"+elemento.id}>
                <td>{elemento.id}</td>
                <td>{elemento.nombre_proceso}</td>
                <td>{elemento.tipo_proceso==='D'?'Documento':'Carpeta'}</td>
                <td>{elemento.id_padre}</td>
                <td><Button variant="warning" onClick={()=>handleEditar(elemento.id)}>Editar</Button></td>
                <td><Button variant="danger" onClick={()=>handleEliminar(elemento.id)}>Eliminar</Button></td>
              </tr>
                        ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default MantenedorProceso;
