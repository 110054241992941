import React, {useContext,  useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import { AuthContext } from "../../auth/authContext";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";

const InformacionAlumno = () => {
  const { state } = useLocation();

  const {user}=useContext(AuthContext );
  const [id,setId]=useState(state.id);
  const [notas,setNotas]=useState([]);

  useEffect(()=>{
    const cursoData=async()=>{
      if(state){
        const response= await AuthService.getCursoAlumnoId(state.id);
        formik.setFieldValue('nombre', response.data.body[0].nombre_alumno+' '+response.data.body[0].primer_apellido+' '+response.data.body[0].segundo_apellido);
        formik.setFieldValue('run', response.data.body[0].run+'-'+response.data.body[0].dv);
        formik.setFieldValue('fecNac', response.data.body[0].fecha_nacimiento);
        formik.setFieldValue('curso', response.data.body[0].nombre_curso);
        formik.setFieldValue('ciclo', response.data.body[0].nombre_ciclo);
        formik.setFieldValue('sede', response.data.body[0].sede);
        formik.setFieldValue('notaFinal', response.data.body[0].nota_final?response.data.body[0].nota_final:'');
        formik.setFieldValue('notaTerreno', '');
        formik.setFieldValue('notaInforme', '');
        formik.setFieldValue('practica', response.data.body[0].empresa_practica?response.data.body[0].empresa_practica:'');
        formik.setFieldValue('direccion', response.data.body[0].direccion?response.data.body[0].direccion:'');
        formik.setFieldValue('fono', response.data.body[0].telefono?response.data.body[0].telefono:'');
        formik.setFieldValue('celular', response.data.body[0].celular?response.data.body[0].celular:'');
        formik.setFieldValue('email', response.data.body[0].email?response.data.body[0].email:'');
        const response2= await AuthService.getNotasAlumnoCurso(response.data.body[0].id_alumno,state.id);
        setNotas(response2.data.body)
      }
      
    }
    cursoData();
  },[])

  //validaciones
  const submitForm=async (values,actions)=>{

    const data={'id':id,
                'run':values.run,
                'digito_verificador':values.dv,
                'ind_extranjero':values.nacionalidad==='CHL'?'N':'S',
                'iso_nac':values.nacionalidad,
                'nombres':values.nombre,
                'primer_apellido':values.primerAp,
                'segundo_apellido':values.segundoAp,
                'fecha_nacimiento':values.fecNac,
                'direccion':values.direccionParticular,
                'ciudad':values.ciudad,
                'telefono':values.numeroTelefono===""?null:values.numeroTelefono,
                'celular':values.numeroCelular,
                'email':values.correo,
                'cod_descubrecurso':values.informacion,
                'estado':'V',
                'cod_curso':values.curso,
                'id_ciclo':values.ciclo,
                'usuario_crea':user.name
              }
    const response=await AuthService.postAlumno(data);
    if(response.status===201){
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: response.body,
        showConfirmButton: false,
        timer: 1500
      })
      //actions.setSubmitting(false);
      actions.resetForm({
        values: {
          nombre: "",
          primerAp: "",
          segundoAp: "",
          run: "",
          dv: "",
          fecNac: "",
          nacionalidad: "",
          direccionParticular: "",
          ciudad:"",
          numeroTelefono: "",
          numeroCelular: "",
          correo: "",
          informacion:"",
          curso: 0,
          ciclo: 0,
        },
      });
      setId('0');
    }else{
      Swal.fire('Ha ocurrido un error!',response.body,'error')
    };
  };

  const formik = useFormik({
    initialValues: {
      nombre: "",
      run: "",
      fecNac: "",
      curso: "",
      ciclo: "",
      sede:"",
      notaFinal: "",
      notaTerreno: "",
      notaInforme: "",
      practica:"",
      direccion: "",
      fono: "",
      celular: "",
      email: ""
    },
    onSubmit: submitForm,
  });

  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Información del Alumno
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col sm={3}><Form.Label>Nombre:</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="nombre">
                      <Form.Control
                        type="text"
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Run:</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="run">
                      <Form.Control
                        type="text"
                        value={formik.values.run}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Fecha Nacimiento:</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="fecNac">
                      <Form.Control
                        type="text"
                        value={formik.values.fecNac}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Especialidad:</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="curso">
                      <Form.Control
                        type="text"
                        value={formik.values.curso}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Ciclo:</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="ciclo">
                      <Form.Control
                        type="text"
                        value={formik.values.ciclo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Sede:</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="sede">
                      <Form.Control
                        type="text"
                        value={formik.values.sede}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Nota Final Asignaturas:</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="notaFinal">
                      <Form.Control
                        type="text"
                        value={formik.values.notaFinal}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Nota Terreno:</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="notaTerreno">
                      <Form.Control
                        type="text"
                        value={formik.values.notaTerreno}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Nota Informe Práctica:</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="notaInforme">
                      <Form.Control
                        type="text"
                        value={formik.values.notaInforme}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Lugar Práctica:</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="practica">
                      <Form.Control
                        type="text"
                        value={formik.values.practica}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Dirección:</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="direccion">
                      <Form.Control
                        type="text"
                        value={formik.values.direccion}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Fono:</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="fono">
                      <Form.Control
                        type="text"
                        value={formik.values.fono}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Celular:</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="celular">
                      <Form.Control
                        type="text"
                        value={formik.values.celular}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Email:</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="email">
                      <Form.Control
                        type="text"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
            <table style={tableStyle}>
              <thead>
                  <tr>
                    <th style={centerStyle}></th>
                    <th style={leftAlignStyle}>
                      Asignaturas
                    </th>
                    <th style={centerStyle}>
                      Nota Final
                    </th>
                  </tr>
              </thead>
              <tbody>
              {notas.length>0?notas.map((asignatura, index) => (
                  <tr key={index}>
                      <td style={centerStyle}>{index+1}</td>
                      <td style={leftAlignStyle}>{asignatura.nombre}</td>
                      <td style={centerStyle}>{asignatura.nota_final?asignatura.nota_final+'%':''}</td>
                  </tr>
              )):
                  <tr>
                      <td style={centerStyle}></td>
                      <td style={centerStyle}></td>
                      <td style={centerStyle}></td>
                  </tr>
              }
                  <tr>
                      <td style={centerStyle}></td>
                      <td style={redTextStyle}>Promedio de Notas</td>
                      <td style={redTextStyle}>{formik.values.notaFinal}</td>
                  </tr>
              </tbody>
          </table>
          </Card.Body>
          <Card.Footer style={myFooter}>
            
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
};

const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};
const myFooter = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "left",
};
// Estilos en línea para la tabla y las celdas
const tableStyle = {
  textAlign: 'center',
  margin: '0 auto',
  width: '80%', // Ajusta el ancho de la tabla según sea necesario
};
const cellStyle = {
  border: '1px solid #ddd', // Agrega bordes a las celdas
  padding: '8px',
};
const redTextStyle = {
  ...cellStyle, // Copia los estilos de celda
  color: 'red', // Texto en color rojo
};
const leftAlignStyle = {
  ...cellStyle, // Copia los estilos de celda
  textAlign: 'left',
};
const rightAlignStyle = {
  ...cellStyle, // Copia los estilos de celda
  textAlign: 'right',
};
const centerStyle = {
  ...cellStyle, // Copia los estilos de celda
  textAlign: 'center',
};

export default InformacionAlumno;
