import React, {  useEffect, useState } from "react";
import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import { useParams } from "react-router-dom";
import { saveAs } from 'file-saver';

const ActividadFinalizada = () => {
  const [id]=useState(useParams().idactividad);
  const [informeFinal,setInformeFinal]=useState(null);
  const [ordenCompra,setOrdenCompra]=useState(null);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [ordenCompraPDF,setOrdenCompraPDF]=useState('');
  const [informeFinalPDF,setInformeFinalPDF]=useState('');
  const [ordenCompraName,setOrdenCompraName]=useState('');
  const [informeFinalName,setInformeFinalName]=useState('');

  useEffect(()=>{
      const actividadData=async(values)=>{
          const response= await AuthService.getActividad(id);
          setOrdenCompraPDF(response.data.body[0].ruta_archivo_orden_compra)
          setInformeFinalPDF(response.data.body[0].ruta_archivo_informe_final)
          setOrdenCompraName(response.data.body[0].nombre_archivo_orden_compra)
          setInformeFinalName(response.data.body[0].nombre_archivo_informe_final)

          setInformeFinal(response.data.body[0].informe_final_pdf);
          setOrdenCompra(response.data.body[0].orden_compra_pdf);
          formik.setValues({
            ...values,
            termino: response.data.body[0].fecha_termino===null ? "": response.data.body[0].fecha_termino.substring(0,10),
            orden_compra:response.data.body[0].orden_compra===null ? "":response.data.body[0].orden_compra,
            correo:response.data.body[0].correo_notifica_finaliza===null ? "":response.data.body[0].correo_notifica_finaliza,
            orden_compra_pdf: "",
            informe_pdf: "",
          })
          
      }
      actividadData();
  },[])
  
  //validaciones
  const submitForm=async (values,actions)=>{
    
  };
  const formik = useFormik({
    initialValues: {
      termino:"",
      orden_compra: "",
      correo:"",
      orden_compra_pdf: "",
      informe_pdf: "",
    },
    /*validationSchema: Yup.object({
      termino:  Yup.date().required("Es obligatorio"),
      orden_compra: Yup.number().required("Es obligatorio"),
      correo: Yup.string()
      .email()
      .min(3, "Debe tener al menos 3 caracteres.")
      .required("Es obligatorio"),
      orden_compra_pdf: Yup.mixed().required("Requerido")
      .test(
       "FILE_SIZE",
       "Muy Grande!, debe tener menos de 2MB",
       (value) => value && value.size <= FILE_SIZE
       )
     .test(
       "FILE_TYPE",
       "Formato incorrecto!, debe ser PDF.",
       (value) => value && SUPPORTED_FORMATS.includes(value.type)
       ),
      informe_pdf: Yup.mixed().required("Requerido")
      .test(
       "FILE_SIZE",
       "Muy Grande!, debe tener menos de 2MB",
       (value) => value && value.size <= FILE_SIZE
       )
     .test(
       "FILE_TYPE",
       "Formato incorrecto!, debe ser PDF.",
       (value) => value && SUPPORTED_FORMATS.includes(value.type)
       ),
    }),*/
    onSubmit: submitForm,
    //validate: handleBlurRut
  });
const handleInforme=()=>{
  if (informeFinal) {
    const blob = new Blob([informeFinal], { type: 'application/pdf' });
    saveAs(blob, 'informe_final.pdf');
  }
}
const handleOrden=()=>{
    const pdfData=new Blob([ordenCompra],{type:'application/pdf'});
    const url=URL.createObjectURL(pdfData);
    const link=document.createElement('a');
    link.href=url;
    link.download=`OrdenCompraActividad_${id}.pdf`;
    link.click();
    URL.revokeObjectURL(url);
}
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Actividad de Capacitación Finalizada
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col sm={3}><Form.Label>Fecha Término</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="termino">
                      <Form.Control
                        type="date"
                        value={formik.values.termino}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.termino && formik.errors.termino ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.termino}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Orden de Compra/Notificación Sence</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="orden_compra">
                      <Form.Control
                        type="number"
                        value={formik.values.orden_compra}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.orden_compra &&
                    formik.errors.orden_compra ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.orden_compra}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Orden de Compra/Archivo PDF</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="orden_compra_pdf">
                    
                    </Form.Group>
                    {ordenCompraName && <><p>{ordenCompraName}</p> <Button variant="primary" onClick={() => ordenCompraPDF? window.open(ordenCompraPDF):null}>
                        Ver
                    </Button></>}
                  </Col>
                  <Col sm={4}>
                    {formik.touched.orden_compra_pdf &&
                    formik.errors.orden_compra_pdf ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.orden_compra_pdf}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Informe Final/Archivo PDF</Form.Label></Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="informe_pdf">
                    </Form.Group>
                      {informeFinalName && <><p>{informeFinalName}</p> <Button variant="primary" onClick={() => informeFinalPDF? window.open(informeFinalPDF):null}>
                        Ver
                    </Button></>}
              
                  </Col>
                  <Col sm={4}>
                    {formik.touched.informe_pdf &&
                    formik.errors.informe_pdf ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.informe_pdf}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Correo Electr&oacute;nico a quien notifique</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="correo">
                      <Form.Control
                        type="email"
                        value={formik.values.correo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.correo &&
                    formik.errors.correo ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.correo}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card.Body>
          <Card.Footer style={myFooter}>
            
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};
const myFooter = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "left",
};

export default ActividadFinalizada;