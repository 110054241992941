import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import {BsFilePdf} from "react-icons/bs";
import { FaRegFolderOpen } from "react-icons/fa";



const Procesos = () => {

function buildTree(nodes,parent=null){
  const children=nodes
  .filter(node=>node.id_padre===parent)
  .map(node=>({...node,children:buildTree(nodes,node.id)}));
  return children.length>0?children:null;
}


    const [proceso,setProceso]=useState([{}]);
    useEffect(()=>{
        const procesosData=async()=>{
            const response= await AuthService.getProcesos();
            const tree=buildTree(response.data.body);
            setProceso(tree);
        }
        procesosData();
    },[])
    const TreeNode=({node})=>{
      return(
        <div>
          <p>{node.nombre_proceso} {node.tipo_proceso==='D'? <BsFilePdf onClick={() => node.ruta_archivo? window.open(node.ruta_archivo):null}/>:<FaRegFolderOpen/>}</p>
          {node.children && node.children.length>0 && (
            <ul>
              {node.children.map((child)=>(
                <li key={child.id}>
                  <TreeNode node={child}/>
                </li>
              ))}
            </ul>
          )}
        </div>
      );
    };
    //validaciones


  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Procesos SGC
          </Card.Header>
          <Card.Body style={myBody}>
           {proceso && proceso.length>0 ?(
            <ul>
              {proceso.map((node)=>(
                <li key={'proceso'+node.id}>
                  <TreeNode node={node}/>
                </li>
              ))}
            </ul>
           ):(<p>No se encontraron datos</p>
           )}
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};


export default Procesos;
