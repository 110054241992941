import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import Swal from "sweetalert2";
import { PDFViewer } from '@react-pdf/renderer';
import CertificadoPDF from "./EgresoPDF";

const ConstanciaEgreso = () => {

  const [cursos,setCursos]=useState([]);
  const [id,setId]=useState('0');
  const [verpdf,setVerpdf]=useState(false);
/*
  useEffect(()=>{
    const cursosData=async()=>{
        //const response= await AuthService.getCursos();
        //setCursos(response.data.body) 
    }
    cursosData();
  },[])*/
  const handleBlurRut=async (e,values)=>{
    if(validateRut(values.run+'-'+values.dv)){
      let timerInterval
      Swal.fire({
        title: 'Buscando alumno',
        html: 'Espere...<b></b>',
        timer: 500,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
          const b = Swal.getHtmlContainer().querySelector('b')
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft()
          }, 100)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
        }
      })
      const response=await AuthService.getAlumnoRun(values.run)
      const responseCursos=await AuthService.getCursoAlumno(response.data.body[0].id)
      //setId(responseCursos.data.body[0].id);
      setCursos(responseCursos.data.body)
      
      formik.setValues({
        ...values,
        nombre: response.data.body[0].nombres,
        primerAp: response.data.body[0].primer_apellido,
        segundoAp: response.data.body[0].segundo_apellido,
      });
      
    }else{
      Swal.fire('Rut Incorrecto','','error')
      formik.setValues({
        ...values,
        nombre: "",
        primerAp: "",
        segundoAp: "",
        run: "",
        dv: "",
        curso: "",
      });
    }
  }
  function validateRut(rut) {
    const cleanRut = rut.replace(/[.-]/g, ''); // Eliminar puntos y guiones
    const rutNumber = cleanRut.slice(0, -1);
    const verifierDigit = cleanRut.slice(-1).toUpperCase();
  
    if (!/^[0-9kK]+$/g.test(verifierDigit)) {
      return false; // El dígito verificador solo debe ser número o K
    }
  
    const reversedRutArray = [...rutNumber].reverse();
    const verifier = reversedRutArray.reduce((acc, digit, index) => {
      const multiplier = (index % 6) + 2;
      return acc + parseInt(digit, 10) * multiplier;
    }, 0);
  
    const remainder = verifier % 11;
    const calculatedVerifierDigit = remainder === 1 ? 'K' : remainder === 0 ? '0' : (11 - remainder).toString();
  
    return calculatedVerifierDigit === verifierDigit;
  }

 const submitForm=async(values,actions)=>{
  //console.log(values.curso);
  //handleLimpiar();
  setId(values.curso)
  setVerpdf(true)
};
  //validaciones
  const formik = useFormik({
    initialValues: {
      nombre: "",
      primerAp: "",
      segundoAp: "",
      run: "",
      dv: "",
      curso: "",
    },
    validationSchema: Yup.object({
      nombre: Yup.string()
        .min(3, "Debe tener al menos 3 caracteres.")
        .required("Es obligatorio"),
      primerAp: Yup.string()
        .min(3, "Debe tener al menos 3 caracteres.")
        .required("Es obligatorio"),
      segundoAp: Yup.string().min(3, "Debe tener al menos 3 caracteres."),
      run: Yup.number()
        .max(99999999, "Debe tener al menos 8 caracteres.")
        .required("Es obligatorio"),
      dv: Yup.string().max(1, "Largo 1").required("Falta"),
      curso: Yup.string().required("Es obligatorio"),
    }),
    onSubmit: submitForm,
  });
  
  
  const handleLimpiar=(e,values)=>{
    formik.setValues({
      ...values,
      nombre: "",
      primerAp: "",
      segundoAp: "",
      run: "",
      dv: "",
      curso: "",
    });
    setCursos([]);
    setVerpdf(false)
  }
  const handleChangeCurso=async(e,values)=>{
    formik.setFieldValue('curso', e.target.value);
    if(e.target.value!=='0'){
      const responseCurso=await AuthService.getCursoAlumnoId(e.target.value)
      if(responseCurso.data.body[0].finalizado==='N'){
        Swal.fire('Curso no Finalizado','','error')
        handleLimpiar()
      }
    }
  }
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Constancia de Egreso
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="run">
                      <Form.Label>Run de Alumno</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.run}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.run && formik.errors.run ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.run}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col sm={1}>
                    <Form.Group className="mb-3" controlId="dv">
                      <Form.Label>DV</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.dv}
                        onChange={formik.handleChange}
                        onBlur={e=>{handleBlurRut(e,formik.values)}}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.dv && formik.errors.dv ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.dv}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="nombre">
                      <Form.Label>Nombre</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                    {formik.touched.nombre && formik.errors.nombre ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.nombre}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="primerAp">
                      <Form.Label>Primer Apellido</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.primerAp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                    {formik.touched.primerAp && formik.errors.primerAp ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.primerAp}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="segundoAp">
                      <Form.Label>Segundo Apellido</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.segundoAp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                    {formik.touched.segundoAp && formik.errors.segundoAp ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.segundoAp}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="curso">
                      <Form.Label>Curso</Form.Label>
                      <Form.Select
                        value={formik.values.curso}
                        onChange={handleChangeCurso}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option value='0'>Seleccione...</option>
                        {cursos.length>0?cursos.map(elemento=>(
                        <option key={"cursosAlumno"+elemento.id} value={elemento.id}>{elemento.nombre_ciclo} {elemento.nombre_curso}</option>
                      )):''}
                      </Form.Select>
                    </Form.Group>
                    {formik.touched.curso && formik.errors.curso ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.curso}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit">
                      Ver Certificado
                    </Button>
                    {' '}
                    <Button variant="success" onClick={handleLimpiar}>
                      Limpiar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card.Body>
        </Card>
        {cursos ? 
        <>{ verpdf ? <Card>
          <Card.Header as="h3" style={myHeader}>
            Impresión de Certificado
          </Card.Header>
          <Card.Body style={myBody}>
            <PDFViewer style={{width:"100%", height:"90vh"}}>
              <CertificadoPDF datos={id} />
            </PDFViewer>
          </Card.Body>
        </Card>:null}
        </>
        : null}
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default ConstanciaEgreso