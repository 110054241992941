import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
//import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

function Registro() {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  //const { signup } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState();

  const handleChange = ({ target: { id, value } }) =>
    setUser({ ...user, [id]: value });
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('')
    try {
      //await signup(user.email, user.password);
      navigate("/");
    } catch (error) {
      setError(error.message);
    }
  };
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Crear usuarios del sistema
          </Card.Header>
          <Card.Body style={myBody}>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Correo Electrónico</Form.Label>
                    <Form.Control
                      type="email"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="password">
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control
                      type="password"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {error && (
                  <Alert variant="danger">
                    Hubo un error:<p>{error}</p>
                  </Alert>
                )}
              </Row>
              <Row>
                <Col>
                  <Button variant="primary" type="submit">
                    Registrar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default Registro;
