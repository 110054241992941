import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ActividadesFinalizadas = () => {
  const navigate = useNavigate ();
  const [actividad,setActividad]=useState([{}]);
    useEffect(()=>{
        const actividadesData=async()=>{
            const response= await AuthService.getActividades();
            setActividad(response.data.body) 
        }
        actividadesData();
    },[])
  const handleIngresar=async(actividad)=>{
    
    navigate(`/actividadfin/${actividad}`,{state:actividad});
  }
  
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Actividades Finalizadas
          </Card.Header>
          <Card.Body style={myBody}>
            <Table striped bordered hover size="sm" variant="dark" className="text-center" responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Actividad</th>
                  <th>Admin</th>
                  <th>CR</th>
                  <th>Empresa</th>
                  <th>Término</th>
                  <th>Ingresar</th>
                </tr>
              </thead>
              <tbody>
              {actividad.map(elemento=>(
                elemento.estado_actividad!==null?
                <tr key={"actividad"+elemento.id_actividad}>
                <td>{elemento.id_actividad}</td>
                <td>{elemento.nombre_generico}</td>
                <td>{elemento.administrador_negocio}</td>
                <td>{elemento.centro_resultado}</td>
                <td>{elemento.nombre_empresa}</td>
                <td>{elemento.fecha_termino}</td>
                <td><Button variant="success" onClick={()=>handleIngresar(elemento.id_actividad)}>Ir</Button></td>
              </tr>:null
                        ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default ActividadesFinalizadas;
