import React, {useContext,  useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import { AuthContext } from "../../auth/authContext";
import Swal from "sweetalert2";


const ModificarActividad = (data) => {
  const {user}=useContext(AuthContext);
  const [id]=useState(data.data);
  const [idimputable,setIdimputable]=useState('');
  const [actividad,setActividad]=useState([{}]);
  const [sence,setSence]=useState([{}]);
  
  useEffect(()=>{
      const actividadData=async(values)=>{
          const response= await AuthService.getActividad(id);
          setIdimputable(response.data.body[0].id_imputable)
          const response2= await AuthService.getActividadTipo();
          setActividad(response2.data.body)
          const response3= await AuthService.getSence();
          setSence(response3.data.body)
          formik.setValues({
            ...values,
            tipo_actividad: response.data.body[0].tipo_actividad,
            ind_sence: response.data.body[0].ind_sence,
            sence: response.data.body[0].codigo_sence===null ? '': response.data.body[0].codigo_sence,
            nombre: response.data.body[0].nombre_generico===null ? '': response.data.body[0].nombre_generico,
            valor:response.data.body[0].valor_venta===null ? '': response.data.body[0].valor_venta,
            adm_negocios: user.name,
          })
      }
      actividadData();
  },[])
  
  //validaciones
  const submitForm=async (values,actions)=>{
    let c_sence='';
    if(values.ind_sence==='S'){
      c_sence=values.sence;
    }else{
      c_sence='';
    }
    let r_sence='';
    if(c_sence!==''){
      const responseChange=await AuthService.getSenceId(values.sence);
      r_sence=responseChange.data.body[0].nombre_curso;
    }
    const data={'id':id,
                'id_imputable':idimputable,
                'tipo_actividad':values.tipo_actividad,
                'ind_sence':values.ind_sence,
                'codigo_sence':c_sence,
                'registro_sence':r_sence,
                'nombre_generico':values.nombre,
                'valor_venta':values.valor,
                'administrador_negocio':values.adm_negocios,
                'usuario_modifica':user.name,
                'estado':'V'
              }
    const response=await AuthService.postActividad(data);
    if(response.status===201){
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: response.body,
        showConfirmButton: false,
        timer: 1500
      })
    }else{
      Swal.fire('Ha ocurrido un error!',response.body,'error')
    };
  };
  const formik = useFormik({
    initialValues: {
      tipo_actividad: "",
      ind_sence: "",
      sence: "",
      nombre: "",
      valor:"",
      adm_negocios: "",
    },
    validationSchema: Yup.object({
      tipo_actividad: Yup.string().required("Es obligatorio"),
      ind_sence: Yup.string().required("Es obligatorio"),
      sence: Yup.string(),
      nombre: Yup.string()
        .min(3, "Debe tener al menos 3 caracteres.")
        .required("Es obligatorio"),
      valor: Yup.number()
        .required("Es obligatorio"),
      adm_negocios: Yup.string()
        .min(3, "Debe tener al menos 3 caracteres."),
    }),
    onSubmit: submitForm,
  });

  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Modificación de actividad de Capacitación
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col sm={3}><Form.Label>Tipo Actividad</Form.Label></Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="tipo_actividad">
                      <Form.Select
                        value={formik.values.tipo_actividad}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option value="0">Seleccione..</option>
                        {actividad.map(elemento=>(
                        <option key={"actividad"+elemento.id} value={elemento.id}>{elemento.nombre}</option>
                      ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.tipo_actividad &&
                    formik.errors.tipo_actividad ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.tipo_actividad}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Actividad Sence</Form.Label></Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="ind_sence">
                      <Form.Select
                        value={formik.values.ind_sence}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option value="0">Seleccione..</option>
                        <option value="N">No</option>
                        <option value="S">Si</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.ind_sence &&
                    formik.errors.ind_sence ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.ind_sence}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Código Sence</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="sence">
                    <Form.Select
                        value={formik.values.ind_sence==='N'?'0':formik.values.sence}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled={formik.values.ind_sence!=='S'?true:false}
                      >
                        <option value="0">Seleccione..</option>
                        {sence.map(elemento=>(
                        <option key={"sence"+elemento.id} value={elemento.id}>{elemento.codigo_curso} - {elemento.nombre_curso}</option>
                      ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.sence && formik.errors.sence ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.sence}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Nombre Genérico</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="nombre">
                      <Form.Control
                        type="text"
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.nombre && formik.errors.nombre ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.nombre}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Valor de venta</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="valor">
                      <Form.Control
                        type="number"
                        value={formik.values.valor}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.valor && formik.errors.valor ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.valor}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Administrador de Negocios</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="adm_negocios">
                      <Form.Control
                        type="text"
                        value={user.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.adm_negocios &&
                    formik.errors.adm_negocios ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.adm_negocios}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Button variant="primary" type="submit">
                  Guardar
                </Button>
              </Form>
            </Col>
          </Card.Body>
          <Card.Footer style={myFooter}>
            
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};
const myFooter = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "left",
};

export default ModificarActividad;
