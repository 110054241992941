import React, { useContext, useEffect, useState  } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import { AuthContext } from "../../auth/authContext";
import Swal from "sweetalert2";
import {format, parseISO } from 'date-fns';
import { Table } from "react-bootstrap";

const MantenedorArea= () => {
  const {user}=useContext(AuthContext );
  const [areas,setAreas]=useState([]);
  const [id,setId]=useState('0');

  useEffect(()=>{
    const areasData=async()=>{
        const response= await AuthService.getAreas();
        setAreas(response.data.body) 
    }
    areasData();
  },[])
    const submitForm=async(values,actions)=>{
      const data={'id':id,
                  'nombre':values.nombreArea,
                  'usuario_crea':user.name,
                  'estado':'V'
      }
       
        const response=await AuthService.postArea(data);
        if(response.status===201){
          Swal.fire(response.body,'','success')
          handleLimpiar()
        }else{
            Swal.fire('Ha ocurrido un error!',response.body,'error')
        };
        
    };
    //validaciones
    const formik = useFormik({
    initialValues: {
        nombreArea: "",
    },
    validationSchema: Yup.object({
        nombreArea:  Yup.string().min(3, "Debe tener al menos 3 caracteres.").required("Es obligatorio"),
    }),
    onSubmit: submitForm,
  });
  function cambiarFormatoFecha(fecha){
    const fechaISO=parseISO(fecha);
    const fechaFormateada=format(fechaISO,'dd-MM-yyyy');
    return fechaFormateada;
  }
  const handleEditar=async(idEdita,values)=>{
    const response=await AuthService.getArea(idEdita)
      setId(idEdita);
      formik.setValues({
        ...values,
        nombreArea: response.data.body[0].nombre
      });
  }
  const handleEliminar=async(idElimina)=>{
    Swal.fire({
      title: '¿Está seguro de eliminar?',
      text: "No se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        const response=await AuthService.putArea({'id':idElimina,'usuario':user.name})
        console.log(response.status)
        if(response.status===200){
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.body,
            showConfirmButton: false,
            timer: 1500
          })
          handleLimpiar()
        }else{
          Swal.fire('Ha ocurrido un error!',response.body,'error')
        }
      }
    })
  }
  const handleLimpiar=async(e,values)=>{
    const response2= await AuthService.getAreas();
    setAreas(response2.data.body)
    formik.setValues({
      ...values,
      nombreArea: ""
    });
    setId('0');
    
  }

  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Mantenedor de Areas
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="nombreArea">
                      <Form.Label>Nombre del area:</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.nombreArea}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.nombreArea && formik.errors.nombreArea ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.nombreArea}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit">
                      Guardar
                    </Button>
                    {' '}
                    <Button variant="success" onClick={handleLimpiar}>
                      Limpiar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Areas Creadas
          </Card.Header>
          <Card.Body style={myBody}>
            <Table striped bordered hover size="sm" variant="dark" responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nombre</th>
                  <th>Usuario Crea</th>
                  <th>Fecha Crea</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {areas.map((elemento,index)=>(
                <tr key={"areas"+elemento.id}>
                <td>{index+1}</td>
                <td>{elemento.nombre}</td>
                <td>{elemento.usuario_crea}</td>
                <td>{elemento.fecha_crea?cambiarFormatoFecha(elemento.fecha_crea.substring(0,10)):''}</td>
                <td><Button variant="warning" onClick={()=>handleEditar(elemento.id)}>Editar</Button></td>
                <td><Button variant="danger" onClick={()=>handleEliminar(elemento.id)}>Eliminar</Button></td>
              </tr>
                        ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default MantenedorArea;
