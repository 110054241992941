import React, { useContext, useEffect,  useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import Swal from "sweetalert2";
import { PDFViewer } from '@react-pdf/renderer';
import CertificadoNotasPDF from "./CertificadoNotasPDF";
import CertificadoNotasPDF2 from "./CertificadoNotasPDF2";
import { AuthContext } from "../../auth/authContext";
import {format, parseISO } from 'date-fns';
import {es} from 'date-fns/locale';

const CertificadoNotas2 = () => {
  const { user } = useContext(AuthContext ); // Obtener user del contexto
  const [cursos,setCursos]=useState([]);
  const [id,setId]=useState('0');
  const [idalumno,setIdalumno]=useState('0');
  const [verpdf,setVerpdf]=useState(false);
  const [cursoSelect,setCursoSelect]=useState([]);


  function validateRut(rut) {
    const cleanRut = rut.replace(/[.-]/g, ''); // Eliminar puntos y guiones
    const rutNumber = cleanRut.slice(0, -1);
    const verifierDigit = cleanRut.slice(-1).toUpperCase();
  
    if (!/^[0-9kK]+$/g.test(verifierDigit)) {
      return false; // El dígito verificador solo debe ser número o K
    }
  
    const reversedRutArray = [...rutNumber].reverse();
    const verifier = reversedRutArray.reduce((acc, digit, index) => {
      const multiplier = (index % 6) + 2;
      return acc + parseInt(digit, 10) * multiplier;
    }, 0);
  
    const remainder = verifier % 11;
    const calculatedVerifierDigit = remainder === 1 ? 'K' : remainder === 0 ? '0' : (11 - remainder).toString();
  
    return calculatedVerifierDigit === verifierDigit;
  }
  const handleBlurRut=async (e,values)=>{
    if(validateRut(values.run+'-'+values.dv)){
      let timerInterval
      Swal.fire({
        title: 'Buscando alumno',
        html: 'Espere...<b></b>',
        timer: 500,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
          const b = Swal.getHtmlContainer().querySelector('b')
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft()
          }, 100)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
        }
      })
      const response=await AuthService.getAlumnoRun(values.run)
      setIdalumno(response.data.body[0].id);
      const responseCursos=await AuthService.getCursoAlumno(response.data.body[0].id)
      //setId(responseCursos.data.body[0].id);
      setCursos(responseCursos.data.body)
      
      formik.setValues({
        ...values,
        nombre: response.data.body[0].nombres,
        primerAp: response.data.body[0].primer_apellido,
        segundoAp: response.data.body[0].segundo_apellido,
      });
      
    }else{
      Swal.fire('Rut Incorrecto','','error')
      formik.setValues({
        ...values,
        nombre: "",
        primerAp: "",
        segundoAp: "",
        run: "",
        dv: "",
        curso: "",
      });
    }
  }

 const submitForm=async(values,actions)=>{
  //setId(values.curso)
  Swal.fire({
    title: 'Confirmar acción',
    text: "La generación de certificado, generará un folio interno, ¿Esta seguro?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Generar!'
  }).then(async(result) => {
    if (result.isConfirmed) {
      handleFoliar(values.curso)
    }
  })
};

  //validaciones
  const formik = useFormik({
    initialValues: {
      nombre: "",
      primerAp: "",
      segundoAp: "",
      run: "",
      dv: "",
      curso: "",
    },
    validationSchema: Yup.object({
      nombre: Yup.string()
        .min(3, "Debe tener al menos 3 caracteres.")
        .required("Es obligatorio"),
      primerAp: Yup.string()
        .min(3, "Debe tener al menos 3 caracteres.")
        .required("Es obligatorio"),
      segundoAp: Yup.string().min(3, "Debe tener al menos 3 caracteres."),
      run: Yup.number()
        .max(99999999, "Debe tener al menos 8 caracteres.")
        .required("Es obligatorio"),
      dv: Yup.string().max(1, "Largo 1").required("Falta"),
      curso: Yup.string().required("Es obligatorio"),
    }),
    onSubmit: submitForm,
  });
  const handleLimpiar=(e,values)=>{
    formik.setValues({
      ...values,
      nombre: "",
      primerAp: "",
      segundoAp: "",
      run: "",
      dv: "",
      curso: "",
    });
    setCursos([]);
    setVerpdf(false)
  }
  const handleFoliar=async(idCursoAlumno)=>{
    const response= await AuthService.getCursoAlumnoId(idCursoAlumno);
    console.log(response.data.body[0].id)
    setCursoSelect(response.data.body[0])
    let fechaCurso=response.data.body[0].fecha_inicio_curso ? cambiarFormatoFecha(response.data.body[0].fecha_inicio_curso, 'dd-MM-yyyy') : '' ;
          try {
            const dataParticipante = {
              'tipo_folio': 4,
              'detalle_documento': response.data.body[0].nombre_alumno + ' ' + response.data.body[0].primer_apellido + ' ' + response.data.body[0].segundo_apellido + ' ' + 'Run: ' + response.data.body[0].run + '-' + response.data.body[0].dv + ' ' + response.data.body[0].nombre_curso + ' ' + fechaCurso + ', ciclo:' + response.data.body[0].nombre_ciclo,
              'ind_fondorendir': 'N',
              'usuario_crea': user.name,
              'id': response.data.body[0].id
            };
            const responseParticipante = await AuthService.postFoliarAlumno(dataParticipante);
            if (responseParticipante.status === 200) {
              console.log(cursoSelect.nombre_alumno + ' ' + cursoSelect.primer_apellido + ' ' + cursoSelect.segundo_apellido + ' ' + 'Run: ' + cursoSelect.run + '-' + cursoSelect.dv + ' ' + cursoSelect.nombre_curso );
              setId(idCursoAlumno)
              setVerpdf(true)
            }
          } catch (error) {
            console.error('Error al foliar:', error);
            setVerpdf(false);
          }
  }
  function cambiarFormatoFecha(fecha,tipo){
    const fechaISO=parseISO(fecha);
    let fechaFormateada='';
    if(tipo==='dd'){
        fechaFormateada=format(fechaISO,'dd');
    }
    if(tipo==='MM'){
        fechaFormateada=capitalize(format(fechaISO,'MMMM',{locale:es}));
    }
    if(tipo==='yyyy'){
        fechaFormateada=format(fechaISO,'yyyy');
    }
    if(tipo==='dd-MM-yyyy'){
        fechaFormateada=format(fechaISO,'dd-MM-yyyy');
    }
    return fechaFormateada;
  }
  const capitalize=(str)=>{
    return str.charAt(0).toUpperCase()+str.slice(1);
  }
  
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Certificado de Notas (formato 2)
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="run">
                      <Form.Label>Run de Alumno</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.run}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.run && formik.errors.run ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.run}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col sm={1}>
                    <Form.Group className="mb-3" controlId="dv">
                      <Form.Label>DV</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.dv}
                        onChange={formik.handleChange}
                        onBlur={e=>{handleBlurRut(e,formik.values)}}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.dv && formik.errors.dv ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.dv}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="nombre">
                      <Form.Label>Nombre</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                    {formik.touched.nombre && formik.errors.nombre ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.nombre}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="primerAp">
                      <Form.Label>Primer Apellido</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.primerAp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                    {formik.touched.primerAp && formik.errors.primerAp ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.primerAp}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="segundoAp">
                      <Form.Label>Segundo Apellido</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.segundoAp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                    {formik.touched.segundoAp && formik.errors.segundoAp ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.segundoAp}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="curso">
                      <Form.Label>Curso</Form.Label>
                      <Form.Select
                        value={formik.values.curso}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option value='0'>Seleccione...</option>
                        {cursos.length>0?cursos.map(elemento=>(
                        <option key={"cursosAlumno"+elemento.id} value={elemento.id}>{elemento.nombre_ciclo} {elemento.nombre_curso}</option>
                      )):''}
                      </Form.Select>
                    </Form.Group>
                    {formik.touched.curso && formik.errors.curso ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.curso}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit">
                      Ver Certificado
                    </Button>
                    {' '}
                    <Button variant="success" onClick={handleLimpiar}>
                      Limpiar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card.Body>
        </Card>
        {cursos ? 
        <>{ verpdf ? <Card>
          <Card.Header as="h3" style={myHeader}>
            Impresión de Certificado
          </Card.Header>
          <Card.Body style={myBody}>
            <PDFViewer style={{width:"100%", height:"90vh"}}>
              <CertificadoNotasPDF2  datos={{'alumno':id,'curso':id,'idAlumno':idalumno}} />
            </PDFViewer>
          </Card.Body>
        </Card>:null}
        </>
        : null}
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default CertificadoNotas2