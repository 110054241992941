import React, { useContext,useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import { AuthContext } from "../../auth/authContext";
import Swal from "sweetalert2";
import { Table } from "react-bootstrap";
import {format, parseISO } from 'date-fns';


const MantenedorActividadTipo= () => {
  const [id,setId]=useState('0');
  const [actividades,setActividades]=useState([{}]);
  useEffect(()=>{
    const actividadData=async()=>{
      const response= await AuthService.getActividadTipo();
      setActividades(response.data.body) 
    }
    actividadData();
  },[])
  const {user}=useContext(AuthContext );
  const submitForm=async(values,actions)=>{
    const data={'id':id,
                'nombre':values.nombre,
                'usuario_crea':user.name,
                'estado':'V'
    }
      
      const response=await AuthService.postActividadTipo(data);
      if(response.status===201){
          Swal.fire(response.body,'','success')
          actions.setSubmitting(false);
          actions.resetForm({
            values: {
              nombre: "",
            },
          });
          handleActualizaTabla();
      }else{
          Swal.fire('Ha ocurrido un error!',response.body,'error')
      };
        
  };
    //validaciones
    const formik = useFormik({
    initialValues: {
        nombre: "",
    },
    validationSchema: Yup.object({
        nombre:  Yup.string().required("Es obligatorio"),
    }),
    onSubmit: submitForm,
  });
  const handleEditar=async(idEdita,values)=>{
    const response=await AuthService.getActividadTipoId(idEdita)
      setId(idEdita);
      formik.setValues({
        ...values,
        nombre: response.data.body[0].nombre
      });
  }
  const handleEliminar=async(idElimina)=>{
    Swal.fire({
      title: '¿Está seguro de eliminar?',
      text: "No se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        const response=await AuthService.putActividadTipo({'id':idElimina,'usuario':user.name})
        if(response.status===200){
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.body,
            showConfirmButton: false,
            timer: 1500
          })
          handleActualizaTabla();
          Swal.fire(
            'Eliminado!',
            'El registro fue eliminado.',
            'success'
          )
        }else{
          Swal.fire('Ha ocurrido un error!',response.body,'error')
        }
      }
    })
  }
  const handleActualizaTabla=async()=>{
    const response= await AuthService.getActividadTipo();
    setActividades(response.data.body)
  }
  function cambiarFormatoFecha(fecha){
    const fechaISO=parseISO(fecha);
    const fechaFormateada=format(fechaISO,'dd-MM-yyyy');
    return fechaFormateada;
  }
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Mantenedor de Tipo Actividad
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="nombre">
                      <Form.Label>Nombre de la actividad:</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.nombre && formik.errors.nombre ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.nombre}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit">
                      Guardar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Actividad creadas
          </Card.Header>
          <Card.Body style={myBody}>
            <Table striped bordered hover size="sm" variant="dark" responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Tipo</th>
                  <th>Usuario Crea</th>
                  <th>Fecha Crea</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {actividades?actividades.map(elemento=>(
                <tr key={"actividades"+elemento.id}>
                <td>{elemento.id}</td>
                <td>{elemento.nombre}</td>
                <td>{elemento.usuario_crea}</td>
                <td>{elemento.fecha_crea?cambiarFormatoFecha(elemento.fecha_crea.substring(0,10)):''}</td>
                <td><Button variant="warning" onClick={()=>handleEditar(elemento.id)}>Editar</Button></td>
                <td><Button variant="danger" onClick={()=>handleEliminar(elemento.id)}>Eliminar</Button></td>
              </tr>
              )):<tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default MantenedorActividadTipo;
