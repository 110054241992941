import React, { useContext,useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import { AuthContext } from "../../auth/authContext";
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import { Table } from "react-bootstrap";


const Instructor= (data) => {
  const {user}=useContext(AuthContext );
  const [id]=useState(data.data);
  const [idimputable,setIdimputable]=useState('');
  const [tipoInstructor,setTipoInstructor]=useState('');
  const [titular,setTitular]=useState('');
  const [reemplazo,setReemplazo]=useState('');
  const [instructores,setInstructores]=useState([{}]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (tipo) =>{
    setTipoInstructor(tipo)
    setShow(true);
  } 
  
  useEffect(()=>{
    const actividadData=async(values)=>{
        const response= await AuthService.getActividad(id);
        setIdimputable(response.data.body[0].id_imputable)
        setTitular(response.data.body[0].id_titular)
        setReemplazo(response.data.body[0].id_reemplazo)
        formik.setValues({
          ...values,
          titular: response.data.body[0].instructor_titular===null ? '': response.data.body[0].instructor_titular,
          reemplazante: response.data.body[0].instructor_reemplazo===null ? '': response.data.body[0].instructor_reemplazo,
        })
    }
    actividadData();
    const instructoresData=async()=>{
      const response= await AuthService.getInstructores();
      setInstructores(response.data.body) 
    }
    instructoresData();
},[])

    //validaciones
    const formik = useFormik({
    initialValues: {
        titular: "",
        reemplazante:"",
    },
    validationSchema: Yup.object({
        titular:  Yup.string().required("Es obligatorio"),
        reemplazante:  Yup.string(),
    }),
    onSubmit: null,
  });

  const handleCambiar=async(idIns,values)=>{
    Swal.fire({
      title: '¿Está seguro de cambiar?',
      text: "No se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Cambiar!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        let var_titular=tipoInstructor==='T' ? idIns :titular;
        let var_reemplazo=tipoInstructor==='R' ? idIns :reemplazo;
        let data={};
        if(tipoInstructor==='T'){
          data={'id':id,
          'id_imputable':idimputable,
          'id_instructor_titular':var_titular,
          'usuario_modifica':user.name,
          'estado':'V'
          }
        }
        if(tipoInstructor==='R'){
          data={'id':id,
            'id_imputable':idimputable,
            'id_instructor_reemplazo':var_reemplazo,
            'usuario_modifica':user.name,
            'estado':'V'
          }
        }
        const response=await AuthService.postActividad(data)
        if(response.status===201){
          const response2= await AuthService.getActividad(id);
          formik.setValues({
            ...values,
            titular: response2.data.body[0].instructor_titular===null ? '': response2.data.body[0].instructor_titular,
            reemplazante: response2.data.body[0].instructor_reemplazo===null ? '': response2.data.body[0].instructor_reemplazo,
          })
          setShow(false)
        }else{
          Swal.fire('Ha ocurrido un error!',response.body,'error')
        }
        Swal.fire(
          'Cambiado!',
          'El registro fue cambiado.',
          'success'
        )
      }
    })
  }
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Instructores
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col><Form.Label>Instructor Titular:</Form.Label></Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="titular">
                      <Form.Control
                        type="text"
                        value={formik.values.titular}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                    {formik.touched.titular && formik.errors.titular ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.titular}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Button variant="success" onClick={() => handleShow('T')}>
                      Cambiar
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col><Form.Label>Instructor Reemplazante:</Form.Label></Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="reemplazante">
                      <Form.Control
                        type="text"
                        value={formik.values.reemplazante}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                    {formik.touched.reemplazante && formik.errors.reemplazante ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.reemplazante}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Button variant="success" onClick={() => handleShow('R')}>
                      Cambiar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card.Body>
        </Card>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Instructores</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover size="sm" variant="dark" className="text-center">
              <thead>
                <tr>
                  <th>Run</th>
                  <th>Nombre</th>
                  <th>Ciudad</th>
                  <th>Area</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {instructores.map(elemento=>(
                <tr key={"instructor"+elemento.id}>
                <td>{elemento.run}-{elemento.dv}</td>
                <td>{elemento.primer_apellido} {elemento.segundo_apellido} {elemento.nombres}</td>
                <td>{elemento.ciudad}</td>
                <td>{elemento.area}</td>
                <td><Button variant="warning" onClick={()=>handleCambiar(elemento.id)}>Cambiar</Button></td>
              </tr>
                        ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default Instructor;
