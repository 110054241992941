import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import { Nav, Tab, Table } from "react-bootstrap";
import { FaCalendarCheck, FaCheck, FaEdit, FaTimes, FaUsers, FaUserTie } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import MantenedorParticipantes from "../participantes/MantenedorParticipantes";
import Programacion from "../programacion/Programacion";
import Instructor from "../instructores/Instructor";
import ModificarActividad from "./ModificarActividad";
import FinalizarActividad from "./FinalizarActividad";
import AnularActividad from "./AnularActividad";
import LibroClases from "../libros/LibroClases";

const Actividad = () => {
  const { state } = useLocation();

  const [actividad,setActividad]=useState([{}]);
    useEffect(()=>{
        const actividadesData=async()=>{
            const response= await AuthService.getActividad(state);
            setActividad(response.data.body) 
        }
        actividadesData();
    },[])

  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Actividad
          </Card.Header>
          <Card.Body style={myBody}>
            <Table striped bordered hover size="sm" variant="dark" className="text-center" responsive>
              <thead>
                <tr>
                  <th>Activ.N°</th>
                  <th>Empresa</th>
                  <th>Actividad</th>
                  <th>C.Resultados</th>
                  <th>Inicio</th>
                  <th>Término</th>
                </tr>
              </thead>
              <tbody>
              {actividad.map(elemento=>(
                <tr key={"actividad"+elemento.id_actividad}>
                <td>{elemento.id_actividad}</td>
                <td>{elemento.nombre_empresa}</td>
                <td>{elemento.nombre_generico}</td>
                <td>{elemento.centro_resultado}</td>
                <td>{elemento.inicio}</td>
                <td>{elemento.termino}</td>
              </tr>
                        ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body style={myBody}>
            <Tab.Container  defaultActiveKey="linkProgramacion"> 
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="linkProgramacion"><FaCalendarCheck/>Programación</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="linkParticipantes"><FaUsers/>Participantes</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="linkInstructores"><FaUserTie/>Instructores</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="linkModificar"><FaEdit/>Modificar</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="linkLibroClases"><FaEdit/>Libro de Clases</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="linkFinalizar"><FaCheck/>Finalizar</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="linkaAnular"><FaTimes/>Anular</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="linkProgramacion">
                  <Programacion data={state}/>
                </Tab.Pane>
                <Tab.Pane eventKey="linkParticipantes">
                  <MantenedorParticipantes data={state}/>
                </Tab.Pane>
                <Tab.Pane eventKey="linkInstructores">
                  <Instructor data={state}/>
                </Tab.Pane>
                <Tab.Pane eventKey="linkModificar">
                  <ModificarActividad data={state}/>
                </Tab.Pane>
                <Tab.Pane eventKey="linkLibroClases">
                  <LibroClases data={state}/>
                </Tab.Pane>
                <Tab.Pane eventKey="linkFinalizar">
                <FinalizarActividad data={state}/>
                </Tab.Pane>
                <Tab.Pane eventKey="linkaAnular">
                <AnularActividad data={state}/>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default Actividad;
