import React, { useContext,useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import { AuthContext } from "../../auth/authContext";
import Swal from "sweetalert2";
import { Table } from "react-bootstrap";


const MantenedorHorario= () => {
  const [id,setId]=useState('0');
  const [horarios,setHorarios]=useState([]);
  const {user}=useContext(AuthContext );
  useEffect(()=>{
    const horarioData=async()=>{
      const response= await AuthService.getHorarios();
      setHorarios(response.data.body) 
    }
    horarioData();
  },[])
  const submitForm=async(values,actions)=>{
    const data={'id':id,
                'hora_inicio_manana':values.iniManana,
                'hora_termino_manana':values.finManana,
                'hora_inicio_tarde':values.iniTarde,
                'hora_termino_tarde':values.finTarde,
                'usuario_crea':user.name,
                'estado':'V'
    }
    
    const response=await AuthService.postHorario(data);
    if(response.status===201){
        Swal.fire(response.body,'','success')
        handleLimpiar();
        handleActualizaTabla();
    }else{
        Swal.fire('Ha ocurrido un error!',response.body,'error')
    };      
  };
  //validaciones
  const formik = useFormik({
    initialValues: {
      iniManana: "",
      finManana: "",
      iniTarde: "",
      finTarde: "",
    },
    validationSchema: Yup.object({
        iniManana:  Yup.string().required('La hora es requerida')
                                .matches(/^([01]\d|2[0-3]):?([0-5]\d)$/, 'Hora inválida'),
        finManana:  Yup.string().required('La hora es requerida')
                                .matches(/^([01]\d|2[0-3]):?([0-5]\d)$/, 'Hora inválida'),
        iniTarde:   Yup.string().required('La hora es requerida')
                                .matches(/^([01]\d|2[0-3]):?([0-5]\d)$/, 'Hora inválida'),
        finTarde:   Yup.string().required('La hora es requerida')
                                .matches(/^([01]\d|2[0-3]):?([0-5]\d)$/, 'Hora inválida'),
    }),
    onSubmit: submitForm,
  });
  const handleEditar=async(idEdita,values)=>{
    const response=await AuthService.getHorarioId(idEdita)
      setId(idEdita);
      formik.setValues({
        ...values,
        iniManana: response.data.body[0].hora_inicio_manana.substring(0,5),
        finManana: response.data.body[0].hora_termino_manana.substring(0,5),
        iniTarde: response.data.body[0].hora_inicio_tarde.substring(0,5),
        finTarde: response.data.body[0].hora_termino_tarde.substring(0,5)
      });
  }
  const handleEliminar=async(idElimina)=>{
    Swal.fire({
      title: '¿Está seguro de eliminar?',
      text: "No se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        const response=await AuthService.putHorario({'id':idElimina,'usuario':user.name})
        if(response.status===200){
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.body,
            showConfirmButton: false,
            timer: 1500
          })
          handleActualizaTabla();
          Swal.fire(
            'Eliminado!',
            'El registro fue eliminado.',
            'success'
          )
        }else{
          Swal.fire('Ha ocurrido un error!',response.body,'error')
        }
      }
    })
  }
  const handleActualizaTabla=async()=>{
    const response= await AuthService.getHorarios();
    setHorarios(response.data.body)
  }
  const handleLimpiar=(e,values)=>{
    formik.setValues({
      ...values,
        iniManana: "",
        finManana: "",
        iniTarde: "",
        finTarde: "",
      }
    );
    setId('0');
  }
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Mantenedor de Horarios
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="iniManana">
                      <Form.Label>Hora Inicio Mañana:</Form.Label>
                      <Form.Control
                        type="time"
                        value={formik.values.iniManana}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.iniManana && formik.errors.iniManana ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.iniManana}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="finManana">
                      <Form.Label>Hora Término Mañana:</Form.Label>
                      <Form.Control
                        type="time"
                        value={formik.values.finManana}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.finManana && formik.errors.finManana ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.finManana}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="iniTarde">
                      <Form.Label>Hora Inicio Tarde:</Form.Label>
                      <Form.Control
                        type="time"
                        value={formik.values.iniTarde}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.iniTarde && formik.errors.iniTarde ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.iniTarde}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="finTarde">
                      <Form.Label>Hora Término Tarde:</Form.Label>
                      <Form.Control
                        type="time"
                        value={formik.values.finTarde}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.finTarde && formik.errors.finTarde ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.finTarde}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit">
                      Guardar
                    </Button>
                    {' '}
                    <Button variant="success" onClick={handleLimpiar}>
                      Limpiar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Horarios creados
          </Card.Header>
          <Card.Body style={myBody}>
            <Table striped bordered hover size="sm" variant="dark" responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Hora Inicio Mañana</th>
                  <th>Hora Término Mañana</th>
                  <th>Hora Inicio Tarde</th>
                  <th>Hora Término Tarde</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {horarios?horarios.map(elemento=>(
                <tr key={"horarios"+elemento.id}>
                <td>{elemento.id}</td>
                <td>{elemento.hora_inicio_manana}</td>
                <td>{elemento.hora_termino_manana}</td>
                <td>{elemento.hora_inicio_tarde}</td>
                <td>{elemento.hora_termino_tarde}</td>
                <td><Button variant="warning" onClick={()=>handleEditar(elemento.id)}>Editar</Button></td>
                <td><Button variant="danger" onClick={()=>handleEliminar(elemento.id)}>Eliminar</Button></td>
              </tr>
              )):<tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default MantenedorHorario;
