import React, { useEffect, useState} from 'react'
import { Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer'
import AuthService from "../../api/ApiRest";
//import QRCode from "react-qr-code";
//import QRCode from 'qrcode.react';
import Fondo from "../../assets/ctm_3.jpg"
import {format, parseISO } from 'date-fns';
import {es} from 'date-fns/locale';
import { renderToStaticMarkup } from 'react-dom/server';
import QrCodigo from './QrCodigo'

const EgresoExamenPDF = ({datos}) => {
    const [curso,setCurso]=useState([]);
    const [habilitado,setHabilitado]=useState(false);
    const [director,setDirector]=useState([]);
    const [directorS,setDirectorS]=useState([]);
    const [encargado,setEncargado]=useState([]);
    useEffect(()=>{
        
        const cursoData=async()=>{
            const response= await AuthService.getCursoAlumnoId(datos);
            setCurso(response.data.body[0])
        }
        cursoData();
        const firmaData=async()=>{
            const response= await AuthService.getFirmas();
            for (let index = 0; index < response.data.body.length; index++) {
                if(response.data.body[index].habilitado==='S'){
                    setHabilitado(true)
                }else{
                    setHabilitado(false)
                }
                if(response.data.body[index].cargo==='D'){
                    setDirector(response.data.body[index])
                }
                if(response.data.body[index].cargo==='S'){
                    setDirectorS(response.data.body[index])
                }
                if(response.data.body[index].cargo==='E'){
                    setEncargado(response.data.body[index])
                }
            }
        }
        firmaData();
    },[])
    //const qrContent=JSON.stringify(curso.folio);
      /*<QRCode
        size={256}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value='holamundo'
        viewBox={`0 0 256 256`}
        /> */
        
    const capitalize=(str)=>{
        return str.charAt(0).toUpperCase()+str.slice(1);
    }
    function cambiarFormatoFecha(fecha,tipo){
        const fechaISO=parseISO(fecha);
        let fechaFormateada='';
        if(tipo==='dd'){
            fechaFormateada=format(fechaISO,'dd');
        }
        if(tipo==='MM'){
            fechaFormateada=capitalize(format(fechaISO,'MMMM',{locale:es}));
        }
        if(tipo==='yyyy'){
            fechaFormateada=format(fechaISO,'yyyy');
        }
        if(tipo==='dd-MM-yyyy'){
            fechaFormateada=format(fechaISO,'dd-MM-yyyy');
        }
        return fechaFormateada;
        }
        const [qrImage, setQrImage] = useState(null);

        const handleImageGenerated = (imageDataUrl) => {
          setQrImage(imageDataUrl);
        };
    return (
        <Document>
            <Page size="LETTER" style={styles.page}>
                <View style={styles.header} fixed>
                    <Image
                        src={Fondo}
                        style={styles.fondo}
                    />
                </View>
                <View  style={styles.titulo}>
                    <Text>Centro Tecnológico Minero S.A. Otorga el presente certificado a don:</Text>
                </View>
                <View  style={styles.alumno}>
                    <Text>{curso.nombre_alumno} {curso.primer_apellido} {curso.segundo_apellido}</Text>
                    <Text>{curso.run}{curso.extranjero==='N' ?'-'+curso.dv:null}</Text>
                </View>
                <View  style={styles.parrafo}>
                    <Text>Por su participación y aprobación en el curso</Text>
                </View>
                <View style={styles.curso}>
                    <Text>{curso.nombre_curso}</Text>
                </View>
                <View  style={styles.parrafo}>
                    <Text>Con un total de {curso.duracion_horas} Horas.</Text>
                    <Text>Obteniendo la siguiente calificación:</Text>
                    <Text>Rendimiento Final: {curso.nota_final}%</Text>
                    <Text>Dictado desde {curso.fecha_inicio_curso?cambiarFormatoFecha(curso.fecha_inicio_curso.substring(0,10),'MM'):''} hasta {curso.fecha_termino_curso?cambiarFormatoFecha(curso.fecha_termino_curso.substring(0,10),'MM'):''} del {curso.fecha_termino_curso?cambiarFormatoFecha(curso.fecha_termino_curso.substring(0,10),'yyyy'):''}</Text>
                </View>
                {curso.ind_practica==='S'?
                    <View style={styles.parrafo}>
                    <Text>Práctica Pre-Ocupacional: 100%</Text>
                    <Text>Con una duración de {curso.horas_practica} horas.</Text>
                    <Text>La práctica Pre-Ocupacional se realizó en:</Text>
                    <View style={styles.curso}>
                        <Text>{curso.empresa_practica}</Text>
                    </View>
                    </View>
                    :<View></View>}

                <View style={styles.firmasNombre}>
                    <Text style={styles.textoIzquierda}>{encargado.nombre} {encargado.primer_apellido} {encargado.segundo_apellido}</Text>
                    <Text style={styles.textoDerecha}>{directorS.habilitado==='S'?directorS.nombre+' '+directorS.primer_apellido+' '+directorS.segundo_apellido:director.nombre+' '+director.primer_apellido+' '+director.segundo_apellido}</Text>
                </View>
                <View style={styles.firmasCargo}>
                    <Text style={styles.textoIzquierda}>{encargado.habilitado==='S'?'Director(S)':encargado.cargo==='D'?'Director':'Encargado de Proyecto'}</Text>
                    <Text style={styles.textoDerecha}>{directorS.habilitado==='S'?'Director(S)':director.cargo==='D'?'Director':'Encargado de Proyecto'}</Text>
                </View>
                <View style={styles.piePagina} fixed>
                    <Text>Folio Nº {curso.folio}</Text>
                    <Text>Fecha de Emisión: {curso.fecha_actual}</Text>
                    
                    

                </View>
                <View>
                 {/*<QRCode
        size={256}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value='holamundo'
        viewBox={`0 0 256 256`}
    /> 
                <QrCodigo data={'acaholamundo'} onImageGenerated={handleImageGenerated} />*/}
                </View>
                    
                
                
            </Page>
        </Document>
    )
}
// Create styles
const styles = StyleSheet.create({
    page: {
        paddingTop: 150,
        paddingBottom: 65,
        paddingHorizontal: 85,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    titulo:{
        fontFamily: 'Helvetica-Bold',
        fontSize: 26,
        textAlign: 'center'
    },
    fondo:{
        width:"600px",
        height:"900px",
    },
    alumno:{
        margin: 20,
        fontFamily: 'Helvetica-Bold',
        fontSize: 24,
        textAlign: 'center',
        color:'rgb(245,145,11)'
    },
    parrafo:{
        fontFamily: 'Helvetica',
        fontSize: 22,
        textAlign: 'center'
    },
    curso:{
        margin: 20,
        fontFamily: 'Helvetica-Bold',
        fontSize: 22,
        textAlign: 'center',
        color:'rgb(245,145,11)'
    },
    firmasNombre:{
        marginTop:25,
        fontFamily: 'Helvetica',
        fontSize: 14,
        textAlign: 'center',
        flexDirection:'row',
        justifyContent:'space-between',
    },
    firmasCargo:{
        fontFamily: 'Helvetica',
        fontSize: 14,
        textAlign: 'center',
        flexDirection:'row',
        justifyContent:'space-between',
    },
    textoIzquierda:{
        textAlign:'left',
    },
    textoDerecha:{
        textAlign:'right',
    },
    header: {
        position: 'absolute',
        marginBottom: 20,
      },
    piePagina: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        margin:35,
        textAlign: 'left',
        color: 'grey',
      },
  });

export default EgresoExamenPDF
