import React, { useContext,useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import { AuthContext } from "../../auth/authContext";
import Swal from "sweetalert2";
import { Table } from "react-bootstrap";

const MantenedorInstructor= () => {
  const {user}=useContext(AuthContext );
  const [instructores,setInstructores]=useState([]);
  const [id,setId]=useState('0');
  const [areas,setAreas]=useState([{}]);
    useEffect(()=>{
        const areasData=async()=>{
            const response= await AuthService.getAreas();
            setAreas(response.data.body) 
        }
        areasData();
        const instructoresData=async()=>{
          const response= await AuthService.getInstructores();
          setInstructores(response.data.body) 
      }
      instructoresData();
    },[])

  const submitForm=async(values,actions)=>{
    const data={'id':id,
                'id_area':values.area,
                'run':values.run,
                'dv':values.dv,
                'nombres':values.nombre,
                'primer_apellido':values.primerAp,
                'segundo_apellido':values.segundoAp,
                'ciudad':values.ciudad,
                'fono':values.numeroTelefono,
                'email':values.correo,
                'usuario_crea':user.name,
                'estado':'V'
    }
      
      const response=await AuthService.postInstructor(data);
      if(response.status===201){
          Swal.fire(response.body,'','success')
          actions.setSubmitting(false);
          actions.resetForm({
            values: {
              run: "",
              dv:"",
              nombre: "",
              primerAp:"",
              segundoAp:"",
              ciudad: "",
              numeroTelefono: "",
              correo: ""
            },
          });
      }else{
          Swal.fire('Ha ocurrido un error!',response.body,'error')
      };
      
  };
  function validateRut(rut) {
    const cleanRut = rut.replace(/[.-]/g, ''); // Eliminar puntos y guiones
    const rutNumber = cleanRut.slice(0, -1);
    const verifierDigit = cleanRut.slice(-1).toUpperCase();
  
    if (!/^[0-9kK]+$/g.test(verifierDigit)) {
      return false; // El dígito verificador solo debe ser número o K
    }
  
    const reversedRutArray = [...rutNumber].reverse();
    const verifier = reversedRutArray.reduce((acc, digit, index) => {
      const multiplier = (index % 6) + 2;
      return acc + parseInt(digit, 10) * multiplier;
    }, 0);
  
    const remainder = verifier % 11;
    const calculatedVerifierDigit = remainder === 1 ? 'K' : remainder === 0 ? '0' : (11 - remainder).toString();
  
    return calculatedVerifierDigit === verifierDigit;
  }
    const handleBlurRut=async (e,values)=>{
      if(!validateRut(values.run+'-'+values.dv)){
        Swal.fire('Rut Incorrecto','','error')
        formik.setValues({
          ...values,
          run:'',
          dv:'',
          nombre: '',
          primerAp:'',
          segundoAp:'',
          ciudad: '',
          numeroTelefono: '',
          correo: ''
        });
      }
    }
    //validaciones
    const formik = useFormik({
    initialValues: {
        run: "",
        dv:"",
        nombre: "",
        primerAp:"",
        segundoAp:"",
        ciudad: "",
        numeroTelefono: "",
        correo: ""
    },
    validationSchema: Yup.object({
        run:  Yup.string().min(7, "Debe tener al menos 7 caracteres.").max(8, "Debe tener 8 caracteres.").required("Es obligatorio"),
        dv:  Yup.string().max(1, "Debe tener 1 caracter.").required("Es obligatorio"),
        nombre:  Yup.string().required("Es obligatorio"),
        primerAp:  Yup.string().required("Es obligatorio"),
        segundoAp: Yup.string().min(3, "Debe tener al menos 3 caracteres."),
        ciudad: Yup.string().min(3, "Debe tener al menos 3 caracteres.").required("Es obligatorio"),
        numeroTelefono: Yup.number().min(6, "Debe tener al menos 6 caracteres.").required("Es obligatorio"),
        correo: Yup.string().min(3, "Debe tener al menos 3 caracteres.").email().required("Es obligatorio"),
    }),
    onSubmit: submitForm,
  });
  const handleEditar=async(idEdita,values)=>{
    const response=await AuthService.getInstructor(idEdita)
      setId(idEdita);
      formik.setValues({
        ...values,
        run: response.data.body[0].run,
        dv:response.data.body[0].dv,
        nombre: response.data.body[0].nombres,
        primerAp:response.data.body[0].primer_apellido,
        segundoAp:response.data.body[0].segundo_apellido,
        ciudad: response.data.body[0].ciudad,
        numeroTelefono: response.data.body[0].fono,
        correo: response.data.body[0].email
      });
  }
  const handleEliminar=async(idElimina)=>{
    Swal.fire({
      title: '¿Está seguro de eliminar?',
      text: "No se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        const response=await AuthService.putInstructor({'id':idElimina,'usuario':user.name})
        console.log(response.status)
        if(response.status===200){
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.body,
            showConfirmButton: false,
            timer: 1500
          })
          handleLimpiar()
        }else{
          Swal.fire('Ha ocurrido un error!',response.body,'error')
        }
      }
    })
  }
  const handleLimpiar=async(e,values)=>{
    const response2= await AuthService.getInstructores();
    setInstructores(response2.data.body)
    formik.setValues({
      ...values,
      run: "",
      dv:"",
      nombre: "",
      primerAp:"",
      segundoAp:"",
      ciudad: "",
      numeroTelefono: "",
      correo: ""
    });
    setId('0');
    
  }
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Mantenedor de Instructores
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
              <Row>
                  <Col sm={3}><Form.Label>Run - Identificador</Form.Label></Col>
                  <Col sm={4}>
                    <Form.Group className="mb-3" controlId="run">
                      <Form.Control
                        type="number"
                        value={formik.values.run}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={1}>
                    <Form.Group className="mb-3" controlId="dv">
                      <Form.Control
                        type="text"
                        value={formik.values.dv}
                        onChange={formik.handleChange}
                        onBlur={e=>{handleBlurRut(e,formik.values)}}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.run && formik.errors.run ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.run}
                      </Alert>
                    ) : null}
                    {formik.touched.dv && formik.errors.dv ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.dv}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Nombres</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="nombre">
                      <Form.Control
                        type="text"
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.nombre && formik.errors.nombre ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.nombre}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Primer Apellido</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="primerAp">
                      <Form.Control
                        type="text"
                        value={formik.values.primerAp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.primerAp && formik.errors.primerAp ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.primerAp}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Segundo Apellido</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="segundoAp">
                      <Form.Control
                        type="text"
                        value={formik.values.segundoAp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.segundoAp && formik.errors.segundoAp ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.segundoAp}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Ciudad</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group
                      className="mb-3"
                      controlId="ciudad"
                    >
                      <Form.Control
                        type="text"
                        value={formik.values.ciudad}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    
                  </Col>
                  <Col sm={4}>
                    {formik.touched.ciudad &&
                    formik.errors.ciudad ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.ciudad}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Teléfono</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="numeroTelefono">
                      <Form.Control
                        type="number"
                        value={formik.values.numeroTelefono}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.numeroTelefono &&
                    formik.errors.numeroTelefono ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.numeroTelefono}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Correo Electrónico</Form.Label>
                  </Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="correo">
                      <Form.Control
                        type="email"
                        value={formik.values.correo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.correo && formik.errors.correo ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.correo}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><Form.Label>Area</Form.Label></Col>
                  <Col sm={5}>
                    <Form.Group className="mb-3" controlId="area">
                      <Form.Select
                        value={formik.values.area}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option value="0">Seleccione</option>
                        {areas.map(elemento=>(
                        <option key={"areas"+elemento.id} value={elemento.id}>{elemento.nombre}</option>
                      ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    {formik.touched.area &&
                    formik.errors.area ? (
                      <Alert className="h-50 pt-1" variant="danger">
                        Hubo un error: {formik.errors.area}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit">
                      Guardar
                    </Button>
                    {' '}
                    <Button variant="success" onClick={handleLimpiar}>
                      Limpiar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Empresas Creadas
          </Card.Header>
          <Card.Body style={myBody}>
            <Table striped bordered hover size="sm" variant="dark" responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nombre</th>
                  <th>Rut</th>
                  <th>Correo Electrónico</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {instructores.map((elemento,index)=>(
                <tr key={"instructores"+elemento.id}>
                <td>{index+1}</td>
                <td>{elemento.nombres}</td>
                <td>{elemento.run}</td>
                <td>{elemento.email}</td>
                <td><Button variant="warning" onClick={()=>handleEditar(elemento.id)}>Editar</Button></td>
                <td><Button variant="danger" onClick={()=>handleEliminar(elemento.id)}>Eliminar</Button></td>
              </tr>
                        ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default MantenedorInstructor;
