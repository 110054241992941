import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import Swal from "sweetalert2";
import { Table } from "react-bootstrap";
import { AuthContext } from "../../auth/authContext";
import {BsSearch} from "react-icons/bs";
import Modal from 'react-bootstrap/Modal';

const Notas = () => {
  const {user}=useContext(AuthContext);
  const [cursos,setCursos]=useState([]);
  const [busquedaAlumnos,setBusquedaAlumnos]=useState([]);
  const [idalumno,setIdalumno]=useState('0');
  const [notaFinal,setnotaFinal]=useState('0');
  const [tipoCurso,setTipoCurso]=useState('P');
  const [notas,setNotas]=useState([]);

  const submitForm=async(values,actions)=>{
    /*const cursoInscrito=cursosAlumno.find(n=> n.id===parseInt(values.curso));
    console.log(cursoInscrito)*/
    const responseCurso=await AuthService.getCursoAlumnoId(values.curso)
    
    const response=await AuthService.postNotasAlumnoCurso(notas);
    if(response.status===201){
      const data={'id':values.curso,
                  'id_alumno':responseCurso.data.body[0].id_alumno,
                  'id_curso':responseCurso.data.body[0].id_curso,
                  'id_ciclo':responseCurso.data.body[0].id_ciclo,
                  'porcentaje_asistencia':values.asistencia,
                  'nota_final':notaFinal
            }
      const response2=await AuthService.postCursoAlumno(data);
      if(response2.status===201){
        Swal.fire(response2.body,'','success')
      }else{
        Swal.fire('Ha ocurrido un error!',response2.body,'error')
    };
    }else{
        Swal.fire('Ha ocurrido un error!',response.body,'error')
    };
  }
  //validaciones
  const formik = useFormik({
    initialValues: {
      nombre: "",
      primerAp: "",
      segundoAp: "",
      run: "",
      dv: "",
      curso: "",
      nota_Final: "",
      asistencia:"",
      SearchNombre: "",
      SearchPrimerAp: "",
      SearchSegundoAp: ""
    },
    validationSchema: Yup.object({
      nombre: Yup.string()
        .min(3, "Debe tener al menos 3 caracteres.")
        .required("Es obligatorio"),
      primerAp: Yup.string()
        .min(3, "Debe tener al menos 3 caracteres.")
        .required("Es obligatorio"),
      segundoAp: Yup.string().min(3, "Debe tener al menos 3 caracteres."),
      run: Yup.number()
        .max(9999999999, "Debe tener al menos 10 caracteres.")
        .required("Es obligatorio"),
      dv: Yup.string().max(1, "Largo 1").required("Falta"),
      curso: Yup.string().required("Es obligatorio"),
      SearchNombre: Yup.string(),
      SearchPrimerAp: Yup.string(),
      SearchSegundoAp: Yup.string()
    }),
    onSubmit: submitForm,
  });
  function validateRut(rut) {
    const cleanRut = rut.replace(/[.-]/g, ''); // Eliminar puntos y guiones
    const rutNumber = cleanRut.slice(0, -1);
    const verifierDigit = cleanRut.slice(-1).toUpperCase();
  
    if (!/^[0-9kK]+$/g.test(verifierDigit)) {
      return false; // El dígito verificador solo debe ser número o K
    }
  
    const reversedRutArray = [...rutNumber].reverse();
    const verifier = reversedRutArray.reduce((acc, digit, index) => {
      const multiplier = (index % 6) + 2;
      return acc + parseInt(digit, 10) * multiplier;
    }, 0);
  
    const remainder = verifier % 11;
    const calculatedVerifierDigit = remainder === 1 ? 'K' : remainder === 0 ? '0' : (11 - remainder).toString();
  
    return calculatedVerifierDigit === verifierDigit;
  }
  const handleBlurRut=async (e,values)=>{
    if(validateRut(values.run+'-'+values.dv)){
      let timerInterval
      Swal.fire({
        title: 'Buscando alumno',
        html: 'Espere...<b></b>',
        timer: 500,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
          const b = Swal.getHtmlContainer().querySelector('b')
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft()
          }, 100)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
        }
      })
      const response=await AuthService.getAlumnoRun(values.run);
      if(response.data.status===200){
        setIdalumno(response.data.body[0].id);
        const responseCursos=await AuthService.getCursoAlumno(response.data.body[0].id)
        //setId(responseCursos.data.body[0].id);
        if(responseCursos.data.status===200){
          setCursos(responseCursos.data.body)
        }else{
          Swal.fire('No hay cursos ingresados para el alumno','','error')
          handleLimpiar()
        }
        
        formik.setValues({
          ...values,
          nombre: response.data.body[0].nombres,
          primerAp: response.data.body[0].primer_apellido,
          segundoAp: response.data.body[0].segundo_apellido,
          nota_Final:"",
          asistencia:"",
        });
      }else{
        Swal.fire('Rut no encontrado','','error')
      }
    }else{
      Swal.fire('Rut Incorrecto','','error')
      formik.setValues({
        ...values,
        nombre: "",
        primerAp: "",
        segundoAp: "",
        run: "",
        dv: "",
        curso: "",
        nota_Final:"",
        asistencia:"",
      });
    }
  }
  const handleLimpiar=(e,values)=>{
    formik.setValues({
      ...values,
      nombre: "",
      primerAp: "",
      segundoAp: "",
      run: "",
      dv: "",
      curso: "",
      nota_Final:"",
      asistencia:"",
      SearchNombre: "",
      SearchPrimerAp: "",
      SearchSegundoAp: ""
    });
    setCursos([]);
    setNotas([]);
    setnotaFinal('0');
  }
  const handleBlurCurso=async(e,values)=>{
    formik.setFieldValue('curso', e.target.value);
    console.log(values.curso)
    if(e.target.value!==0){
      const response2= await AuthService.getNotasAlumnoCurso(idalumno,e.target.value);
      /*const cursosAlumno=[...cursos];
      const cursoInscrito=cursosAlumno.find(n=> n.id_curso===parseInt(values.curso));*/

      const responseCurso=await AuthService.getCursoAlumnoId(e.target.value)
      //formik.setFieldValue('asistenciaFinal',responseCurso.data.body[0].porcentaje_asistencia);
      setnotaFinal(responseCurso.data.body[0].nota_final);
      setTipoCurso(responseCurso.data.body[0].tipo_curso);
      formik.setValues({
        ...values,
        nombre: values.nombre,
        primerAp: values.primerAp,
        segundoAp: values.segundoAp,
        nota_Final:responseCurso.data.body[0].nota_final,
        asistencia:responseCurso.data.body[0].porcentaje_asistencia,
      });
      if(response2.data.status===200){
        setNotas([])
        const asignaturasAgregaCampo=response2.data.body.map(datos=>({
          ...datos,
          usuario_crea:user.name,
          id_matricula:responseCurso.data.body[0].id
        }))
        setNotas(asignaturasAgregaCampo)
      }else{
        Swal.fire('No existen Asignaturas creadas para este curso.','','error')
        setNotas([])
        setCursos([]);
        setnotaFinal('0');
      }
    }
  }
  const handleChangeCurso=async(e,values)=>{
    formik.setFieldValue('curso', e.target.value);

    if(e.target.value!=='0'){
      const response2= await AuthService.getNotasAlumnoCurso(idalumno,e.target.value);

      const responseCurso=await AuthService.getCursoAlumnoId(e.target.value)
      console.log(responseCurso.data.body[0].nota_final)
      setnotaFinal(responseCurso.data.body[0].nota_final===null?'':responseCurso.data.body[0].nota_final);
      setTipoCurso(responseCurso.data.body[0].tipo_curso);

      formik.setFieldValue('nota_Final', responseCurso.data.body[0].nota_final);
      formik.setFieldValue('asistencia', responseCurso.data.body[0].porcentaje_asistencia);
      if(response2.data.status===200){
        setNotas([])
        const asignaturasAgregaCampo=response2.data.body.map(datos=>({
          ...datos,
          usuario_crea:user.name,
          id_matricula:responseCurso.data.body[0].id
        }))
        setNotas(asignaturasAgregaCampo)
      }else{
        Swal.fire('No existen Asignaturas creadas para este curso.','','error')
        setNotas([])
        setCursos([]);
        setnotaFinal('0');
        handleLimpiar()
      }
    }else{
      setNotas([])
      setnotaFinal('0');
    }
  }
  const handleInputChange=(asignaturaId,notaIndex,valor)=>{
    const notasActualizadas=[...notas]
    const asignaturaNotas=notasActualizadas.find(n=> n.id===asignaturaId);
    if(asignaturaNotas){
      asignaturaNotas[notaIndex]=valor;
      const nota1=parseFloat(asignaturaNotas.nota_actividad_1)||0;
      const nota2=parseFloat(asignaturaNotas.nota_actividad_2)||0;
      const nota3=parseFloat(asignaturaNotas.nota_actividad_3)||0;
      const nota4=parseFloat(asignaturaNotas.nota_actividad_4)||0;
      const nota5=parseFloat(asignaturaNotas.nota_actividad_5)||0;
      const evaluacion=parseFloat(asignaturaNotas.nota_evaluacion)||0;
      const actividades=parseFloat(asignaturaNotas.numero_actividades)||0;
      const porcentaje_actividad_1=parseFloat(asignaturaNotas.porcentaje_actividad_1)||0;
      const porcentaje_actividad_2=parseFloat(asignaturaNotas.porcentaje_actividad_2)||0;
      const porcentaje_actividad_3=parseFloat(asignaturaNotas.porcentaje_actividad_3)||0;
      const porcentaje_actividad_4=parseFloat(asignaturaNotas.porcentaje_actividad_4)||0;
      const porcentaje_actividad_5=parseFloat(asignaturaNotas.porcentaje_actividad_5)||0;
      const porcentaje_evaluacion=parseFloat(asignaturaNotas.porcentaje_evaluacion)||0;

      /*const promedio_actividades=isNaN(((((nota1*(porcentaje_actividad_1/100))+
                                          (nota2*(porcentaje_actividad_2/100))+
                                          (nota3*(porcentaje_actividad_3/100))+
                                          (nota4*(porcentaje_actividad_4/100))+
                                          (nota5*(porcentaje_actividad_5/100)))/((porcentaje_actividad_1+porcentaje_actividad_2+porcentaje_actividad_3+porcentaje_actividad_4+porcentaje_actividad_5)/100))))?0:((((nota1*(porcentaje_actividad_1/100))+
                                          (nota2*(porcentaje_actividad_2/100))+
                                          (nota3*(porcentaje_actividad_3/100))+
                                          (nota4*(porcentaje_actividad_4/100))+
                                          (nota5*(porcentaje_actividad_5/100)))/((porcentaje_actividad_1+porcentaje_actividad_2+porcentaje_actividad_3+porcentaje_actividad_4+porcentaje_actividad_5)/100)));
      */
      const promedio_actividades=isNaN((nota1)+(nota2)+(nota3)+(nota4)+(nota5))?0:((nota1)+(nota2)+(nota3)+(nota4)+(nota5));
      
      //const promedio_final= promedio_actividades+((evaluacion)*(porcentaje_evaluacion/100));
      const promedio_final= promedio_actividades+evaluacion;
      asignaturaNotas.nota_final=isNaN(promedio_final)?0:promedio_final.toFixed(1);
      let sumaPromedios=0;
      for(let i=0;i<notas.length;i++){
        sumaPromedios+=parseFloat(notas[i].nota_final)||0;
      }
      const promedioFinal=sumaPromedios/notas.length;
      setnotaFinal(promedioFinal.toFixed(1))
    }
    setNotas(notasActualizadas);
  }
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    formik.setFieldValue('SearchNombre', '');
    formik.setFieldValue('SearchPrimerAp', '');
    formik.setFieldValue('SearchSegundoAp', '');
    setBusquedaAlumnos([])
  }
  const handleSearch=()=>{
    setShow(true);
  }
  const handleBuscar=async(e,values)=>{
    const response= await AuthService.postAlumnosNombres(values.SearchNombre,values.SearchPrimerAp,values.SearchSegundoAp);
    if(response.data.status===200){
      setBusquedaAlumnos(response.data.body)
    }else{
      Swal.fire(response.data.body,'','error')
    }
    
  }
  const handleSeleccionar=(rut,dv)=>{
    formik.setFieldValue('run', rut);
    formik.setFieldValue('dv', dv);
    handleClose()
  }
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Ingreso de notas por alumno
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="run">
                      <Form.Label>Run de Alumno</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.run}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.run && formik.errors.run ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.run}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col sm={1}>
                    <Form.Group className="mb-1" controlId="dv">
                      <Form.Label>DV</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.dv}
                        onChange={formik.handleChange}
                        onBlur={e=>{handleBlurRut(e,formik.values)}}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.dv && formik.errors.dv ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.dv}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col sm={1}>
                    <Form.Group className="mb-1" controlId="search">
                      <Form.Label>Buscar</Form.Label>
                      <Button variant="warning" onClick={() => handleSearch()}>
                      <BsSearch/>
                    </Button>
                      
                    </Form.Group>
                  
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="nombre">
                      <Form.Label>Nombre</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                    {formik.touched.nombre && formik.errors.nombre ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.nombre}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="primerAp">
                      <Form.Label>Primer Apellido</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.primerAp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                    {formik.touched.primerAp && formik.errors.primerAp ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.primerAp}
                      </Alert>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="segundoAp">
                      <Form.Label>Segundo Apellido</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.segundoAp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                    {formik.touched.segundoAp && formik.errors.segundoAp ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.segundoAp}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="curso">
                      <Form.Label>Curso</Form.Label>
                      <Form.Select
                        value={formik.values.curso}
                        onChange={handleChangeCurso}
                        onBlur={formik.handleBlur/*e=>{handleBlurCurso(e,formik.values)}*/}
                        className="form-control"
                      >
                        <option value='0'>Seleccione...</option>
                        {cursos.length>0?cursos.map(elemento=>(
                        <option key={"cursosAlumno"+elemento.id} value={elemento.id}>{elemento.nombre_ciclo} {elemento.nombre_curso}</option>
                      )):''}
                      </Form.Select>
                    </Form.Group>
                    {formik.touched.curso && formik.errors.curso ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.curso}
                      </Alert>
                    ) : null}
                  </Col>
                  {tipoCurso==='P' &&(
                    <Col  sm={3}>
                    <Form.Group className="mb-3" controlId="asistencia">
                      <Form.Label>Asistencia</Form.Label>
                      <Form.Control
                        type="number"
                        value={formik.values.asistencia}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.asistencia && formik.errors.asistencia ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.asistencia}
                      </Alert>
                    ) : null}
                  </Col>)}
                  
                  <Col  sm={3}>
                    <Form.Group className="mb-3" controlId="nota_Final">
                      <Form.Label>Nota Final</Form.Label>
                      <Form.Control
                        type="number"
                        value={notaFinal}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        disabled
                      />
                    </Form.Group>
                    {formik.touched.nota_Final && formik.errors.nota_Final ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.nota_Final}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit">
                      Guardar
                    </Button>
                    {' '}
                    <Button variant="success" onClick={handleLimpiar}>
                      Limpiar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Asignaturas del Curso
          </Card.Header>
          <Card.Body style={myBody}>
            <Table striped bordered hover size="sm" variant="dark" responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Asignatura</th>
                  <th>% Actividad N°1</th>
                  <th>% Actividad N°2</th>
                  <th>% Actividad N°3</th>
                  <th>% Actividad N°4</th>
                  <th>% Actividad N°5</th>
                  <th>% Evaluación</th>
                  <th>Nota Final</th>
                  <th>Situación</th>
                </tr>
              </thead>
              <tbody>
              {notas.length>0?notas.map((elemento,index)=>(
                <tr key={"notas"+elemento.id}>
                <td>{index+1}</td>
                <td>{elemento.nombre}</td>
                <td>{elemento.numero_actividades>0?<Form.Control key={index} style={styleRow} type="number" value={elemento.nota_actividad_1?elemento.nota_actividad_1:''} onChange={e=>handleInputChange(elemento.id,'nota_actividad_1',e.target.value)}/>:''}</td>
                <td>{elemento.numero_actividades>=2?<Form.Control key={index} style={styleRow} type="number" value={elemento.nota_actividad_2?elemento.nota_actividad_2:''} onChange={e=>handleInputChange(elemento.id,'nota_actividad_2',e.target.value)}/>:''}</td>
                <td>{elemento.numero_actividades>=3?<Form.Control key={index} style={styleRow} type="number" value={elemento.nota_actividad_3?elemento.nota_actividad_3:''} onChange={e=>handleInputChange(elemento.id,'nota_actividad_3',e.target.value)}/>:''}</td>
                <td>{elemento.numero_actividades>=4?<Form.Control key={index} style={styleRow} type="number" value={elemento.nota_actividad_4?elemento.nota_actividad_4:''} onChange={e=>handleInputChange(elemento.id,'nota_actividad_4',e.target.value)}/>:''}</td>
                <td>{elemento.numero_actividades>=5?<Form.Control key={index} style={styleRow} type="number" value={elemento.nota_actividad_5?elemento.nota_actividad_5:''} onChange={e=>handleInputChange(elemento.id,'nota_actividad_5',e.target.value)}/>:''}</td>
                <td><Form.Control key={index} style={styleRow} type="number" value={elemento.nota_evaluacion?elemento.nota_evaluacion:''} onChange={e=>handleInputChange(elemento.id,'nota_evaluacion',e.target.value)}/></td>
                <td><Form.Control key={index} style={styleRow} type="number" value={elemento.nota_final?elemento.nota_final:''} onChange={e=>handleInputChange(elemento.id,'nota_final',e.target.value)} disabled/></td>
                <td>{elemento.nota_final<70?'Reprobado':'Aprobado'}</td>
              </tr>)):
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              }
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Buscar Alumno</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col>
            <Form.Group className="mb-3" controlId="SearchNombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.SearchNombre}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="SearchPrimerAp">
              <Form.Label>Primer Apellido</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.SearchPrimerAp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="SearchSegundoAp">
              <Form.Label>Segundo Apellido</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.SearchSegundoAp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
              />
            </Form.Group>
          </Col>
          <Table striped bordered hover size="sm" variant="dark" className="text-center">
              <thead>
                <tr>
                  <th>Run</th>
                  <th>Nombre Completo</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {busquedaAlumnos.length>0?busquedaAlumnos.map(elemento=>(
                <tr key={"busquedaalumno"+elemento.id}>
                <td>{elemento.run}-{elemento.digito_verificador}</td>
                <td>{elemento.primer_apellido} {elemento.segundo_apellido} {elemento.nombres}</td>
                <td><Button variant="warning" onClick={()=>handleSeleccionar(elemento.run,elemento.digito_verificador)}>Seleccionar</Button></td>
              </tr>
               )):null}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={e=>{handleBuscar(e,formik.values)}}>
              Buscar
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};
const styleRow = {
  width: "90px"
}
export default Notas;