import React, { useContext, useEffect, useState  } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AuthService from "../../api/ApiRest";
import { AuthContext } from "../../auth/authContext";
import Swal from "sweetalert2";
import { Table } from "react-bootstrap";

const MantenedorEmpresa= () => {
 
  const {user}=useContext(AuthContext );
  const [empresas,setEmpresas]=useState([]);
  const [id,setId]=useState('0');

  useEffect(()=>{
    const empresasData=async()=>{
        const response= await AuthService.getEmpresas();
        setEmpresas(response.data.body) 
    }
    empresasData();
  },[])
    const submitForm=async(values,actions)=>{
      const data={'id':id,
                  'nombre_empresa':values.nombreEmpresa,
                  'rut':values.rutEmpresa,
                  'observacion':values.observacion,
                  'usuario_crea':user.name,
                  'estado':'V'
      }
       
        const response=await AuthService.postEmpresa(data);
        if(response.status===201){
            Swal.fire(response.body,'','success')
            actions.setSubmitting(false);
            actions.resetForm({
              values: {
                nombreEmpresa: "",
                rutEmpresa: "",
                observacion: "",
              },
            });
            handleLimpiar()
        }else{
            Swal.fire('Ha ocurrido un error!',response.body,'error')
        };
        
    };
    //validaciones
    const formik = useFormik({
    initialValues: {
        nombreEmpresa: "",
        rutEmpresa:"",
        observacion: "",
    },
    validationSchema: Yup.object({
        nombreEmpresa:  Yup.string().required("Es obligatorio"),
        rutEmpresa:  Yup.string(),
        observacion: Yup.string().min(3, "Debe tener al menos 3 caracteres."),
    }),
    onSubmit: submitForm,
  });
  const handleEditar=async(idEdita,values)=>{
    const response=await AuthService.getEmpresaId(idEdita)
      setId(idEdita);
      formik.setValues({
        ...values,
        nombreEmpresa: response.data.body[0].nombre_empresa,
        rutEmpresa: response.data.body[0].rut,
        observacion: response.data.body[0].observacion
      });
  }
  const handleEliminar=async(idElimina)=>{
    Swal.fire({
      title: '¿Está seguro de eliminar?',
      text: "No se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        const response=await AuthService.putEmpresa({'id':idElimina,'usuario':user.name})
        console.log(response.status)
        if(response.status===200){
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.body,
            showConfirmButton: false,
            timer: 1500
          })
          handleLimpiar()
        }else{
          Swal.fire('Ha ocurrido un error!',response.body,'error')
        }
      }
    })
  }
  const handleLimpiar=async(e,values)=>{
    const response2= await AuthService.getEmpresas();
    setEmpresas(response2.data.body)
    formik.setValues({
      ...values,
      nombreEmpresa: "",
      rutEmpresa:"",
      observacion: "",
    });
    setId('0');
    
  }
  return (
    <>
      <Container>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Mantenedor de Empresas
          </Card.Header>
          <Card.Body style={myBody}>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="nombreEmpresa">
                      <Form.Label>Nombre de la empresa:</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.nombreEmpresa}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.nombreEmpresa && formik.errors.nombreEmpresa ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.nombreEmpresa}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="rutEmpresa">
                      <Form.Label>Rut de la empresa:</Form.Label>
                      <Form.Control
                        type="text"
                        value={formik.values.rutEmpresa}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                    </Form.Group>
                    {formik.touched.rutEmpresa && formik.errors.rutEmpresa ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.rutEmpresa}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="observacion">
                      <Form.Label>Observacion</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Agregar Observación..."
                        value={formik.values.observacion}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Form.Group>
                    {formik.touched.observacion && formik.errors.observacion ? (
                      <Alert variant="danger">
                        Hubo un error: {formik.errors.observacion}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit">
                      Guardar
                    </Button>
                    {' '}
                    <Button variant="success" onClick={handleLimpiar}>
                      Limpiar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header as="h3" style={myHeader}>
            Empresas Creadas
          </Card.Header>
          <Card.Body style={myBody}>
            <Table striped bordered hover size="sm" variant="dark" responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nombre</th>
                  <th>Rut</th>
                  <th>Observación</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {empresas.map((elemento,index)=>(
                <tr key={"empresas"+elemento.id}>
                <td>{index+1}</td>
                <td>{elemento.nombre_empresa}</td>
                <td>{elemento.rut}</td>
                <td>{elemento.observacion}</td>
                <td><Button variant="warning" onClick={()=>handleEditar(elemento.id)}>Editar</Button></td>
                <td><Button variant="danger" onClick={()=>handleEliminar(elemento.id)}>Eliminar</Button></td>
              </tr>
                        ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
const myHeader = {
  color: "white",
  backgroundColor: "#263238",
  padding: "10px",
  fontFamily: "Sans-Serif",
  textAlign: "center",
};
const myBody = {
  color: "white",
  backgroundColor: "#37474F",
  padding: "10px",
  fontFamily: "Sans-Serif",
};

export default MantenedorEmpresa;
