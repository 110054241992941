import React, { useEffect, useState } from 'react'
import { Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer'
import AuthService from "../../api/ApiRest";
import Fondo from "../../assets/libro_clases.jpg"
import {startOfDay, format, parseISO, addDays, parse } from 'date-fns';
import {es} from 'date-fns/locale';


const LibroClasesPDF = (datos) => {
    const [participantes,setParticipantes]=useState([]);
    const [actividad,setActividad]=useState([]);
    const [horario,setHorario]=useState('');
    const [columns,setColumns]=useState([]);
    useEffect(()=>{
        const actividadesData=async()=>{
            const response= await AuthService.getActividad(datos.datos);
            setActividad(response.data.body[0]) 
            setHorario(response.data.body[0].hora_inicio_manana.substring(0,5)+'-'+response.data.body[0].hora_termino_manana.substring(0,5)+' A '+response.data.body[0].hora_inicio_tarde.substring(0,5)+'-'+response.data.body[0].hora_termino_tarde.substring(0,5))
            generateColumns(parse(response.data.body[0].inicio, 'dd-MM-yyyy', new Date()), parse(response.data.body[0].termino, 'dd-MM-yyyy', new Date()));
        }
        actividadesData();
        const participantesData=async()=>{
            const response= await AuthService.getParticipantes(datos.datos);
            setParticipantes(response.data.body) 
        }
        participantesData();

    },[])
    const capitalize=(str)=>{
        return str.charAt(0).toUpperCase()+str.slice(1);
    }
    function cambiarFormatoFecha(fecha,tipo){
        const fechaISO=parseISO(fecha);
        let fechaFormateada='';
        if(tipo==='dd'){
            fechaFormateada=format(fechaISO,'dd');
        }
        if(tipo==='MM'){
            fechaFormateada=capitalize(format(fechaISO,'MMMM',{locale:es}));
        }
        if(tipo==='yyyy'){
            fechaFormateada=format(fechaISO,'yyyy');
        }
        if(tipo==='dd-MM-yyyy'){
            fechaFormateada=format(fechaISO,'dd-MM-yyyy');
        }
        if(tipo==='HH:mm'){
            fechaFormateada=fecha.substring(0,5);
        }
        
        return fechaFormateada;
    }
    const generateColumns = (startDate, endDate) => {

        const columns = [];
        let currentDate = startOfDay(startDate);
      
        while (currentDate <= endDate) {
          columns.push(currentDate);
          currentDate = addDays(currentDate, 1);
        }

        setColumns(columns);
    };
    return (
        <Document>
            <Page size="LETTER" orientation="landscape" style={styles.page}>
                <View style={styles.header} fixed>
                    <Image
                        src={Fondo}
                        style={styles.fondo}
                    />
                </View>
                <View  style={styles.titulo}>
                    <Text>LIBRO DE CONTROL DE CLASES</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderAsignatura}>
                            <Text style={styles.tableCellHeader}>NOMBRE DE ACCIÓN DE CAPACITACIÓN</Text>
                        </View>
                        <View style={styles.tableColHeaderCenter}>
                            <Text style={styles.tableCellHeaderCenter}>:</Text>
                        </View>
                        <View style={styles.tableColHeaderFin}>
                            <Text style={styles.tableCellHeaderDatos}>{actividad.nombre_generico}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderAsignatura}>
                            <Text style={styles.tableCellHeader}>CÓDIGO DEL CURSO</Text>
                        </View>
                        <View style={styles.tableColHeaderCenter}>
                            <Text style={styles.tableCellHeaderCenter}>:</Text>
                        </View>
                        <View style={styles.tableColHeaderFin}>
                            <Text style={styles.tableCellHeaderDatos}>{actividad.codigo_curso}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderAsignatura}>
                            <Text style={styles.tableCellHeader}>FECHA DE EJECUCIÓN</Text>
                        </View>
                        <View style={styles.tableColHeaderCenter}>
                            <Text style={styles.tableCellHeaderCenter}>:</Text>
                        </View>
                        <View style={styles.tableColHeaderAsignaturaFec}>
                            <Text style={styles.tableCellHeader}>FECHA DE INICIO:</Text>
                        </View>
                        <View style={styles.tableColHeaderFinFec}>
                            <Text style={styles.tableCellHeaderDatos}>{actividad.inicio}</Text>
                        </View>
                        <View style={styles.tableColHeaderAsignaturaFec}>
                            <Text style={styles.tableCellHeader}>FECHA DE TERMINO:</Text>
                        </View>
                        <View style={styles.tableColHeaderFinFec}>
                            <Text style={styles.tableCellHeaderDatos}>{actividad.termino}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderAsignatura}>
                            <Text style={styles.tableCellHeader}>DIRECCIÓN DE EJECUCIÓN</Text>
                        </View>
                        <View style={styles.tableColHeaderCenter}>
                            <Text style={styles.tableCellHeaderCenter}>:</Text>
                        </View>
                        <View style={styles.tableColHeaderFin}>
                            <Text style={styles.tableCellHeaderDatos}>{actividad.direccion_ejecucion}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderAsignatura}>
                            <Text style={styles.tableCellHeader}>HORARIO</Text>
                        </View>
                        <View style={styles.tableColHeaderCenter}>
                            <Text style={styles.tableCellHeaderCenter}>:</Text>
                        </View>
                        <View style={styles.tableColHeaderFin}>
                            <Text style={styles.tableCellHeaderDatos}>{horario}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderAsignatura}>
                            <Text style={styles.tableCellHeader}>NOMBRE INSTRUCTOR TITULAR</Text>
                        </View>
                        <View style={styles.tableColHeaderCenter}>
                            <Text style={styles.tableCellHeaderCenter}>:</Text>
                        </View>
                        <View style={styles.tableColHeaderFin}>
                            <Text style={styles.tableCellHeaderDatos}>{actividad.instructor_titular}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderAsignatura}>
                            <Text style={styles.tableCellHeader}>NOMBRE INSTRUCTOR REEMPLAZO</Text>
                        </View>
                        <View style={styles.tableColHeaderCenter}>
                            <Text style={styles.tableCellHeaderCenter}>:</Text>
                        </View>
                        <View style={styles.tableColHeaderFin}>
                            <Text style={styles.tableCellHeaderDatos}>{actividad.instructor_reemplazo}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderAsignatura}>
                            <Text style={styles.tableCellHeader}>NOMBRE OTEC</Text>
                        </View>
                        <View style={styles.tableColHeaderCenter}>
                            <Text style={styles.tableCellHeaderCenter}>:</Text>
                        </View>
                        <View style={styles.tableColHeaderFin}>
                            <Text style={styles.tableCellHeaderDatos}>CENTRO TECNOLÓGICO MINERO S.A.</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderAsignatura}>
                            <Text style={styles.tableCellHeader}>NOMBRE EMPRESA A CAPACITAR</Text>
                        </View>
                        <View style={styles.tableColHeaderCenter}>
                            <Text style={styles.tableCellHeaderCenter}>:</Text>
                        </View>
                        <View style={styles.tableColHeaderFin}>
                            <Text style={styles.tableCellHeaderDatos}>{actividad.nombre_empresa}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderAsignatura}>
                            <Text style={styles.tableCellHeader}>NOMBRE DE LA OTIC, CUANDO CORRESPONDA</Text>
                        </View>
                        <View style={styles.tableColHeaderCenter}>
                            <Text style={styles.tableCellHeaderCenter}>:</Text>
                        </View>
                        <View style={styles.tableColHeaderFin}>
                            <Text style={styles.tableCellHeaderDatos}>{actividad.nombre_otic}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderAsignatura}>
                            <Text style={styles.tableCellHeader}>Nº DE LIBRO</Text>
                        </View>
                        <View style={styles.tableColHeaderCenter}>
                            <Text style={styles.tableCellHeaderCenter}>:</Text>
                        </View>
                        <View style={styles.tableColHeaderFin}>
                            <Text style={styles.tableCellHeaderDatos}>{actividad.folio_libro}</Text>
                        </View>
                    </View>
                </View>
            </Page>
            {columns.reduce((pages, date, index) => {
                const pageIndex = Math.floor(index / 3);
                if (!pages[pageIndex]) {
                pages[pageIndex] = [];
                }
                pages[pageIndex].push(date);
                return pages;
            }, []).map((pageDates, pageIndex) => (
                <Page key={pageIndex} size="LETTER" orientation="landscape" style={styles.page}>
                    <View style={styles.header} fixed>
                    <Image
                        src={Fondo}
                        style={styles.fondo}
                    />
                    </View>
                    <View  style={styles.subtitulo}>
                        <Text>HOJA DE REGISTRO DE ASISTENCIA MES DE: {actividad.fecha_inicio?cambiarFormatoFecha(actividad.fecha_inicio.substring(0,10),'MM')===cambiarFormatoFecha(actividad.fecha_termino.substring(0,10),'MM')?cambiarFormatoFecha(actividad.fecha_inicio.substring(0,10),'MM')+'.':cambiarFormatoFecha(actividad.fecha_inicio.substring(0,10),'MM')+' a '+cambiarFormatoFecha(actividad.fecha_termino.substring(0,10),'MM')+'.':''}</Text>
                    </View>
                    <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderFinAsistenciaDiaJor}>
                            <Text style={styles.tableCellHeader}></Text>
                        </View>
                        <View style={styles.tableColHeaderFinAsistenciaFin}>
                            <Text style={styles.tableCellHeaderDatosAsis}>DÍAS</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderFinAsistenciaDiaJor}>
                            <Text style={styles.tableCellHeader}></Text>
                        </View>
                        <View style={styles.tableColHeaderFinAsistenciaFin}>
                            <Text style={styles.tableCellHeaderDatosAsis}>JORNADA</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderFinAsistencia}>
                            <Text style={styles.tableCellHeaderAsis}>FECHA</Text>
                        </View>
                        {pageDates.map((date, index) => (
                        <View key={index} style={styles.tableCol}>
                            <Text style={styles.tableCell}>{format(date, 'dd/MM/yyyy')}</Text>
                        </View>
                        ))}
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderFinAsistencia}>
                            <Text style={styles.tableCellHeaderAsis}>HORARIO</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCell}>De {cambiarFormatoFecha(actividad.hora_inicio_manana,'HH:mm')} A {cambiarFormatoFecha(actividad.hora_termino_manana,'HH:mm')} Hrs.</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCell}>De {cambiarFormatoFecha(actividad.hora_inicio_tarde,'HH:mm')} A {cambiarFormatoFecha(actividad.hora_termino_tarde,'HH:mm')} Hrs.</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCell}>De {cambiarFormatoFecha(actividad.hora_inicio_manana,'HH:mm')} A {cambiarFormatoFecha(actividad.hora_termino_manana,'HH:mm')} Hrs.</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCell}>De {cambiarFormatoFecha(actividad.hora_inicio_tarde,'HH:mm')} A {cambiarFormatoFecha(actividad.hora_termino_tarde,'HH:mm')} Hrs.</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCell}>De {cambiarFormatoFecha(actividad.hora_inicio_manana,'HH:mm')} A {cambiarFormatoFecha(actividad.hora_termino_manana,'HH:mm')} Hrs.</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCell}>De {cambiarFormatoFecha(actividad.hora_inicio_tarde,'HH:mm')} A {cambiarFormatoFecha(actividad.hora_termino_tarde,'HH:mm')} Hrs.</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderFinAsistencia}>
                            <Text style={styles.tableCellHeaderAsisCenter}>NOMBRE DEL PARTICIPANTE</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}>Mañana</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}>Tarde</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}>Mañana</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}>Tarde</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}>Mañana</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}>Tarde</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}>% Asistencia</Text>
                        </View>
                    </View>
                    {participantes.map((datos, index) => (
                    <View key={index} style={styles.tableRow}>
                        <View style={participantes.length===index+1?styles.tableColIndiceFin:styles.tableColIndice}>
                            <Text style={styles.tableCellAsignatura}>{index+1}</Text>
                        </View>
                        <View style={participantes.length===index+1?styles.tableColHeaderAlumnosFin:styles.tableColHeaderAlumnos}>
                            <Text style={styles.tableCellAsignatura}>{datos.primer_apellido} {datos.segundo_apellido} {datos.nombres}</Text>
                        </View>
                        <View style={participantes.length===index+1?styles.tableColJorFin:styles.tableColJor}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                        <View style={participantes.length===index+1?styles.tableColJorFin:styles.tableColJor}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                        <View style={participantes.length===index+1?styles.tableColJorFin:styles.tableColJor}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                        <View style={participantes.length===index+1?styles.tableColJorFin:styles.tableColJor}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                        <View style={participantes.length===index+1?styles.tableColJorFin:styles.tableColJor}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                        <View style={participantes.length===index+1?styles.tableColJorFin:styles.tableColJor}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                        <View style={participantes.length===index+1?styles.tableColJorFin:styles.tableColJor}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                    </View>
                        ))}
                </View>

                </Page>
            ))}
            <Page size="LETTER" orientation="landscape" style={styles.page}>
                <View style={styles.header} fixed>
                    <Image
                        src={Fondo}
                        style={styles.fondo}
                    />
                </View>
                <View  style={styles.titulo}>
                    <Text>DESCRIPCIÓN DE LAS ACTIVIDADES REALIZADAS</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderFinFecha}>
                            <Text style={styles.tableCellHeaderAsisCenter}>FECHA</Text>
                        </View>
                        <View style={styles.tableColTema}>
                            <Text style={styles.tableCellHeaderAsisCenter}>TEMA</Text>
                        </View>
                        <View style={styles.tableColActividades}>
                            <Text style={styles.tableCellHeaderAsisCenter}>ACTIVIDADES</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}>HORAS</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}>FIRMA INSTRUCTOR</Text>
                        </View>
                    </View>
                    {Array.from(Array(12)).map((_, index) => (
                        <View key={index} style={styles.tableRow}>
                            <View style={12===index+1?styles.tableColHeaderFinFechaDetalleFin:styles.tableColHeaderFinFechaDetalle}>
                                <Text style={styles.tableCellHeaderAsisCenter}></Text>
                            </View>
                            <View style={12===index+1?styles.tableColTemaDetalleFin:styles.tableColTemaDetalle}>
                                <Text style={styles.tableCellHeaderAsisCenter}></Text>
                            </View>
                            <View style={12===index+1?styles.tableColActividadesDetalleFin:styles.tableColActividadesDetalle}>
                                <Text style={styles.tableCellHeaderAsisCenter}></Text>
                            </View>
                            <View style={12===index+1?styles.tableColJorDetalleFin:styles.tableColJorDetalle}>
                                <Text style={styles.tableCellHeaderAsisCenter}></Text>
                            </View>
                            <View style={12===index+1?styles.tableColJorDetalleFin:styles.tableColJorDetalle}>
                                <Text style={styles.tableCellHeaderAsisCenter}></Text>
                            </View>
                        </View>
                        ))
                    }
                </View>
            </Page>
            <Page size="LETTER" orientation="landscape" style={styles.page}>
                <View style={styles.header} fixed>
                    <Image
                        src={Fondo}
                        style={styles.fondo}
                    />
                </View>
                <View  style={styles.titulo}>
                    <Text>ANTECEDENTES DE LOS PARTICIPANTES</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderFinFecha}>
                            <Text style={styles.tableCellHeaderAsisCenter}>N°</Text>
                        </View>
                        <View style={styles.tableColActividades}>
                            <Text style={styles.tableCellHeaderAsisCenter}>APELLIDOS, NOMBRES</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}>CÉDULA IDENTIDAD</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}>NIVEL DE ESCOLARIDAD</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}>EMPRESA</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}>CARGO DESEMPEÑADO</Text>
                        </View>
                        <View style={styles.tableColTema}>
                            <Text style={styles.tableCellHeaderAsisCenter}>FIRMA</Text>
                        </View>
                    </View>
                    {participantes.map((datos, index) => (
                        <View key={index} style={styles.tableRow}>
                            <View style={participantes.length===index+1?styles.tableColHeaderFinFechaDetalleFin:styles.tableColHeaderFinFechaDetalle}>
                                <Text style={styles.tableCellHeaderAsisCenter}>{index+1}</Text>
                            </View>
                            <View style={participantes.length===index+1?styles.tableColActividadesDetalleFin:styles.tableColActividadesDetalle}>
                                <Text style={styles.tableCellAsignatura}>{datos.primer_apellido} {datos.segundo_apellido} {datos.nombres}</Text>
                            </View>
                            <View style={participantes.length===index+1?styles.tableColJorDetalleFin:styles.tableColJorDetalle}>
                                <Text style={styles.tableCellAsignatura}>{datos.run}-{datos.dv}</Text>
                            </View>
                            <View style={participantes.length===index+1?styles.tableColJorDetalleFin:styles.tableColJorDetalle}>
                                <Text style={styles.tableCellHeaderAsisCenter}></Text>
                            </View>
                            <View style={participantes.length===index+1?styles.tableColJorDetalleFin:styles.tableColJorDetalle}>
                                <Text style={styles.tableCellAsignatura}>{actividad.nombre_empresa}</Text>
                            </View>
                            <View style={participantes.length===index+1?styles.tableColJorDetalleFin:styles.tableColJorDetalle}>
                                <Text style={styles.tableCellAsignatura}>{datos.cargo}</Text>
                            </View>
                            <View style={participantes.length===index+1?styles.tableColTemaDetalleFin:styles.tableColTemaDetalle}>
                                <Text style={styles.tableCellHeaderAsisCenter}></Text>
                            </View>
                        </View>
                        ))
                    }
                </View>
            </Page>
            <Page size="LETTER" orientation="landscape" style={styles.page}>
                <View style={styles.header} fixed>
                    <Image
                        src={Fondo}
                        style={styles.fondo}
                    />
                </View>
                <View  style={styles.titulo}>
                    <Text>EVALUACIONES</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderFinFecha}>
                            <Text style={styles.tableCellHeaderAsisCenter}></Text>
                        </View>
                        <View style={styles.tableColActividades}>
                            <Text style={styles.tableCellHeaderAsisCenter}></Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}>FECHA</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}>FECHA</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}>FECHA</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}>FECHA</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}></Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderFinFecha}>
                            <Text style={styles.tableCellHeaderAsisCenter}>N°</Text>
                        </View>
                        <View style={styles.tableColActividades}>
                            <Text style={styles.tableCellHeaderAsisCenter}>APELLIDOS, NOMBRES</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}></Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}></Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}></Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}></Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}>NOTA FINAL</Text>
                        </View>
                    </View>
                    {participantes.map((datos, index) => (
                        <View key={index} style={styles.tableRow}>
                            <View style={participantes.length===index+1?styles.tableColHeaderFinFechaDetalleFin:styles.tableColHeaderFinFechaDetalle}>
                                <Text style={styles.tableCellHeaderAsisCenter}>{index+1}</Text>
                            </View>
                            <View style={participantes.length===index+1?styles.tableColActividadesDetalleFin:styles.tableColActividadesDetalle}>
                                <Text style={styles.tableCellAsignatura}>{datos.primer_apellido} {datos.segundo_apellido} {datos.nombres}</Text>
                            </View>
                            <View style={participantes.length===index+1?styles.tableColJorDetalleFin:styles.tableColJorDetalle}>
                                <Text style={styles.tableCellAsignatura}></Text>
                            </View>
                            <View style={participantes.length===index+1?styles.tableColJorDetalleFin:styles.tableColJorDetalle}>
                                <Text style={styles.tableCellHeaderAsisCenter}></Text>
                            </View>
                            <View style={participantes.length===index+1?styles.tableColJorDetalleFin:styles.tableColJorDetalle}>
                                <Text style={styles.tableCellAsignatura}></Text>
                            </View>
                            <View style={participantes.length===index+1?styles.tableColJorDetalleFin:styles.tableColJorDetalle}>
                                <Text style={styles.tableCellAsignatura}></Text>
                            </View>
                            <View style={participantes.length===index+1?styles.tableColJorDetalleFin:styles.tableColJorDetalle}>
                                <Text style={styles.tableCellHeaderAsisCenter}></Text>
                            </View>
                        </View>
                        ))
                    }
                </View>
            </Page>
            <Page size="LETTER" orientation="landscape" style={styles.page}>
                <View style={styles.header} fixed>
                    <Image
                        src={Fondo}
                        style={styles.fondo}
                    />
                </View>
                <View  style={styles.titulo}>
                    <Text>HOJA DE FISCALIZACIÓN - SENCE</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderFinFecha}>
                            <Text style={styles.tableCellHeaderAsisCenter}>FECHA</Text>
                        </View>
                        <View style={styles.tableColJor}>
                            <Text style={styles.tableCellHeaderAsisCenter}>HORA</Text>
                        </View>
                        <View style={styles.tableColTema}>
                            <Text style={styles.tableCellHeaderAsisCenter}>NOMBRE DEL FISCALIZADOR</Text>
                        </View>
                        <View style={styles.tableColTema}>
                            <Text style={styles.tableCellHeaderAsisCenter}>OBSERVACIONES</Text>
                        </View>
                        <View style={styles.tableColTema}>
                            <Text style={styles.tableCellHeaderAsisCenter}>FIRMA DEL FISCALIZADOR</Text>
                        </View>
                    </View>
                    {Array.from(Array(13)).map((_, index) => (
                        <View key={index} style={styles.tableRow}>
                            <View style={13===index+1?styles.tableColHeaderFinFechaDetalleFin:styles.tableColHeaderFinFechaDetalle}>
                                <Text style={styles.tableCellHeaderAsisCenter}></Text>
                            </View>
                            <View style={13===index+1?styles.tableColJorDetalleFin:styles.tableColJorDetalle}>
                                <Text style={styles.tableCellHeaderAsisCenter}></Text>
                            </View>
                            <View style={13===index+1?styles.tableColTemaDetalleFin:styles.tableColTemaDetalle}>
                                <Text style={styles.tableCellHeaderAsisCenter}></Text>
                            </View>
                            <View style={13===index+1?styles.tableColTemaDetalleFin:styles.tableColTemaDetalle}>
                                <Text style={styles.tableCellHeaderAsisCenter}></Text>
                            </View>
                            <View style={13===index+1?styles.tableColTemaDetalleFin:styles.tableColTemaDetalle}>
                                <Text style={styles.tableCellHeaderAsisCenter}></Text>
                            </View>
                        </View>
                        ))
                    }

                </View>
            </Page>
        </Document>
    )
}
// Create styles
const styles = StyleSheet.create({
    page: {
        paddingTop: 90,
        paddingBottom: 65,
        paddingHorizontal: 30,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    titulo:{
        fontFamily: 'Helvetica-Bold',
        fontSize: 20,
        textAlign: 'center'
    },
    subtitulo:{
        fontFamily: 'Helvetica-Bold',
        fontSize: 15,
        textAlign: 'center',
    },
    fondo:{
        width:"800px",
        height:"500px",
    },
    alumno:{
        margin: 20,
        fontFamily: 'Helvetica-Bold',
        fontSize: 24,
        textAlign: 'center',
        color:'rgb(245,145,11)'
    },
    parrafo:{
        fontFamily: 'Helvetica',
        fontSize: 22,
        textAlign: 'center'
    },
    curso:{
        margin: 20,
        fontFamily: 'Helvetica-Bold',
        fontSize: 22,
        textAlign: 'center',
        color:'rgb(245,145,11)'
    },
    firmasNombre:{
        marginTop:50,
        fontFamily: 'Helvetica',
        fontSize: 14,
        textAlign: 'center',
        flexDirection:'row',
        justifyContent:'space-between',
    },
    firmasCargo:{
        fontFamily: 'Helvetica',
        fontSize: 14,
        textAlign: 'center',
        flexDirection:'row',
        justifyContent:'space-between',
    },
    textoIzquierda:{
        textAlign:'left',
    },
    textoDerecha:{
        textAlign:'right',
    },
    header: {
        position: 'absolute',
        marginBottom: 20,
      },
    piePagina: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        margin:35,
        textAlign: 'left',
        color: 'grey',
      },
      table: {
        display: 'table',
        width: '100%',
        borderStyle: 'solid',
       
        borderRightWidth: 0,
        borderBottomWidth: 0,
        marginTop:20,
      },
    tableRow: {
        flexDirection: 'row'
      },
    tableColHeader: {
        width: '8%',
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderLeftWidth: 1
      },
    tableColHeaderFin: {
        width: '60%',
        borderStyle: 'solid',
        borderTopWidth:0,
        borderBottomWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth:0
      },
    tableColHeaderFinAsistenciaDiaJor: {
        width: '25%',
        borderStyle: 'solid',
        borderTopWidth:1,
        borderLeftWidth: 1,
        borderRightWidth:1,
      },
    tableColHeaderFinAsistencia: {
        width: '28%',
        borderStyle: 'solid',
        borderTopWidth:1,
        borderLeftWidth: 1,
        borderRightWidth:1
      },
      tableColHeaderFinFecha: {
        width: '10%',
        borderStyle: 'solid',
        borderTopWidth:1,
        borderLeftWidth: 1,
        borderRightWidth:1
      },
      tableColHeaderFinFechaDetalle: {
        width: '10%',
        height: 30,
        borderStyle: 'solid',
        borderTopWidth:1,
        borderLeftWidth: 1,
        borderRightWidth:1
      },
      tableColHeaderFinFechaDetalleFin: {
        width: '10%',
        height: 30,
        borderStyle: 'solid',
        borderTopWidth:1,
        borderBottomWidth:1,
        borderLeftWidth: 1,
        borderRightWidth:1
      },
    tableColHeaderAlumnos: {
        width: '24%',
        borderStyle: 'solid',
        borderTopWidth:1,
        borderLeftWidth: 1,
        borderRightWidth:1
      },
    tableColHeaderAlumnosFin: {
        width: '24%',
        borderStyle: 'solid',
        borderTopWidth:1,
        borderLeftWidth: 1,
        borderRightWidth:1, 
        borderBottomWidth: 1,
      },
    tableColHeaderFinAsistenciaFin: {
        width: '75%',
        borderStyle: 'solid',
        borderTopWidth:1,
        borderRightWidth:1
      },
    tableColHeaderFinFec: {
        width: '10%',
        borderStyle: 'solid',
        borderTopWidth:0,
        borderBottomWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth:0
      },
    tableColHeaderAsignatura: {
        width: '40%',
        borderStyle: 'solid',
        borderTopWidth:0,
        borderBottomWidth: 0,
        borderLeftWidth: 0
      },
    tableColHeaderAsignaturaFec: {
          width: '20%',
          borderStyle: 'solid',
          borderTopWidth:0,
          borderBottomWidth: 0,
          borderLeftWidth: 0
        },
    tableColHeaderCenter: {
        width: '5%',
        borderStyle: 'solid',
        borderTopWidth:0,
        borderBottomWidth: 0,
        borderLeftWidth: 0
      },
    tableCol: {
        width: '24%',
        borderStyle: 'solid',
        borderTopWidth:1,
        borderRightWidth:1
      },
    tableColJor: {
        width: '12%',
        borderStyle: 'solid',
        borderTopWidth:1,
        borderRightWidth:1
      },
    tableColJorDetalle: {
        width: '12%',
        height:30,
        borderStyle: 'solid',
        borderTopWidth:1,
        borderRightWidth:1
      },
    tableColJorDetalleFin: {
        width: '12%',
        height:30,
        borderStyle: 'solid',
        borderTopWidth:1,
        borderBottomWidth:1,
        borderRightWidth:1
      },
    tableColTema: {
        width: '25%',
        borderStyle: 'solid',
        borderTopWidth:1,
        borderRightWidth:1
      },
    tableColTemaDetalle: {
        width: '25%',
        height: 30,
        borderStyle: 'solid',
        borderTopWidth:1,
        borderRightWidth:1
      },
      tableColTemaDetalleFin: {
        width: '25%',
        height: 30,
        borderStyle: 'solid',
        borderTopWidth:1,
        borderBottomWidth:1,
        borderRightWidth:1
      },
    tableColActividades: {
        width: '40%',
        borderStyle: 'solid',
        borderTopWidth:1,
        borderRightWidth:1
      },
      tableColActividadesDetalle: {
        width: '40%',
        height: 30,
        borderStyle: 'solid',
        borderTopWidth:1,
        borderRightWidth:1
      },
      tableColActividadesDetalleFin: {
        width: '40%',
        height: 30,
        borderStyle: 'solid',
        borderTopWidth:1,
        borderBottomWidth:1,
        borderRightWidth:1
      },
      tableColJorFin: {
        width: '12%',
        borderStyle: 'solid',
        borderTopWidth:1,
        borderRightWidth:1,
        borderBottomWidth: 1,
      },
    tableColIndice: {
        width: '4%',
        borderStyle: 'solid',
        borderTopWidth: 1,
        borderLeftWidth: 1,
      },
    tableColIndiceFin: {
        width: '4%',
        borderStyle: 'solid',
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderBottomWidth: 1,
      },
    tableColFin: {
        width: '8%',
        borderStyle: 'solid',
        borderLeftWidth: 1,
        borderBottomWidth: 1,
        borderRightWidth:1
      },
    tableColAsignatura: {
        width: '50%',
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderLeftWidth: 1
      },
    tableCellHeader: {
        margin: 5,
        fontSize: 11,
        fontWeight: 'bold',
        fontFamily: 'Helvetica-Bold',
      },
      tableCellHeaderAsis: {
        margin: 5,
        fontSize: 11,
        fontWeight: 'bold',
        fontFamily: 'Helvetica-Bold',
        textAlign: 'right',
      },
      tableCellHeaderAsisCenter: {
        margin: 5,
        fontSize: 11,
        fontWeight: 'bold',
        fontFamily: 'Helvetica-Bold',
        textAlign: 'center',
      },
    tableCellHeaderCenter: {
        margin: 5,
        fontSize: 11,
        fontFamily: 'Helvetica',
      },
    tableCellHeaderDatos: {
        margin: 5,
        fontSize: 11,
        //textDecoration: 'underline',
        fontFamily: 'Helvetica',
      },
    tableCellHeaderDatosAsis: {
        margin: 5,
        fontSize: 11,
        textAlign: 'center',
        fontFamily: 'Helvetica',
      },
    tableCell: {
        margin: 5,
        fontSize: 10,
        textAlign: 'center'
      },
    tableCellAsignatura: {
        margin: 5,
        fontSize: 9
      },  
      columnTitle: {
        marginBottom: 10,
        fontSize: 12,
        fontWeight: 'bold'
      },
      day: {
        marginBottom: 5,
        fontSize: 10
      }
  });

export default LibroClasesPDF
